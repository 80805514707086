/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable prefer-destructuring */
import { Storage, Auth } from 'aws-amplify';
import customProvider from './customProvider';
import config from '../config';
import getStore from '../createAdminStore';
import { setPaginationToken } from '../actions';
import { upload } from '../libs/fileUploader';

const RA = require('react-admin');

const { basePath } = config;

export const fetchJson = (url, options = {}, token) => {
  const requestHeaders = createHeadersFromOptions(options, token);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response => {
      if (response) {
        return response.text().then(text => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        }));
      }
    })
    .then(({ status, _statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        if (status !== 401) {
          return Promise.reject({
            status,
            body: json && json.message,
            message: json.message,
          });
        }
        // refresh token
        return Auth.currentSession()
          .then(user => fetchJson(url, options, user.getIdToken().jwtToken))
          .catch(e =>
            Promise.reject({
              status: 401,
              body: 'refresh token expired',
            }),
          );
      }
      return Promise.resolve({
        status,
        headers,
        body,
        json,
      });
    });
};

export const createHeadersFromOptions = (options, token) => {
  const requestHeaders =
    options.headers || new Headers({ Accept: 'application/json' });
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (token) {
    requestHeaders.set('Authorization', `Bearer ${token}`);
  }
  return requestHeaders;
};

const httpClient = async (url, options = {}) => {
  const username = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`,
  );
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`,
  );
  return fetchJson(url, options, token);
};

const _uploadConfigurations = ({ params, type }) => {
  let data = {};
  if (type === 'CREATE') data = params.data;
  if (type === 'DELETE') data = params.previousData;
  if (type === 'DELETE_MANY') data = params.ids;
  if (!data.imagePath) data.imagePath = '';
  return {
    asset: {
      fileKey: 'pictures',
      resultKey: 'assetPath',
      idKey: 'code',
    },
    'appliance-info': {
      fileKey: 'images',
      resultKey: 'path',
      idValue: `${data.applianceType ? `${data.applianceType}#` : ''}${
        data.category
      }`,
      multiple: true,
    },
    troubleshooting: {
      fileKey: 'pictures',
      resultKey: 'imagePath',
      idValue:
        type === 'DELETE' || type === 'UPDATE'
          ? decodeURIComponent(data.imagePath.split('/').pop())
          : undefined,
      steps: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue:
          type === 'DELETE' || type === 'UPDATE'
            ? decodeURIComponent(data.imagePath.split('/').pop())
            : undefined,
        multiple: true,
      },
    },
    hints: {
      steps: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue:
          type === 'DELETE'
            ? decodeURIComponent(data.imagePath.split('/').pop())
            : undefined,
        multiple: true,
      },
      singleUploadOfDifferentFiles: ['pictureAbove', 'thumbnailImage'],
    },
    'hints-category': {
      fileKey: 'pictures',
      resultKey: 'imagePath',
      idValue:
        type === 'DELETE'
          ? decodeURIComponent(data.imagePath.split('/').pop())
          : undefined,
    },
    discover: {
      fileKey: 'pictures',
      resultKey: 'imagePath',
    },
    'tutorial-landing-screen': {
      fileKey: 'pictures',
      resultKey: 'imagePath',
    },
    'mock-demo': {
      fileKey: 'mocks',
      resultKey: 'mockPath',
      idKey: 'code',
    },
    'appliance-wake-up': {
      fileKey: 'imagePath',
      resultKey: 'imagePath',
      idValue: decodeURIComponent(data.imagePath.split('/').pop()),
      steps: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue: decodeURIComponent(data.imagePath.split('/').pop()),
        multiple: true,
      },
    },
    'support-guide-help': {
      fileKey: 'imagePath',
      resultKey: 'imagePath',
      idValue: decodeURIComponent(data.imagePath.split('/').pop()),
      steps: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue: decodeURIComponent(data.imagePath.split('/').pop()),
        multiple: true,
      },
    },
    'iam-configuration': {
      fileKey: 'imagePath',
      resultKey: 'imagePath',
      idValue: decodeURIComponent(data.imagePath.split('/').pop()),
      variables: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue: decodeURIComponent(data.imagePath.split('/').pop()),
        multiple: true,
      },
    },
    'service-mode': {
      fileKey: 'imagePath',
      resultKey: 'imagePath',
      idValue: decodeURIComponent(data.imagePath.split('/').pop()),
      steps: {
        fileKey: 'steps',
        resultKey: 'imagePath',
        idValue: decodeURIComponent(data.imagePath.split('/').pop()),
        multiple: true,
      },
    },
    campaigns: {
      singleUploadOfDifferentFiles: [
        'cardDiscountImg',
        'cardBackgroundImg',
        'cardProductImg',
        'activeCardBackgroundImg',
        'activeCardProductImg',
        'cardBrandLogo',
        'detailHeaderDiscountImg',
        'detailHeaderProductImg',
        'detailBannerProductImg',
        'detailBannerBackgroundImg',
        'wineListBannerBackgroundImg',
        'wineListBannerBackgroundImgActive',
        'wineListBannerActiveImg',
        'wineListBannerProductImgActive',
      ],
    },
    'translation-ai': {
      fileKey: 'translationFile',
      resultKey: 'translationFile',
    },
  };
};

const addUploadFeature = requestHandler => async (type, resource, params) => {
  try {
    const uploadParams = _uploadConfigurations({ params, type })[resource];

    if (uploadParams) {
      await upload({
        type,
        params,
        resource,
        ...uploadParams,
      });
    }
  } catch (err) {
    console.error('upload err', err);
  }
  if (type === 'UPDATE') {
    if (resource === 'language' && params.data.files) {
      const file = params.data.files.rawFile;
      const newKey = `import_${params.data.code}.xlsx`;
      params.data.langStatus = 'importing';
      params.data.importFilename = newKey;
      await Storage.put(newKey, file, {
        contentType: params.data.files.rawFile.type,
      });
      params.data.files = null;
    }
    // RECIPE
    if (resource === 'recipe' && params.data.files) {
      // TO DO
      // const file = params.data.files.rawFile;
      // const newKey = `import_${params.data.code}.xlsx`;
      // params.data.langStatus = 'importing';
      // params.data.importFilename = newKey;
      // await Storage.put(newKey, file, {
      //   contentType: params.data.files.rawFile.type,
      //   bucket: bucketBea
      // });
      // params.data.files = null;
    }
  }
  return requestHandler(type, resource, params);
};
const dataProvider = customProvider(
  basePath,
  httpClient,
  getStore,
  setPaginationToken,
);

const uploadCapableDataProvider = addUploadFeature(dataProvider);

const multiPartRequest = async (resource, params, file) => {
  const formData = new FormData();

  formData.append('params', JSON.stringify(params.data));
  formData.append('file', file);
  const options = {
    method: 'POST',
    body: formData,
    // headers: new Headers({
    //   'Content-Type': `multipart/mixed;,`,
    // }),
  };

  return httpClient(`${config.basePath}/${resource}`, options).then(
    ({ json }) => ({
      data: { ...params.data },
    }),
  );
};
const finalDataProvider = {
  getList: (resource, params) =>
    uploadCapableDataProvider(RA.GET_LIST, resource, params),
  getOne: (resource, params) =>
    uploadCapableDataProvider(RA.GET_ONE, resource, params),
  getMany: (resource, params) =>
    uploadCapableDataProvider(RA.GET_MANY, resource, params),
  getManyReference: (resource, params) =>
    uploadCapableDataProvider(RA.GET_MANY_REFERENCE, resource, params),
  create: async (resource, params) =>
    uploadCapableDataProvider(RA.CREATE, resource, params),
  //   {
  //   if (resource === 'translation-ai') {
  //     const { translationsFile, ...rest } = params.data;
  //     const uploadResponse = await multiPartRequest(
  //       resource,
  //       params,
  //       translationsFile.rawFile,
  //     );
  //     return { data: { ...rest, id: uploadResponse.id } };
  //   } else {
  //     return uploadCapableDataProvider(RA.CREATE, resource, params);
  //   }
  // }
  update: (resource, params) =>
    uploadCapableDataProvider(RA.UPDATE, resource, params),
  updateMany: (resource, params) =>
    uploadCapableDataProvider(RA.UPDATE_MANY, resource, params),
  delete: (resource, params) =>
    uploadCapableDataProvider(RA.DELETE, resource, params),
  deleteMany: (resource, params) =>
    uploadCapableDataProvider(RA.DELETE_MANY, resource, params),
  upload: (resource, params) => {
    if (resource === 'recipe') {
      const options = {
        method: 'POST',
        body: JSON.stringify({ fileName: params.data.fileName }),
      };
      return httpClient(`${config.basePath}/${resource}/upload`, options).then(
        ({ json }) => ({
          data: { ...params.data },
        }),
      );
    }
    //  else if (resource === 'translation-ai') {
    //   const options = {
    //     method: 'POST',
    //     body: JSON.stringify({ fileName: params.data.fileName }),
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   };
    //   return httpClient(`${config.basePath}/${resource}`, options).then(
    //     ({ json }) => ({
    //       data: { ...params.data },
    //     }),
    //   );
    // }
  },
};

export default finalDataProvider;
