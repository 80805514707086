import { Box } from '@material-ui/core';
import { CustomSelectInput } from 'candy-commons-components';
import React, {
  useEffect,
  useState
} from 'react';
import {
  Loading,
  NumberInput,
  TextInput,
  required,
  useDataProvider
} from 'react-admin';
import {
  FORM_TYPE,
  IMPRESSIONS_LOGIC
} from '../../config';

export const ImpressionLogicInput = ({ queryID, variant, isEditDisabled }) => {
  const [impression, updateImpression] = React.useState(
    queryID ? null : IMPRESSIONS_LOGIC.NO_LIMIT,
  );
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setHasError] = useState(false);

  useEffect(() => {
    if (queryID) {
      if (!impression) {
        setIsLoading(true);
        dataProvider
          .getOne('promo-spaces', { id: queryID })
          .then(({ data: response }) => {
            updateImpression(response.content.impressionsLogic);
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            setHasError(error);
          });
      }
    }
  }, [impression, isLoading]);

  if (isLoading) return <Loading />;
  if (!impression && variant === FORM_TYPE.EDIT) return null;
  if (error) return null;

  return (
    <Box>
      <CustomSelectInput
        source="content.impressionsLogic"
        label="Impressions logic"
        choices={[
          {
            id: IMPRESSIONS_LOGIC.COUNT,
            name: 'Count',
          },
          {
            id: IMPRESSIONS_LOGIC.NO_LIMIT,
            name: 'No limit',
          },
        ]}
        onChange={event => {
          updateImpression(event.target.value);
        }}
        validate={[required()]}
        style={{ marginRight: 10 }}
        disabled={isEditDisabled}
      />
      {impression === IMPRESSIONS_LOGIC.COUNT && (
        <NumberInput
          min="1"
          source="content.impressionsNumber"
          label="Impressions Number"
          validate={[required()]}
        />
      )}

      {impression === IMPRESSIONS_LOGIC.COUNT && (
        <Box>
          <TextInput
            source="content.stopCondition.eventName"
            label="Stop condition event"
            style={{ marginRight: 10 }}
          />

          <NumberInput
            min="1"
            source="content.stopCondition.occurrencies"
            label="occurrencies"
          />
        </Box>
      )}
    </Box>
  );
};
