import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  popupContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    maxHeight: '60%',
    overflowY: 'auto',
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 10,
    fontSize: 20,
    cursor: 'pointer',
    color: '#f44336',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#d32f2f',
    },
  },
  topBar: {
    position: 'sticky',
    top: 0,
    right: 0,
    padding: 10,
    display: 'flex',
    'justify-content': 'flex-end',
  },
  scrollableContent: {
    overflowY: 'auto',
    'max-height': 'calc(60% - 30px)',
  },
}));


const Popup = ({ errors, onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.popupContainer}>
      <div className={classes.popup}>
        <div className={classes.topBar}>
          <span className={classes.close} onClick={onClose}>
            &times;
          </span>
        </div>
        <div className={classes.scrollableContent}>
          <h2>The uploaded file contained the following errors</h2>
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Popup;
