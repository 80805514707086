import React from "react";
import { Show, SimpleForm, TextField, Labeled } from "react-admin";
import { CustomBreadcrumbs } from 'candy-commons-components';
import AssistantIcon from '@material-ui/icons/Assistant';
const breadcrumbsData = [
  {
    text: 'Assistance',
    url: 'assistance',
    icon:<AssistantIcon/>
  }, {
    text: 'Show',
    url: ''
  }
];

const SecondaryCountryCode = ({ record = {} }) =>
  record.secondaryCountryCode ? (
    <Labeled label="Secondary Country Code">
      <span>{record.secondaryCountryCode} </span>
    </Labeled>
  ) : null;

const AssistanceShow = ({ ...props }) => {
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Show {...props}>
        <SimpleForm toolbar={null}>
          <TextField source="countryCode" label="Country Code" />
          <TextField source="brand" />
          <TextField source="country" />
          <TextField source="phone" />
          <SecondaryCountryCode source="secondaryCountryCode" />
        </SimpleForm>
      </Show>
    </>
  );
};

export default AssistanceShow;
