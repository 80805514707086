import React from 'react';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  useTranslate
} from 'react-admin';
import countries from '../../../data/countries.json';
import { useStyles } from '../styles';
import CustomToolbar from '../../../components/CustomToolbar';

const HowToEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['multi-account-how-to']?.update;
  const { id } = props;

  const breadcrumbsData = [
    {
      text: translation('resources.multi-account-how-to.name'),
      url: 'multi-account-how-to',
      icon: <LiveHelpIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          className={classes.fullWidth}
          toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}
        >
          <BooleanInput
            initialValue={false}
            source="publish"
            label="Publish"
            className={classes.fullWidth}
            disabled={isEditDisabled}
          />
          <ContainerFieldsList>
            <TextInput source="id" label="Title" disabled />
            <TextInput source="tag" label="Tag" disabled={isEditDisabled} />
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={countries}
              parse={x => (x.includes('all') ? ['all'] : x)}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <TextInput source="liferayContentId" label="Liferay Content Id" disabled={isEditDisabled} />
            <TextInput
              source="liferayStructureId"
              label="Liferay Structure Id"
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default HowToEdit;
