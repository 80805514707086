import React from 'react';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import constants from 'candy-commons/constants';
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useTranslate
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';

import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import series from '../../data/series.json';

const EcoEvents = constants.EcoEvents;

const EligibleAppliancesEdit = ({ permissions = {}, ...props }) => {
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['eligible-appliances']?.update;
  const { id } = props;

  const breadcrumbsData = [
    {
      text: translation('resources.eligible-appliances.name'),
      url: 'eligible-appliances',
      icon: <AssistantIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  const getFilteredEvents = applianceType => {
    if (applianceType) {
      applianceType = applianceType === 'WM-WD' ? 'WM' : applianceType;
      return EcoEvents.filter(evt => evt?.applianceTypes.includes(applianceType));
    }
    return [];
  };
  
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} />}>
          <ContainerFieldsList>
            <ContainerFieldsList>
              <TextInput
                id={id}
                source="id"
                label="Energy Provider Key"
                disabled
              />
              <CustomSelectArrayInput
                required
                label="Countries"
                source="countries"
                choices={COUNTRIES}
                disabled={isEditDisabled}
              />
            </ContainerFieldsList>
            <ArrayInput
              source="applianceEligibility"
              label="appliance eligibility"
              disabled={isEditDisabled}
            >
              
              <SimpleFormIterator getItemLabel={index => `${index + 1}-key`} TransitionProps={{ enter: false, exit: false }}>
                <CustomSelectInput
                  source="applianceType"
                  choices={applianceType}
                  required
                />
                <TextInput source="country" required />
                <CustomSelectInput source="brand" allowEmpty choices={brand} />
                <CustomSelectInput source="series" allowEmpty choices={series} />

                <TextInput source="seriesVersion" required />
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }) => (
                    <CustomSelectArrayInput
                      choices={getFilteredEvents(scopedFormData?.applianceType)}
                      label="Events Subscription"
                      translateChoice={false}
                      source={getSource('eventSubscription')}
                      {...rest}
                    />
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default EligibleAppliancesEdit;
