import PageviewIcon from '@material-ui/icons/Pageview';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  TextField, useTranslate, NumberField, DateField,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const CampaignsList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.campaigns.name'),
      url: '',
      icon: <PageviewIcon />,
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        exporter={false}
        {...props}
        bulkActionButtons={
          permissions && permissions.assistance && permissions.assistance.delete
        }
        pagination={<DynamoPagination resource="maintenance" />}
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField label="Slug" source="slug" />
          <NumberField
            label="Order"
            source="order"
          />
          <TextField source="title" />
          <TextField source="description" />
          <DateField label="Valid from" source="from" />
          <DateField label="Valid to" source="to" />
          <TextField source="activeDuration" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default CampaignsList;
