import Toolbar from '@material-ui/core/Toolbar';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  CreateButton, TextField, TextInput, useTranslate,
} from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import SettingsIcon from '@material-ui/icons/Settings'

const TutorialFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.tutorial-landing-screen.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const TutorialListAction = ({
  basePath,
  permissions,
}) => {
  return (
    <Toolbar>
      {(permissions
        && permissions['tutorial-landing-screen']
        && permissions['tutorial-landing-screen'].create)
        && <CreateButton basePath={basePath} />}
    </Toolbar>
  );
};

const TutorialList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.tutorial-landing-screen.name'),
      url: '',
      icon: <SettingsIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        {...props}
        perPage={25}
        pagination={<DynamoPagination resource="tutorial-landing-screen" />}
        filters={<TutorialFilter />}
        actions={<TutorialListAction permissions={permissions} />}
        bulkActionButtons={permissions && permissions['tutorial-landing-screen'] && permissions['tutorial-landing-screen'].delete}
      >
        <CustomDatagrid
          rowClick="edit"
          selectable
        >
          <TextField source="applianceType" />
          <TextField source="landingScreenTitle" />
          <TextField source="landingScreenDescription" />
          <TextField source="code" />
          <TextField source="assetName" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default TutorialList;
