import {CustomFilter, CustomSelectArrayInput, CustomSelectInput} from 'candy-commons-components';
import {TextInput} from 'react-admin';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import languages from '../data/languages.json';
import config from '../config';
import {getHeaders} from '../utils/axios';


export default function TranslationFilter(props) {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    axios.get(
      `${config.basePath}/translation-keys`,
      { headers: getHeaders() },
    ).then(res => setKeys(res.data.map(data => ({ id: data, name: data }))));
  }, []);

  return (
    <CustomFilter {...props}>
      <TextInput
        label="resources.translation.fields.search"
        source="q"
        alwaysOn
      />
      <CustomSelectInput
        allowEmpty
        source="code"
        choices={keys}
        alwaysOn
      />
      <CustomSelectArrayInput
        label='languages'
        source="l"
        choices={languages}
        alwaysOn
      />
    </CustomFilter>
  );
}
