import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
  FieldsGenerator,
} from 'candy-commons-components';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import SERIES from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
import { CONNECTIVITY } from './applianceConnectivityChoices';
import { sectionChoices } from './sectionChoices';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const ApplianceConfigurationEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions['appliance-configuration']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: 'Appliance Configuration',
      url: 'appliance-configuration',
      icon: <ViewComfyIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'applianceType',
          type: 'textField',
          source: 'applianceType',
        },
        {
          label: 'applianceModel',
          type: 'textField',
          source: 'applianceModel',
        },
        {
          label: 'series',
          type: 'textField',
          source: 'series',
        },
        {
          label: 'brand',
          type: 'textField',
          source: 'brand',
        },
        {
          label: 'connectivity',
          type: 'textField',
          source: 'connectivity',
        },
      ]}
      record={record}
      {...props}
    />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ShowList />
          <hr className={classes.width} />
          <ContainerFieldsList>
            <TextInput source="applianceModel" disabled={isEditDisabled} />
            <CustomSelectInput allowEmpty source="series" choices={SERIES} disabled={isEditDisabled}  />
            <CustomSelectInput allowEmpty source="brand" choices={BRANDS} disabled={isEditDisabled}  />
            <CustomSelectInput
              allowEmpty
              source="country"
              choices={COUNTRIES}
              disabled={isEditDisabled} 
            />
            <CustomSelectInput
              allowEmpty
              source="connectivity"
              choices={CONNECTIVITY}
              disabled={isEditDisabled} 
            />
            <CustomSelectInput
              allowEmpty
              source="loadingType"
              choices={LOADING_TYPE}
              disabled={isEditDisabled} 
            />
          </ContainerFieldsList>
          <ArrayInput source="sections" disabled={isEditDisabled} >
            <SimpleFormIterator>
              <CustomSelectInput
                source="title"
                choices={sectionChoices.sort((a, b) =>
                  a.id.localeCompare(b.id),
                )}
              />
              <BooleanInput label="" source="visibility" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="topicsSubscribe" disabled={isEditDisabled} >
            <SimpleFormIterator>
              <TextInput source="topic" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="topicsPublish" disabled={isEditDisabled}>
            <SimpleFormIterator>
              <TextInput source="topic" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ApplianceConfigurationEdit;
