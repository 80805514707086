import SettingsIcon from '@material-ui/icons/Settings';
import {
  CustomBreadcrumbs,
  CustomFilter,
  CustomList,
} from 'candy-commons-components';
import React, { cloneElement } from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  SingleFieldList,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

import DynamoPagination from '../../../components/DynamoPagination';
import CustomDatagrid from '../../../components/organisms/customDatagrid';

const breadcrumbsData = [
  {
    text: 'Rewards',
    url: '',
    icon: <SettingsIcon />,
  },
];

const RewardsList = ({ permissions, ...props }) => {
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        {...props}
        exporter={false}
        bulkActionButtons={
          permissions &&
          permissions['promo-rewards'] &&
          permissions['promo-rewards'].delete
        }
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="id" />
          <TextField source="slug" />
          <TextField source="content.externalCmsContentId" />
          <TextField source="content.externalCmsStructureId" />
          <TextField source="content.rewardType" />
          <TextField source="content.codeType" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default RewardsList;
