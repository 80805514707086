import React from 'react';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import applianceTypeWash from '../../data/applianceTypeWash.json';
import brand from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import series from '../../data/series.json';
import { useStyles } from './styles';
import CustomToolbar from '../../components/CustomToolbar';

const styles = {
  hidden: { display: 'none' },
  fullLine: { width: '100%' },
  flex: { display: 'flex' },
};

const EcoDelayStartEdit = ({ permissions = {}, ...props }) => {
  const styles = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['eco-delay-start']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.eco-delay-start.name'),
      url: 'eco-delay-start',
      icon: <AssistantIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ContainerFieldsList>
            <ContainerFieldsList>
              <TextInput label="Name" source="id" disabled />
              <CustomSelectArrayInput
                required
                label="Countries"
                source="countries"
                choices={COUNTRIES}
                disabled={isEditDisabled}
              />
              <BooleanInput
                source="countriesToExclude"
                label="Are countries to exclude?"
                disabled={isEditDisabled}
              />
            </ContainerFieldsList>
            <ArrayInput
              className={styles.arrayInput}
              source="items"
              label="Smart Eco Delay Activation"
              disabled={isEditDisabled}
            >
              <SimpleFormIterator
                className={{ form: styles.form }}
                getItemLabel={index => `${index + 1}-key`}
              >
                <CustomSelectInput
                  source="applianceType"
                  choices={applianceTypeWash}
                  required
                />
                <ArrayInput source="brand" disabled={isEditDisabled}>
                  <SimpleFormIterator>
                    <CustomSelectInput source="brand" choices={brand} />
                  </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="series" disabled={isEditDisabled}>
                  <SimpleFormIterator>
                    <CustomSelectInput source="series" choices={series} />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="applianceModel" />
              </SimpleFormIterator>
            </ArrayInput>

            {/*Group for single record input type*/}
            <></>
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default EcoDelayStartEdit;
