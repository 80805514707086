import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WidgetsIcon from '@material-ui/icons/Widgets';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
  FieldsGenerator
} from 'candy-commons-components';
import {
  Edit,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import StatusTypes from './constants/StatusType';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const WidgetEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions?.['widget']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: 'Widget',
      url: '',
      icon: <WidgetsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'Name',
          type: 'textField',
          source: 'name',
        },
        {
          label: 'Type',
          type: 'textField',
          source: 'type',
        },
        {
          label: 'Id',
          type: 'textField',
          source: 'id',
        },
      ]}
      record={record}
      {...props}
    />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ShowList />
          <hr className={classes.width} />
          <ContainerFieldsList>
            <TextInput source="description" disabled={isEditDisabled} />
            <CustomSelectInput
              source="active"
              choices={StatusTypes}
              validate={[required()]}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default WidgetEdit;
