import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  ImageField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import display from '../../data/display.json';
import series from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
import type_ from '../../data/type.json';
import options from './applianceWakeUp.mock';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const ApplianceWakeUpCreate = props => {
  const [tdChoices, setTdChoices] = useState(false);
  const classes = useStyles();
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.applianceWakeUp.name'),
      url: 'appliance-wake.up',
      icon: <ErrorIcon />,
    },
    {
      text: transaltion('resources.applianceWakeUp.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
            />
            <CustomSelectInput allowEmpty source="brand" choices={brand} />
            <CustomSelectInput allowEmpty source="series" choices={series} />
            <TextInput source="seriesVersion" />
            <CustomSelectInput
              allowEmpty
              source="loadingType"
              choices={LOADING_TYPE}
            />
            <TextInput source="installationType" />
            <CustomSelectInput allowEmpty source="type" choices={type_} />
            <CustomSelectArrayInput
              allowEmpty
              source="display"
              choices={display}
            />
            <TextInput source="title" />
            <TextInput source="description" />
            <CustomSelectInput
              allowEmpty
              source="options"
              label="enrollment Type"
              choices={options}
            />
            <BooleanInput
              initialValue={false}
              source="resetGesture"
              label="Reset gesture"
            />
            <BooleanInput
              initialValue={false}
              source="easyEnrollment"
              label="Easy enrollment"
            />
            <BooleanInput
              initialValue={false}
              source="standardEnrollment"
              label="Standard enrollment"
            />
          </ContainerFieldsList>

          <hr className={classes.width} />
          <ArrayInput source="steps">
            <SimpleFormIterator>
              {/* <TextInput source="title" /> */}
              <TextInput source="stepTitle" label="Step title" />
              <TextInput source="stepSubtitle" label="Step subtitle" />
              <TextInput source="description" />
              <BooleanInput
                initialValue={false}
                source="standard"
                label="Standard flow (wifi)"
              />
              <NumberInput source="delay" label="Delay" />
              <ImageInput
                source="imagePath"
                label="Image"
                accept="image/*"
                placeholder={
                  <p>
                    {transaltion('resources.applianceWakeUp.fields.input_file')}
                  </p>
                }
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default ApplianceWakeUpCreate;
