import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconContentPublish from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import LanguageIcon from '@material-ui/icons/Language';
import { ContainerFieldsList, FieldsGenerator, CustomBreadcrumbs } from 'candy-commons-components';
import React, { useState } from 'react';
import {
  Button,
  CardActions,
  Edit,
  FileField,
  FileInput,
  required,
  SimpleForm,
  TextInput,
  UPDATE,
  useNotify,
  withDataProvider,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { showListData } from './languageStaticData';
import CustomToolbar from '../../components/CustomToolbar';
import { checkFileErrors, checkLanguageErrors } from '../../utils/fileUtils';
import Popup from '../../components/Popup/Popup';

const ShowList = ({ record, ...props }) => (
  <FieldsGenerator fields={showListData} record={record} {...props} />
);

const PostEditActions = ({
  data, resource, dataProvider, code,
}) => {
  const notify = useNotify();
  const history = useHistory();
  const handleClick = () => {
    dataProvider.update(resource, { data: { version: data.version || 0, langStatus: 'exporting' }, id: data.id })
      .then(({ data: result }) => {
        notify(`version ${result.version} published`);
        history.push('/language');
      })
      .catch((e) => {
        notify('ra.page.error', 'warning');
      });
  };
  const download = () => {
    window.location.href = data.exportLink;
  };
  return (
    <CardActions>
      <Button label="resources.language.action.publish_in_app" onClick={handleClick}>
        <IconContentPublish />
      </Button>
      <Button disabled={!data || !data.langStatus || data.langStatus !== 'published'} label="resources.language.action.export" onClick={download}>
        <GetAppIcon />
      </Button>
    </CardActions>
  );
};

const _checkFileErrors = async (event, setLoading, notify, setErrors) => {
  if (!event || !event.rawFile) {
    return;
  }

  const reader = new FileReader();
  const file = event.rawFile;

  if (!file) {
    notify('no file');
    setLoading(false);
    return;
  }

  try {
    setLoading(true);
    const fileContent = await readFileAsync(file);
    const languageErrors = await checkLanguageErrors(fileContent);
    if (languageErrors && languageErrors.length) {
      setErrors([
        `Error: invalid language codes detected in the input file: ${languageErrors}. Please, check the content of the file and try again.`,
      ]);
    } else {
      const fileErrors = checkFileErrors(fileContent);
      setErrors(fileErrors);
    }
  } catch (error) {
    // Handle the error, e.g., display an error message to the user.
  } finally {
    setLoading(false);
  }
};

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};


const LanguageEdit = ({
  id, resource, basePath, dataProvider, dispatch, permissions,
}) => {
  const [errors, setErrors] = useState([]);
  const notify = useNotify();
  const isEditDisabled = !permissions?.language?.update;
  const classes = useStyles();
  const translation = useTranslate();
  const [isLoading, setLoading] = useState(false);

  const validateFile = async event => {
    await _checkFileErrors(event, setLoading, notify, setErrors);
  };

  const breadcrumbsData = [
    {
      text: translation('resources.language.name'),
      url: 'language',
      icon: <LanguageIcon />,
    }, {
      text: id,
      url: '',
      capitalize: true,
    },
  ];

  return (
    <>
      <CustomBreadcrumbs capitalize data={breadcrumbsData} />
      <Edit
        actions={
          <PostEditActions dataProvider={dataProvider} dispatch={dispatch} />
        }
        id={id}
        className={classes.invertButtonSave}
        resource={resource}
        basePath={basePath}
      >
        <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          {/* <TextField source="version" />
        <TextField label="Status" source="langStatus" /> */}
          <ShowList />
          <ContainerFieldsList>
            <TextInput
              source="langName"
              validate={required()}
              disabled={isEditDisabled}
            />
            <TextInput
              source="code"
              validate={required()}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          {!isEditDisabled && (
            <FileInput
              source="files"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              validate={validateFile}
              disabled={isEditDisabled}
            >
              <FileField source="src" title="title" />
            </FileInput>
          )}
        </SimpleForm>
      </Edit>
      {isLoading ? (
        <CircularProgress className={classes.loader} size={30} thickness={2} />
      ) : null}
      {errors.length > 0 && (
        <Popup
          errors={errors}
          onClose={() => {
            setErrors([]);
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  invertButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
  loader: {
    alignSelf: 'center',
    position: 'relative',
    top: '-100px',
  }
});

export default withDataProvider(LanguageEdit);
