const adminPortalSections = [
  "appliance-model",
  "appliance-configuration",
  "appliance-type",
  "appliance",
  "command-history",
  "activity",
  "status-change",
  "firmware",
  "appliance-model-dictionary",
  "command",
  "program-list-rules"
];

export { adminPortalSections };
