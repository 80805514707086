import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  ArrayField, Datagrid, Show, TextField, TextInput, useTranslate,
} from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';


const ApplianceSettingsFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.appliance-settings.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const PostShow = props => (
  <Show {...props} title=" Options">
    <ArrayField source="options">
      <CustomDatagrid>
        <TextField source="name" sortable={false} />
        <TextField source="value" sortable={false} />
      </CustomDatagrid>
    </ArrayField>
  </Show>
);

const ApplianceSettingsList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.appliance-settings.name'),
      url: '',
      icon: <SettingsApplicationsIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        {...props}
        perPage={25}
        pagination={<DynamoPagination resource="appliance-settings" />}
        filters={<ApplianceSettingsFilter />}
        bulkActionButtons={permissions && permissions['appliance-settings'] && permissions['appliance-settings'].delete}
      >
        <Datagrid expand={<PostShow />} rowClick="edit">
          <TextField source="applianceType" />
          <TextField source="series" />
        </Datagrid>
      </CustomList>
    </>
  );
};

export default ApplianceSettingsList;
