export default [{
  id: 'generic',
  name: 'Generic',
},
{
  id: 'wash',
  name: 'WASH'
},
{
  id: 'cook',
  name: 'COOK'
},
{
  id: 'cool',
  name: 'COOL'
}
];
