import TimelapseIcon from '@material-ui/icons/Timelapse';
import { ContainerFieldsList, CustomBreadcrumbs, CustomSelectArrayInput, CustomSelectInput } from 'candy-commons-components';
import { func } from 'prop-types';
import React, { useMemo } from 'react';
import {
  BooleanInput,
  Create,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useGetList,
  useTranslate
} from 'react-admin';
import { useForm } from 'react-final-form';


const GWCreateSaveButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm();
  const handleClick = () => {
    if (form.getState().values.cycleType === 'D') {
      form.change('temperature', null);
      form.change('spins', null);
      form.change('spinsRange', null);
      form.change('soil', null);
      form.change('soilLevel', null);
      form.change('color', null);
      form.change('steam', false);
    }
    if (form.getState().values.cycleType === 'WD' && form.getState().values.steam) {
      form.change('dryingLevel', null);
    }
    if (!(form.getState().values.dryingLevel === 0 || form.getState().values.dryingLevel === 9)) {
      form.change('steam', false);
    }
    handleSubmitWithRedirect('list');
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

GWCreateSaveButton.propTypes = ({
  handleSubmitWithRedirect: func.isRequired,
});

const GWCreateToolbar = props => (
  <Toolbar {...props}>
    <GWCreateSaveButton />
  </Toolbar>
);

const GuidedWashingCycleCreate = props => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.guided-washing.name_breadcrumbs'),
      url: 'guided-washing',
      icon: <TimelapseIcon />,
    }, {
      text: translation('resources.guided-washing.create.name'),
      url: '',
    },
  ];
  const {
    ids = [], data,
  } = useGetList(
    'guided-washing-cluster',
    { },
    { },
  );
  const clusterTypes = useMemo(() => (
    ids.map(x => ({ id: data[x].clusterType, name: data[x].clusterType }))
  ), [ids, data]);
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm
          toolbar={<GWCreateToolbar />}
        >

          <ContainerFieldsList>
            <CustomSelectInput
              fullWidth
              source="category"
              choices={[
                { id: 'HOME_CARE', name: 'HOME_CARE' },
                { id: 'SPECIAL_CARE', name: 'SPECIAL_CARE' },
                { id: 'FABRICS', name: 'FABRICS' },
              ]}
              validate={required()}
            />
            <TextInput source="outputCycle" validate={required()} />
            <NumberInput
              fullWidth
              source="prCode"
              validate={required()}
            />
            <NumberInput
              fullWidth
              source="temperature"
            />
            <CustomSelectInput
              fullWidth
              source="spins"
              choices={[
                { id: 0, name: '0' },
                { id: 400, name: '400' },
                { id: 800, name: '800' },
                { id: 1000, name: '1000' },
                { id: 'MAX', name: 'MAX' },
              ]}
            />
            <CustomSelectArrayInput
              fullWidth
              source="spinsRange"
              choices={[
                { id: '0', name: '0' },
                { id: '400', name: '400' },
                { id: '800', name: '800' },
                { id: '1000', name: '1000' },
                { id: 'MAX', name: 'MAX' },
              ]}
            />
            <CustomSelectInput
              fullWidth
              source="cluster"
              choices={clusterTypes}
              validate={required()}
            />
            <CustomSelectInput
              fullWidth
              source="cycleType"
              choices={[
                { id: 'WD', name: 'W-WD' },
                { id: 'D', name: 'D' },
              ]}
              validate={required()}
            />
            <CustomSelectInput
              fullWidth
              source="soil"
              choices={[
                { id: 'LITTLE', name: 'LITTLE' },
                { id: 'NORMAL', name: 'NORMAL' },
                { id: 'HIGH', name: 'HIGH' },
              ]}
            />
            <NumberInput
              fullWidth
              source="soilLevel"
            />
            <CustomSelectInput
              fullWidth
              source="color"
              choices={[
                { id: 'DARKS', name: 'DARKS' },
                { id: 'COLORED', name: 'COLORED' },
                { id: 'WHITES', name: 'WITHES' },
              ]}
            />
            <CustomSelectInput
              fullWidth
              source="dryingLevel"
              label="Dry"
              choices={[
                { id: 2, name: 'IRON' },
                { id: 1, name: 'EXTRA' },
                { id: 3, name: 'CLOSET' },
                { id: 0, name: 'NO_DRY' },
                { id: 9, name: 'DRY' },
              ]}
            />
            <NumberInput
              fullWidth
              source="dryingTime"
            />
            <BooleanInput
              initialValue={false}
              source="steam"
            />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default GuidedWashingCycleCreate;
