import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import moment from 'moment';
import React, { useState } from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  ImageField,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import { useForm } from 'react-final-form';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator';

const CustomImageField = ({ formData, isEditDisabled, ...rest }) => {
  const form = useForm();
  const translation = useTranslate();
  return (
    <>
      <ImageField source="imagePath" label="" {...rest} />
      {!isEditDisabled && (
        <Button
          color="primary"
          onClick={() => form.change('imagePath', null)}
          {...rest}
        >
          {translation('resources.asset.button.delete')}
        </Button>
      )}
    </>
  );
};

const DiscoverEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const [allApplianceFlag, setAllApplianceFlag] = useState(false);
  const [noApplianceFlag, setNoApplianceFlag] = useState(false);
  const [liferayLinkEnabled, setLiferayLinkEnabled] = useState(false);

  const { id } = props;
  const translation = useTranslate();
  const isEditDisabled = !permissions?.discover?.update;
  const breadcrumbsData = [
    {
      text: translation('resources.discover.name'),
      url: 'discover',
      icon: <PageviewIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  const dateFormatter = v => {
    if (!v) return null;
    const d = new Date(v);
    if (Number.isNaN(d)) return null;
    return moment(d).format('YYYY-MM-DD');
  };

  const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
  const handleNoAppliance = (e) => setNoApplianceFlag(e.target.checked);
  const handleLiferayLink = (e) => setLiferayLinkEnabled(e.target.checked);

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} {...props}>
        <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ContainerFieldsList>
            <NumberInput
              min={1}
              label="Order"
              source="order"
              validate={discoverOrderValidator}
              disabled={isEditDisabled}
            />
            <TextInput
              source="title"
              validate={required()}
              disabled={isEditDisabled}
            />
            <TextInput
              source="description"
              validate={required()}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <BooleanInput
            initialValue={false}
            source="featured"
            label="Featured"
            disabled={isEditDisabled}
          />

          <ContainerFieldsList>
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  {!formData.AllAppliance &&
                    !formData.NoAppliance &&
                    !noApplianceFlag &&
                    !allApplianceFlag && (
                      <CustomSelectArrayInput
                        label="Appliance types"
                        source="applianceType"
                        choices={APPLIANCE_TYPES}
                        disabled={
                          noApplianceFlag || allApplianceFlag || isEditDisabled
                        }
                      />
                    )}
                  <BooleanInput
                    source="AllAppliance"
                    label="News for all appliance"
                    disabled={
                      noApplianceFlag || formData.NoAppliance || isEditDisabled
                    }
                    onClick={handleAllAppliance}
                  />
                  <BooleanInput
                    source="NoAppliance"
                    label="News for no appliance"
                    disabled={
                      allApplianceFlag ||
                      formData.AllAppliance ||
                      isEditDisabled
                    }
                    onClick={handleNoAppliance}
                  />
                  <BooleanInput
                    source="isVisibleForNoAppliance"
                    label="Visible for no appliance account"
                  />
                </>
              )}
            </FormDataConsumer>
          </ContainerFieldsList>
          <ContainerFieldsList>
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={COUNTRIES}
              disabled={isEditDisabled}
            />
            <CustomSelectArrayInput
              label="Brands"
              source="brand"
              choices={BRANDS}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <DateInput
              source="from"
              variant="outlined"
              label="from"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={(from, item) => {
                const { to } = item;
                if (to && from && !moment(from).isBefore(to)) {
                  return 'FROM must be before TO';
                }
              }}
              disabled={isEditDisabled}
            />
            <DateInput
              source="to"
              variant="outlined"
              label="to"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={(to, item) => {
                const { from } = item;
                if (from && to && !moment(from).isBefore(to)) {
                  return 'TO must be after FROM';
                }
              }}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <TextInput source="category" disabled={isEditDisabled} />
            <TextInput
              source="link"
              validate={required()}
              disabled={isEditDisabled}
            />
            <BooleanInput
              initialValue={false}
              source="deepLink"
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <TextInput
              source="latitude"
              label="Latitude"
              disabled={isEditDisabled}
            />
            <TextInput
              source="longitude"
              label="Longitude"
              disabled={isEditDisabled}
            />
            <TextInput
              source="radius"
              label="Radius (in meters)"
              disabled={isEditDisabled}
            />
            <TextInput
              source="appVersion"
              label="Minimum app version (blank for any)"
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <SimpleShowLayout>
              <FormDataConsumer>
                {formDataProps =>
                  formDataProps.formData.imagePath && (
                    <>
                      <CustomImageField
                        {...formDataProps}
                        isEditDisabled={isEditDisabled}
                      />
                    </>
                  )
                }
              </FormDataConsumer>
            </SimpleShowLayout>
          </ContainerFieldsList>

          {!isEditDisabled && (
            <ImageInput
              source="pictures"
              accept="image/*"
              placeholder={
                <p>{translation('resources.asset.fields.input_file')}</p>
              }
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          )}
          <ContainerFieldsList>
            <BooleanInput
              initialValue={liferayLinkEnabled}
              source="liferayLinkEnabled"
              onClick={handleLiferayLink}
              disabled={isEditDisabled}
            />
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  {formData.liferayLinkEnabled ? (
                    <>
                      <TextInput source="structureId" disabled={isEditDisabled} />
                      <TextInput source="liferayId" disabled={isEditDisabled} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </FormDataConsumer>
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({
  reverseButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
});

export default DiscoverEdit;
