import React from 'react';
import { TextInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';

export const ServiceAppSyncValues = () => {
  const p = useFormState();
  const { values } = p;
  const { serviceAppSync } = values || {};

  if (!serviceAppSync) return null;

  return (
    <>
      <hr style={{width: '100%'}} />
      <p style={{ paddingLeft: '1rem', width: '100%' }}>
        Service App Sync Values
      </p>
      <TextInput
        source="serviceAppSync_localProtocolErrorCode"
        label="Local protocol error code"
      />
      <TextInput
        source="serviceAppSync_technicalDescription"
        label="Technical description"
      />
      <TextInput
        source="serviceAppSync_possibleCause"
        label="Possible cause"
      />
      <TextInput
        source="serviceAppSync_recommendedTestToCat"
        label="Recommended test to CAT"
      />
    </>
  );
};
