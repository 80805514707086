const LanguageStatus = require('./LanguageStatus');
const PhraseEvent = require('./PhraseEvent');
const Season = require('./Season');
const OtaExecutionState = require('./otaExecutionState');
const WashingTemperatureRange = require('./washingTemperatureRange');
const LanguageCodes = require('./Languages');
const MultiAccount = require('./multiAccount');
const EcoEvents = require('./EcoEvents.json');
module.exports = {
  LanguageStatus,
  PhraseEvent,
  Season,
  OtaExecutionState,
  WashingTemperatureRange,
  LanguageCodes,
  MultiAccount,
  EcoEvents
};