import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React, { useCallback, useMemo } from 'react';
import {
  FunctionField,
  ArrayField,
  TextField,
  useTranslate,
  NumberField,
  DateField,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const GetFeedBackTargetList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.getfeedback-target.name'),
      url: '',
      icon: <PageviewIcon />,
    },
  ];

  const renderCountries = useCallback(
    item =>
      Array.isArray(item?.countries)
        ? item?.countries.join(',')
        : item?.countries || '',
    [],
  );

  const renderApplianceTypes = useCallback(item => {
    if (item?.isAllAppliance) {
      return 'ALL';
    }
    if (item?.isNoAppliance) {
      return 'None';
    }
    if (Array.isArray(item?.applianceType)) {
      return item.applianceType.join(',');
    }
  }, []);

  const renderBrands = useCallback(
    item =>
      Array.isArray(item?.brand) ? item?.brand.join(',') : item?.brand || '',
    [],
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        {...props}
        perPage={25}
        exporter={false}
        bulkActionButtons={
          permissions && permissions.assistance && permissions.assistance.delete
        }
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="eventName" label="Event name" />
          <TextField source="isActive" label="Active" />
          <FunctionField
            label="Appliance type"
            render={renderApplianceTypes}
            source="applianceType"
          />
          <FunctionField
            label="Countries"
            render={renderCountries}
            source="countries"
          />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

const useStyles = makeStyles({});

export default GetFeedBackTargetList;
