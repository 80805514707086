import React from 'react';
import { Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
} from 'react-admin';
import { ValueInput, validateJson } from './commonComponents';
import applianceConfigurationTypes from './constants/applianceConfigurationTypes';

const breadcrumbsData = [
    {
        text: 'App Configuration',
        url: '',
        icon: <SettingsIcon />,
    },
    {
        text: 'Create',
        url: '',
    },
];

const AppConfigurationCreate = (props) => {
    const classes = useStyle();
    const [selectedType, setSelectedType] = useState('');
    const [customType, setCustomType] = useState(false);

    useEffect(() => {
        if (selectedType === 'custom') {
            setCustomType(true);
        } else {
            setCustomType(false);
        }
    }, [selectedType]);

    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm validate={validateJson}>
                    <CustomSelectInput
                        allowEmpty
                        source="type"
                        choices={applianceConfigurationTypes}
                        onChange={(event) => {
                            try {
                                setSelectedType(event.target.value);
                            } catch (_error) {}
                        }}
                        validate={[required()]}
                    />
                    {selectedType !== 'app-update' ? (
                        <ContainerFieldsList style={{ paddingLeft: 10 }}>
                            <Typography variant="h6" component="h6">
                                General configurations
                            </Typography>
                            <ContainerFieldsList>
                                <TextInput
                                    source="iosMinVersion"
                                    label="iOS minimum version"
                                />
                                <TextInput
                                    source="androidMinVersion"
                                    label="Android minimum version"
                                />
                            </ContainerFieldsList>

                            <Typography variant="h6" component="h6">
                                Key-value configurations
                            </Typography>

                            {customType ? (
                                <ContainerFieldsList>
                                    <TextInput
                                        source="customType"
                                        label="Custom type"
                                    />
                                    <TextInput
                                        source="key"
                                        label="Config name"
                                    />
                                    <ValueInput
                                        source="value"
                                        label="Config value"
                                    />
                                </ContainerFieldsList>
                            ) : (
                                <ContainerFieldsList>
                                    <TextInput
                                        source="key"
                                        label="Config name"
                                    />
                                    <ValueInput
                                        source="value"
                                        label="Config value"
                                    />
                                </ContainerFieldsList>
                            )}
                        </ContainerFieldsList>
                    ) : (
                        <ContainerFieldsList
                            className={classes.customContainer}
                            style={{ paddingLeft: 10 }}
                        >
                            <Typography variant="h6" component="h6">
                                General configurations
                            </Typography>
                            <BooleanInput
                                initialValue={false}
                                source="appUpdate"
                                label="App Update"
                            />
                        </ContainerFieldsList>
                    )}
                </SimpleForm>
            </Create>
        </>
    );
};

const useStyle = makeStyles((theme) => ({
    customContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'flex-start',
        marginRight: -8,
        marginBottom: 20,
        '& >div.MuiFormControl-root': {
            width: 260,
            margin: 8,
        },
    },
}));

export default AppConfigurationCreate;
