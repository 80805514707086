import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { CustomBreadcrumbs, CustomList, CustomFilter } from 'candy-commons-components';
import React from 'react';
import {
  TextField,
  TextInput,
} from 'react-admin';

import { CustomDatagrid } from '../../components/organisms/customDatagrid';

const breadcrumbsData = [
  {
    text: 'Ingredient [REF]',
    url: '',
    icon: <ViewComfyIcon />,
  },
];

const IngredientRefFilter = props => (
  <CustomFilter {...props}>
    <TextInput
      source="name"
      label="Name (Translation key)"
    />
  </CustomFilter>
);

const IngredientRefList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList
      perPage={25}
      exporter={false}
      {...props}
      filters={<IngredientRefFilter />}
      bulkActionButtons={permissions && permissions.ingredient && permissions.ingredient.delete}
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name (Translation key)" />
        <TextField source="category" label="Category" />
        <TextField source="fridgeExpiryTime" label="Fridge Expiry Time" />
        <TextField source="freezerExpiryTime" label="Freezer Expiry Time" />
      </CustomDatagrid>
    </CustomList>
  </>
);


export default IngredientRefList;
