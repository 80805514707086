import PageviewIcon from '@material-ui/icons/Pageview';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
} from 'candy-commons-components';
import moment from 'moment';
import React, { useState } from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  ImageField,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator';

const GetFeedBackTargetCreate = props => {
  const translation = useTranslate();
  const [allApplianceFlag, setAllApplianceFlag] = useState(false);
  const [noApplianceFlag, setNoApplianceFlag] = useState(false);
  const breadcrumbsData = [
    {
      text: translation('resources.getfeedback-target.name'),
      url: 'getfeedback-target',
      icon: <PageviewIcon />,
    },
    {
      text: translation('resources.getfeedback-target.create.name'),
      url: '',
    },
  ];

  const handleAllAppliance = e => setAllApplianceFlag(e.target.checked);

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <TextInput
              label="Event name"
              source="eventName"
              validate={required()}
            />
            <BooleanInput
              initialValue={false}
              source="isActive"
              label="Active"
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <FormDataConsumer>
              {() => (
                <>
                  {!allApplianceFlag && (
                    <CustomSelectArrayInput
                      label="Appliance types"
                      source="applianceType"
                      choices={APPLIANCE_TYPES}
                      disabled={allApplianceFlag}
                    />
                  )}
                  <BooleanInput
                    label="All appliance"
                    initialValue={false}
                    source="isAllAppliance"
                    disabled={noApplianceFlag}
                    onClick={handleAllAppliance}
                  />
                </>
              )}
            </FormDataConsumer>
          </ContainerFieldsList>
          <ContainerFieldsList>
            <BooleanInput
              initialValue={false}
              label="No enrolled appliance"
              source="isNoAppliance"
            />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={COUNTRIES}
              validate={required()}
            />
            {/* <CustomSelectArrayInput
              label="Brands"
              source="brand"
              choices={BRANDS}
            /> */}
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default GetFeedBackTargetCreate;
