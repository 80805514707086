import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useTranslate
} from 'react-admin';

import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  FieldsGenerator
} from 'candy-commons-components';
import CustomToolbar from '../../components/CustomToolbar';

const ApplianceSettingsEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['appliance-settings']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.appliance-settings.name'),
      url: 'appliance-settings',
      icon: <SettingsApplicationsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...p }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'applianceType',
          type: 'textField',
          source: 'applianceType',
        },
        {
          label: 'series',
          type: 'textField',
          source: 'series',
        },
      ]}
      record={record}
      {...p}
    />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} undoable={false} {...props}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ContainerFieldsList>
            <ShowList />
          </ContainerFieldsList>
          <ArrayInput className={classes.arrayInput} source="options" disabled={isEditDisabled}>
            <SimpleFormIterator classes={{ form: classes.form }}>
              <TextInput source="name" disabled={isEditDisabled} />
              <TextInput source="value" disabled={isEditDisabled} />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: -8,
    '& .MuiFormControl-root.MuiTextField-root': {
      width: 260,
      margin: 8,
    },
    '& >.ra-input': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  reverseButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
  arrayInput: {
    '& .MuiFormLabel-root': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
});

export default ApplianceSettingsEdit;
