import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { CustomBreadcrumbs, CustomList, CustomFilter } from 'candy-commons-components';
import React from 'react';
import CustomDatagrid from '../../../components/organisms/customDatagrid';
import { BooleanField, TextField, TextInput, FunctionField } from 'react-admin';
import DynamoPagination from '../../../components/DynamoPagination';
//*Guardare i filtri di programRules
const HowToFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.how-to.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const breadcrumbsData = [
  {
    text: 'How To',
    url: '',
    icon: <LiveHelpIcon />,
  }
];

const HowToList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList
      perPage={10}
      exporter={false}
      {...props}
      pagination={<DynamoPagination resource='multi-account-how-to' />}
      filters={<HowToFilter />}
      bulkActionButtons={
        permissions &&
        permissions['multi-account-how-to'] &&
        permissions['multi-account-how-to'].delete
      }
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField label={'Title'}  source='id' />
        <TextField label={'Tag'} source='tag' />
        <FunctionField
          label="Countries"
          render={item => (item.countries || []).join(',')}
          source="countries"
        />
        <BooleanField label={'Publish'} source='publish' />
      </CustomDatagrid>
    </CustomList>
  </>
);

export default HowToList;
