const getRestrictedLanguages = ({
  permissions,
}) => {
  const permissionLanguages = {
    ...permissions.translation,
  };

  delete permissionLanguages.create;
  delete permissionLanguages.read;
  delete permissionLanguages.update;
  delete permissionLanguages.delete;
  delete permissionLanguages.restrictLanguages;

  let restrictedLanguages = Object.keys(permissionLanguages);

  restrictedLanguages = restrictedLanguages.filter(x => permissionLanguages[x]);

  return restrictedLanguages;
};

export {
  getRestrictedLanguages,
};
