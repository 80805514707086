import { makeStyles } from '@material-ui/core/styles';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { ContainerFieldsList, CustomBreadcrumbs, CustomSelectArrayInput, CustomSelectInput } from 'candy-commons-components';
import React, { useMemo } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create, SimpleForm,
  SimpleFormIterator, TextInput,
  required,
  useGetList, useTranslate
} from 'react-admin';

const GuidedWashingClusterCreate = props => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
  {
    text: translation('resources.guided-washing-cluster.name_breadcrumbs'),
    url: 'guided-washing-cluster',
    icon:<GroupWorkIcon/>
  }, {
    text: translation('resources.guided-washing-cluster.create.name'),
    url: ''
  }
];
  const {
    ids = [], data,
  } = useGetList(
    'guided-washing-cluster',
    { },
    { },
  );
  const types = useMemo(() => (
    ids.filter(x => data[x].category === 'FABRICS').map(x => ({ id: data[x].clusterType, name: data[x].clusterType }))
  ), [ids, data]);

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectInput
              source="category"
              choices={[
                { id: 'HOME_CARE', name: 'HOME_CARE' },
                { id: 'SPECIAL_CARE', name: 'SPECIAL_CARE' },
                { id: 'FABRICS', name: 'FABRICS' },
              ]}
              validate={required()}
            />
            <TextInput source="code" validate={required()} />
            <TextInput source="clusterType" validate={required()} />
            <BooleanInput source="active" initialValue={false} />
          </ContainerFieldsList>
          <ArrayInput source="outputCycle">
            <SimpleFormIterator classes={{form: classes.form}}>
              <CustomSelectInput
                source="type"
                choices={[
                  { id: 'WD', name: 'W-WD' },
                  { id: 'D', name: 'D' },
                ]}
                validate={required()}
              />
              <BooleanInput source="steam" />
              <CustomSelectArrayInput
                source="dry"
                choices={[
                  { id: 'IRON', name: 'IRON' },
                  { id: 'EXTRA', name: 'EXTRA' },
                  { id: 'CLOSET', name: 'CLOSET' },
                  { id: 'NO_DRY', name: 'NO_DRY' },
                  { id: 'DRY', name: 'DRY' },
                ]}
              />
              <CustomSelectInput
                source="multiple"
                choices={types}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: -8,
    "& .MuiFormControl-root.MuiTextField-root": {
      width: 260,
      margin: 8
    },
    "& >.ra-input": {
      display: 'flex',
      alignItems: 'center'
    }
  }
});

export default GuidedWashingClusterCreate;
