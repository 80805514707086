import PageviewIcon from '@material-ui/icons/Pageview';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import moment from 'moment';
import React, { useState } from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  ImageField,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator';

const DiscoverCreate = (props) => {
  const translation = useTranslate();
  const [allApplianceFlag, setAllApplianceFlag] = useState(false);
  const [noApplianceFlag, setNoApplianceFlag] = useState(false);
  const [liferayLinkEnabled, setLiferayLinkEnabled] = useState(false);
  const breadcrumbsData = [
    {
      text: translation('resources.discover.name'),
      url: 'discover',
      icon: <PageviewIcon />,
    },
    {
      text: translation('resources.discover.create.name'),
      url: '',
    },
  ];

  const dateFormatter = v => {
    const d = new Date(v);
    if (Number.isNaN(d)) return null;
    return moment(d).format('YYYY-MM-DD');
  };

  const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
  const handleNoAppliance = (e) => setNoApplianceFlag(e.target.checked);
  const handleLiferayLink = (e) => setLiferayLinkEnabled(e.target.checked);

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <NumberInput
              min={1}
              label="Order"
              source="order"
              validate={discoverOrderValidator}
            />
            <TextInput source="title" validate={required()} />
            <TextInput source="description" validate={required()} />
          </ContainerFieldsList>
          <BooleanInput initialValue={false} source="featured" label="Featured" />

          <ContainerFieldsList>
            <FormDataConsumer>
              {() => (
                <>
                  {
                    !noApplianceFlag && !allApplianceFlag && (
                      <CustomSelectArrayInput
                        label="Appliance types"
                        source="applianceType"
                        choices={APPLIANCE_TYPES}
                        disabled={noApplianceFlag || allApplianceFlag}
                      />
                    )
                  }
                  <BooleanInput
                    source="AllAppliance"
                    label="News for all appliance"
                    disabled={noApplianceFlag}
                    onClick={handleAllAppliance}
                  />
                  <BooleanInput
                    source="NoAppliance"
                    label="News for no appliance"
                    disabled={allApplianceFlag}
                    onClick={handleNoAppliance}
                  />
                  <BooleanInput
                    source="isVisibleForNoAppliance"
                    label="Visible for no appliance account"
                  />
                </>
              )}
            </FormDataConsumer>

          </ContainerFieldsList>
          <ContainerFieldsList>
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={COUNTRIES}
            />
            <CustomSelectArrayInput
              label="Brands"
              source="brand"
              choices={BRANDS}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <DateInput
              source="from"
              variant="outlined"
              label="from"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={(from, item) => {
                const { to } = item;
                if (to && from && !moment(from).isBefore(to)) {
                  return 'FROM must be before TO';
                }
              }}
            />
            <DateInput
              source="to"
              variant="outlined"
              label="to"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={(to, item) => {
                const { from } = item;
                if (from && to && !moment(from).isBefore(to)) {
                  return 'TO must be after FROM';
                }
              }}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <TextInput source="category" />
            <TextInput source="link" validate={required()} />
            <BooleanInput initialValue={false} source="deepLink" />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <TextInput source="latitude" label="Latitude" />
            <TextInput source="longitude" label="Longitude" />
            <TextInput source="radius" label="Radius (in meters)" />
            <TextInput source="appVersion" label="Minimum app version (blank for any)" />
          </ContainerFieldsList>
          <ImageInput
            source="pictures"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ContainerFieldsList>
            <BooleanInput
              initialValue={liferayLinkEnabled}
              source="liferayLinkEnabled"
              onClick={handleLiferayLink}
            />
            {
              liferayLinkEnabled ? (
                <>
                  <TextInput source="structureId" />
                  <TextInput source="liferayId" />
                </>
              ) : <></>
            }
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};


export default DiscoverCreate;
