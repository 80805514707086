import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import {
  ContainerFieldsList,
  FieldsGenerator,
  CustomBreadcrumbs,
} from 'candy-commons-components';

import {
  Edit,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import applianceType from '../../data/applianceType.json';
const showListData = [
  {
    label: 'resources.mocks.fields.code',
    type: 'textField',
    source: 'code',
  },
];
const MockDemoEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['mock-demo']?.update;
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator fields={showListData} record={record} {...props} />
  );
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.mock-demo.name'),
      url: 'mock-demo',
      icon: <ChildFriendlyIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} {...props}>
        <SimpleForm
          redirect="list"
          toolbar={
            <CustomToolbar
              {...props}
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ShowList />
          <ContainerFieldsList>
            <TextInput source="mockName" disabled={isEditDisabled} />
          </ContainerFieldsList>
          {!isEditDisabled && (
            <FileInput
              source="mocks"
              accept="application/json"
              validate={required()}
              placeholder={
                <p>{translation('resources.mock.fields.input_file')}</p>
              }
            >
              <FileField source="src" title="title" />
            </FileInput>
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};
const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  reverseButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
});
export default MockDemoEdit;
