import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { reducer as reduxFormReducer } from "redux-form";
import {
  adminReducer,
  adminSaga,
  defaultI18nProvider,
  USER_LOGOUT
} from "react-admin";
import rootReducer from "./reducers";
// import appMiddlewares from '../middlewares';

const initStore = ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    // add your own reducers here
    custom: rootReducer,
    form: reduxFormReducer
  });
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider)
        // add your own sagas here
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === "development" &&
      typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true
        //  traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {},
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        thunk,
        logger
        // ...appMiddlewares,
      )
      // add your own enhancers here
    )
  );
  sagaMiddleware.run(saga);

  store.dispatch({ type: "@@APP_START" });
  store.dispatch({ type: "@@APP_START2" });

  return store;
};

let reduxStore;

const getStore = params => {
  if (reduxStore) return reduxStore;

  reduxStore = initStore(params);

  return reduxStore;
};

export default getStore;
