import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  ArrayField, BooleanField, ChipField, SingleFieldList, TextField, TextInput, useTranslate,
} from 'react-admin';

import DynamoPagination from '../../../components/DynamoPagination';
import CustomDatagrid from '../../../components/organisms/customDatagrid';

const GWClusterFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.guided-washing-cluster.fields.search" source="q" alwaysOn />
  </CustomFilter>
);
const GuidedWashingClusterList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.guided-washing-cluster.name_breadcrumbs'),
      url: '',
      icon: <GroupWorkIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        {...props}
        exporter={false}
        pagination={<DynamoPagination resource="gwCluster" />}
        filters={<GWClusterFilter />}
        bulkActionButtons={permissions && permissions['guided-washing'] && permissions['guided-washing'].delete}
      >
        <CustomDatagrid selectable rowClick="edit">
          <ArrayField source="outputCycle">
            <SingleFieldList linkType={false}>
              <ChipField source="type" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="code" />
          <BooleanField source="active" />
          <TextField source="clusterType" />
          <TextField source="category" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default GuidedWashingClusterList;
