export const showListData = [
  {
    label: "resources.user.fields.Username",
    type: "textField",
    source: "Username"
  },
  {
    label: "resources.user.fields.UserCreateDate",
    type: "dateField",
    source: "UserCreateDate"
  },
  {
    label: "resources.user.fields.UserLastModifiedDate",
    type: "dateField",
    source: "UserLastModifiedDate"
  },
  {
    label: "resources.user.fields.Enabled",
    type: "booleanField",
    source: "Enabled"
  },
  {
    label: "resources.user.fields.UserStatus",
    type: "textField",
    source: "UserStatus"
  },
  {
    label: "resources.user.fields.id",
    type: "textField",
    source: "id"
  },
  {
    label: "resources.user.fields.email",
    type: "textField",
    source: "email"
  }
];
