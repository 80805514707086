import RowingIcon from '@material-ui/icons/Rowing';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import {FunctionField, TextField, TextInput, useTranslate} from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const HintsFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.hints.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const HintList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.hints.name'),
      url: '',
      icon: <RowingIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        pagination={<DynamoPagination resource="hints" />}
        filters={<HintsFilter />}
        bulkActionButtons={(permissions && permissions.hints && permissions.hints.delete) ? undefined : false}
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="category" />
          <TextField label="order" source="sortOrder" />
          <TextField source="brand" />
          <TextField source="series" />
          <FunctionField
            label="Series Version"
            render={
              item => {
                const { seriesVersion } = item;
                if (seriesVersion && Array.isArray(seriesVersion)) {
                  return seriesVersion.join(',');
                }

                return seriesVersion;
              }
            }
            source="seriesVersion"
          />
          <TextField source="applianceModel" />
          <TextField source="title" />
          <TextField source="body" />
          <FunctionField
            label="Countries"
            render={item => (item.countries || []).join(',')}
            source="countries"
          />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};


export default HintList;
