import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import { categoryName, statusName } from './source';

const SmartDoorCounterCreateRule = (props) => {
  const translation = useTranslate();

  const breadcrumbsData = [
    {
      text: translation('resources.smart-door-counter.name'),
      url: 'smart-door-counter',
      icon: <SettingsIcon />,
    },
    {
      text: translation('resources.smart-door-counter.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <b>Create New Rule</b>
          <ContainerFieldsList>
            <CustomSelectInput source="score" choices={statusName} validate={[required()]} />
            <CustomSelectInput source='category' choices={categoryName} validate={required()} />
            <NumberInput source='min' />
            <NumberInput source='max' />
            <TextInput source="description" validate={[required()]} />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default SmartDoorCounterCreateRule;
