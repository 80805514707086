import SettingsIcon from '@material-ui/icons/Settings';
import {
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import React from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import { useStyles } from '../styles';

const RewardsCreate = props => {
  const translation = useTranslate();
  const classes = useStyles();
  const [codeType, onChangeCodeType] = React.useState();
  const [rewardType, onChangeRewardType] = React.useState();
  const breadcrumbsData = [
    {
      text: translation('resources.promo-rewards.name'),
      url: 'promo-rewards',
      icon: <SettingsIcon />,
    },
    {
      text: translation('resources.promo-rewards.name.create.name'),
      url: '',
    },
  ];

  const onChangeCodeTypeHandler = event => {
    onChangeCodeType(event.target.value);
  };
  const onChangeRewardTypeHandler = event => {
    onChangeRewardType(event.target.value);
  };

  const transform = data => {
    if (data.content.rewardType === 'PHYSICAL') {
      return {
        ...data,
        content: {
          ...data.content,
          codeType: null,
          code: null,
          couponType: null,
        },
      };
    } else {
      return data;
    }
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list" className={classes.fullWidth}>
          <TextInput
            source="slug"
            label="Slug"
            validate={required()}
            className={classes.fullWidth}
          />
          <TextInput
            source="content.externalCmsStructureId"
            label="External CMS Structure Id"
            validate={[required()]}
            className={classes.fullWidth}
          />
          <TextInput
            source="content.externalCmsContentId"
            label="External CMS Content Id"
            validate={[required()]}
            className={classes.fullWidth}
          />
          <CustomSelectInput
            validate={[required()]}
            label={'Reward type'}
            source={'content.rewardType'}
            choices={[
              { id: 'PHYSICAL', name: 'Physical' },
              { id: 'DIGITAL', name: 'Digital' },
            ]}
            onChange={onChangeRewardTypeHandler}
          />
          {rewardType === 'DIGITAL' && (
            <>
              <CustomSelectInput
                validate={[required()]}
                label={'Code type'}
                source={'content.codeType'}
                choices={[
                  { id: 'GENERIC', name: 'generic' },
                  { id: 'UNIQUE', name: 'unique' },
                ]}
                onChange={onChangeCodeTypeHandler}
              />
              {codeType === 'GENERIC' && (
                <TextInput
                  source="content.code"
                  label="Code"
                  className={classes.fullWidth}
                  validate={[required()]}
                />
              )}
              {codeType === 'UNIQUE' && (
                <TextInput
                  source="content.couponType"
                  label="Code List Id"
                  className={classes.fullWidth}
                  validate={[required()]}
                />
              )}
            </>
          )}
        </SimpleForm>
      </Create>
    </>
  );
};

export default RewardsCreate;
