import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomSelectArrayInput, CustomSelectInput } from 'candy-commons-components';
import {
  DateInput,
  NumberInput,
  TextInput,
  required
} from 'react-admin';
import { CRITERIA, INPUT_VARIANT } from '../config';

const useStyles = makeStyles({
  containerFormInput: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridGap: '20px',
    width: '100%',
    alignItems: 'start',
    borderBottom: '1px solid #0000001f',
    marginBottom: '20px',
  },
  resetMargin: {
    margin: 0,
  },
  queryDeleteIcon: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  queryDeleteIconColor: {
    color: 'red',
  },
});

const BaseContainerInput = ({
  label,
  level,
  rules,
  children,
  removeInputHandler,
  onChange,
  isEditDisabled
}) => {
  const classes = useStyles();
  const getFieldKey = () =>
    `${level === 1 ? 'criteria' : 'nestedCriteria'}.${rules.source}.rule`;

  return (
    <Box>
      <Box className={classes.containerFormInput}>
        {level && rules && (
          <CustomSelectInput
            label="Rule"
            source={getFieldKey()}
            choices={rules.choices}
            validate={[required()]}
            className={classes.resetMargin}
            disabled={isEditDisabled}
          />
        )}
        {children}
        {removeInputHandler && (
          <Box className={classes.queryDeleteIcon}>
            <DeleteIcon
              className={classes.queryDeleteIconColor}
              onClick={removeInputHandler}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const BasicQueryInput = ({
  level,
  typeKey,
  removeInputHandler,
  isActive,
  optionsList,
  variant = 'text',
  isEditDisabled
}) => {
  const classes = useStyles();
  const { label, type, rules } = CRITERIA[typeKey];
  const fieldKey = `${
    level === 1 ? 'criteria' : 'nestedCriteria'
  }.${type}.value`;

  if (!isActive) return null;

  return (
    <BaseContainerInput
      label={label}
      level={level}
      removeInputHandler={removeInputHandler}
      rules={{ source: type, choices: rules }}
      isEditDisabled={isEditDisabled}
    >
      {variant === INPUT_VARIANT.TEXT && (
        <TextInput
          source={fieldKey}
          label={label}
          validate={[required()]}
          className={classes.resetMargin}
          disabled={isEditDisabled}
        />
      )}
      {variant === INPUT_VARIANT.NUMBER && (
        <NumberInput
          min="1"
          source={fieldKey}
          label={label}
          validate={[required()]}
          className={classes.resetMargin}
          disabled={isEditDisabled}
        />
      )}
      {variant === INPUT_VARIANT.DATE && (
        <DateInput
          source={fieldKey}
          label={label}
          validate={[required()]}
          className={classes.resetMargin}
          disabled={isEditDisabled}
        />
      )}
      {variant === INPUT_VARIANT.MULTIPLE_CHOICES && optionsList && (
        <CustomSelectArrayInput
          source={fieldKey}
          label={label}
          validate={[required()]}
          choices={optionsList}
          className={classes.resetMargin}
          disabled={isEditDisabled}
        />
      )}
      {variant === INPUT_VARIANT.SINGLE_CHOICES && (
        <CustomSelectInput
          source={fieldKey}
          label={label}
          choices={optionsList}
          validate={[required()]}
          className={classes.resetMargin}
          disabled={isEditDisabled}
        />
      )}
      {variant === INPUT_VARIANT.POSITION && (
        <>
          <NumberInput
            source={`${fieldKey}.lat`}
            label="Latitude"
            validate={[required()]}
            className={classes.resetMargin}
            disabled={isEditDisabled}
          />
          <NumberInput
            source={`${fieldKey}.lon`}
            label="Longitude"
            validate={[required()]}
            className={classes.resetMargin}
            disabled={isEditDisabled}
          />
          <NumberInput
            source={`${fieldKey}.radius`}
            label="Radius (in meters)"
            validate={[required()]}
            className={classes.resetMargin}
            disabled={isEditDisabled}
          />
        </>
      )}
    </BaseContainerInput>
  );
};
