import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  CustomBreadcrumbs,
  CustomList
} from 'candy-commons-components';
import {
  TextField
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const breadcrumbsData = [
  {
    text: 'App Configuration',
    url: '',
    icon: <SettingsIcon />,
  },
];

const AppConfigurationList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList
      perPage={25}
      exporter={false}
      pagination={<DynamoPagination resource="app-configuration" />}
      {...props}
      bulkActionButtons={
        permissions &&
        permissions['app-configuration'] &&
        permissions['app-configuration'].delete
      }
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField source="type" />
        <TextField source="key" />
        <TextField source="value" />
      </CustomDatagrid>
    </CustomList>
  </>
);

export default AppConfigurationList;
