import React from 'react';
import RowingIcon from '@material-ui/icons/Rowing';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  BooleanInput,
  Create,
  ImageField,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import connectivityOptions from '../../data/connectivityOptions.json';
import connectivityOptionsExtra from '../../data/connectivityOptionsExtra.json';
import countries from '../../data/programListRulesCountries.json';


const HintCategoryCreate = (props) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.hints-category.name'),
      url: 'hints',
      icon: <RowingIcon />,
    },
    {
      text: translation('resources.hints-category.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list">
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
            />
            <TextInput source="code" validate={required()} />
            <TextInput source="title" validate={required()} />
            <TextInput label="Description" source="description" />
            <CustomSelectArrayInput
              label="Brand"
              source="brand"
              choices={brand}
            />
            <TextInput label="Series" source="series" />
            <TextInput label="Series Version" source="seriesVersion" />
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={countries}
              parse={x => (x.includes('ALL') ? ['ALL'] : x)}
            />
            <CustomSelectArrayInput
              source="connectivity"
              allowEmpty
              choices={[
                ...connectivityOptions,
                ...connectivityOptionsExtra
              ]}
            />
            <BooleanInput label="active" source="active" initialValue />
          </ContainerFieldsList>
          <SimpleShowLayout>
            <ImageField source="imagePath" addLabel={false} />
          </SimpleShowLayout>
          <ImageInput
            source="pictures"
            accept="image/*"
            placeholder={
              <p>{translation('resources.hints.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default HintCategoryCreate;
