import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';

import {
  ArrayInput,
  Edit,
  ImageField,
  required,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import series from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
import options from './serviceMode.mock';

const ServiceModeEdit = ({ permissions = {}, ...props }) => {
  const transaltion = useTranslate();
  const [tdChoices, setTdChoices] = useState(false);
  const isEditDisabled = !permissions?.['service-mode']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: transaltion('resources.service-mode.name'),
      url: 'service-mode',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
              disabled={isEditDisabled}
            />
            <CustomSelectArrayInput
              allowEmpty
              source="brand"
              choices={[
                ...brand,
                {
                  id: 'TERZISMO',
                  name: 'TERZISMO',
                },
              ]}
              disabled={isEditDisabled}
            />
            <CustomSelectArrayInput
              allowEmpty
              source="series"
              choices={series.sort((a, b) => a.name.localeCompare(b.name))}
              disabled={isEditDisabled}
            />
            <TextInput source="seriesVersion" disabled={isEditDisabled} />
            <CustomSelectInput
              source="loadingType"
              allowEmpty
              choices={LOADING_TYPE}
              disabled={isEditDisabled}
            />
            <TextInput source="installationType" disabled={isEditDisabled} />
            <TextInput source="display" disabled={isEditDisabled} />
            <TextInput source="title" label="title" disabled={isEditDisabled} />
            <TextInput source="description" label="description" disabled={isEditDisabled} />
            <CustomSelectInput
              allowEmpty
              source="serviceability"
              label="Serviceability"
              choices={options}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <ArrayInput source="steps" disabled={isEditDisabled}>
            <SimpleFormIterator getItemLabel={index => `${index + 1}-key`}>
              <SimpleShowLayout>
                <ImageField source="imagePath" />
              </SimpleShowLayout>
              {/* <TextInput label="title" source="title" /> */}
              <TextInput label="description" source="description" />
              <ImageInput
                source="imagePath"
                label="Image"
                accept="image/*"
                placeholder={<p>Drop your file here</p>}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ServiceModeEdit;
