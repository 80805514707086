import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';

import {
  ArrayInput,
  BooleanInput,
  Edit,
  ImageField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import display from '../../data/display.json';
import series from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
import type_ from '../../data/type.json';
import options from './applianceWakeUp.mock';

const ApplianceWakeUpEdit = ({ permissions = {}, ...props }) => {
  const transaltion = useTranslate();
  const [tdChoices, setTdChoices] = useState(false);
  const isEditDisabled = !permissions['appliance-wake-up']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: transaltion('resources.appliance-wake-up.name'),
      url: 'appliance-wake-up',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
              disabled={isEditDisabled}
            />
            <CustomSelectInput
              allowEmpty
              source="brand"
              choices={brand}
              disabled={isEditDisabled}
            />
            <CustomSelectInput
              allowEmpty
              source="series"
              choices={series}
              disabled={isEditDisabled}
            />
            <TextInput source="seriesVersion" disabled={isEditDisabled} />
            <CustomSelectInput
              allowEmpty
              source="loadingType"
              choices={LOADING_TYPE}
              disabled={isEditDisabled}
            />
            <TextInput source="installationType" disabled={isEditDisabled} />
            <CustomSelectInput
              allowEmpty
              source="type"
              choices={type_}
              disabled={isEditDisabled}
            />
            <CustomSelectArrayInput
              allowEmpty
              source="display"
              choices={display}
              disabled={isEditDisabled}
            />
            <TextInput source="title" label="title" disabled={isEditDisabled} />
            <TextInput
              source="description"
              label="description"
              disabled={isEditDisabled}
            />
            <CustomSelectInput
              allowEmpty
              source="options"
              label="Enrollment type"
              choices={options}
              disabled={isEditDisabled}
            />
            <BooleanInput
              initialValue={false}
              source="resetGesture"
              label="Reset gesture"
              disabled={isEditDisabled}
            />
            <BooleanInput
              initialValue={false}
              source="easyEnrollment"
              label="Easy enrollment"
              disabled={isEditDisabled}
            />
            <BooleanInput
              initialValue={false}
              source="standardEnrollment"
              label="Standard enrollment"
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <ArrayInput source="steps" disabled={isEditDisabled}>
            <SimpleFormIterator getItemLabel={index => `${index + 1}-key`}>
              <TextInput source="stepTitle" label="Step title" />
              <TextInput source="stepSubtitle" label="Step subtitle" />

              <SimpleShowLayout>
                <ImageField source="imagePath" />
              </SimpleShowLayout>
              {/* <TextInput label="title" source="title" /> */}
              <TextInput label="description" source="description" />
              <BooleanInput
                initialValue={false}
                source="standard"
                label="Standard flow (wifi)"
              />
              <NumberInput source="delay" label="Delay" />
              {!isEditDisabled && (
                <ImageInput
                  source="imagePath"
                  label="Image"
                  accept="image/*"
                  placeholder={<p>Drop your file here</p>}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              )}
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ApplianceWakeUpEdit;
