/* eslint-disable prefer-promise-reject-errors */
import * as R from 'ramda';
import { Auth } from 'aws-amplify';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { CognitoUser } from 'amazon-cognito-identity-js';
import config from '../config';

import fetcher from '../fetcher';

let userPermissions;

// applyBinaryFn = (* -> a) -> [*] -> a
export const applyBinaryFn = (f) => R.apply(R.binary(f));

// getError :: Object -> a
export const getError = R.prop('error');

// completeNewPassword :: String -> String -> Object
export const completeNewPassword = applyBinaryFn(
  Auth.completeNewPassword.bind(Auth),
);

// signIn :: String -> String -> Object
export const signIn = applyBinaryFn(Auth.signIn.bind(Auth));

// login :: Object -> Object
export const login = async (params) => {
  if (params instanceof CognitoUser) {
    return params;
  }
  const error = getError(params);
  if (error) {
    return Promise.reject(error);
  }
  const retValue = await R.compose(
    signIn,
    R.props(['username', 'password']),
  )(params);
  return retValue;
};

export const logout = () => Auth.signOut({ global: true });

// changePassword :: Object -> Object
export const changePassword = async (params) => {
  const retValue = await R.compose(
    completeNewPassword,
    R.props(['cognitoUser', 'password']),
  )(params);
  return retValue;
};

export const currentSession = async () => {
  try {
    const session = await Auth.currentSession();
    if (!session) {
      return Promise.reject('You need to sign in to access that page.');
    }
    return session;
  } catch (ex) {
    return Promise.reject('You need to sign in to access that page.');
  }
};

export const checkPermissions = async () => {
  try {
    const defaultPermissions = {
      profile: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    };
    let permissions = {

    };
    try {
      if (!userPermissions) {
        const user = await fetcher({
          url: '/user-permissions',
        });
        if (user && user.data) {
          permissions = user.data;
          userPermissions = permissions;
        }
      } else {
        permissions = userPermissions;
      }
    } catch (ex) {
      console.error('checkPermissions error', ex);
    }

    permissions = {
      ...permissions,
      ...defaultPermissions,
    };

    return Promise.resolve(permissions);
  } catch (ex) {
    return Promise.reject('This user has no permission to access here');
  }
};

const AuthProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    userPermissions = null;
    return login(params);
  }
  if (type === AUTH_LOGOUT) {
    userPermissions = null;
    return logout(params);
  }
  if (type === AUTH_CHECK) {
    const username = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`,
    );
    const token = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`,
    );
    return token
      ? Promise.resolve()
      : Promise.reject('You need to sign in to access that page.');
    // return currentSession();
  }
  if (type === AUTH_ERROR) {
    if (params.status === 401) {
      return Promise.reject('refresh token expired');
    }
    return Promise.resolve();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    return checkPermissions();
  }
  return Promise.reject(`Unsupported authentication method ${type}.`);
};

export default AuthProvider;
