import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import React from 'react';
import {
  BooleanInput, Edit, SimpleForm,
  TextInput, useTranslate
} from 'react-admin';
import { useStyles } from './styles';
import COUNTRIES from '../../data/countries.json';
import CustomToolbar from '../../components/CustomToolbar';

const styles = {
  hidden: { display: 'none' },
  fullLine: { width: '100%' },
  flex: { display: 'flex' },
};

const MultiAccountEdit = ({ permissions = {}, ...props }) => {
  const styles = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['multi-account']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.multi-account.name'),
      url: 'multi-account',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  console.log('id', id);
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ContainerFieldsList>
            <CustomSelectArrayInput
                label="Country"
                source="country"
                choices={COUNTRIES}
                disabled={isEditDisabled}
              />
            <TextInput
              initialValue={''}
              label="Restricted Users (Use | separator)"
              source="restrictedUsers"
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <ContainerFieldsList>
            {/*Country code*/}
            
              <BooleanInput
            initialValue={false}
            label="Group Experience"
            source="groupExperience"
            disabled={isEditDisabled}
          />
          <BooleanInput
            initialValue={false}
            label="Invitation"
            source="invitation"
            disabled={isEditDisabled}
          />
          </ContainerFieldsList>
          
        </SimpleForm>
      </Edit>
    </>
  );
};

export default MultiAccountEdit;
