import React from 'react';
import { SaveButton, Toolbar, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = ({ permissions = {}, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton disabled={props.isEditDisabled} />
    {(permissions[props.resource] && permissions[props.resource].delete && !props.isEditDisabled) && <DeleteButton undoable />}
  </Toolbar>
);

export default CustomToolbar;
