export const brands = [
  { id: 'artherm', name: 'AR-Therm' },
  { id: 'baumatic', name: 'BAUMATIC' },
  { id: 'candy', name: 'CANDY' },
  { id: 'haier', name: 'HAIER' },
  { id: 'hOn', name: 'HON' },
  { id: 'hoover', name: 'HOOVER' },
  { id: 'iberna', name: 'IBERNA' },
  { id: 'lamona', name: 'LAMONA' },
  { id: 'otsein', name: 'OTSEIN' },
  { id: 'rosieres', name: 'ROSIERES' },
  { id: 'zerowatt', name: 'ZEROWATT' },
];
