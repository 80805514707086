import React from 'react';
import {
  Create, SimpleForm, TextInput, required, useTranslate
} from 'react-admin';
import LanguageIcon from '@material-ui/icons/Language';
import { ContainerFieldsList, CustomBreadcrumbs } from 'candy-commons-components';


const LanguageCreate = props => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.language.name'),
      url: 'language',
      icon:<LanguageIcon/>
    }, {
      text: translation('resources.language.create.name'),
      url: ''
    }
  ];
 return ( 
  <>
    <CustomBreadcrumbs data={breadcrumbsData}  />
    <Create {...props}>
      <SimpleForm>
        <ContainerFieldsList>
          <TextInput source="langName" validate={required()} />
          <TextInput source="code" validate={required()} />
        </ContainerFieldsList>
      </SimpleForm>
    </Create>
  </>
  )
};

export default LanguageCreate;
