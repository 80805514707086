import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useTranslate
} from 'react-admin';
import { useForm } from 'react-final-form';
import applianceType from '../../data/applianceType.json';
import { brands } from '../maintenanceInstructions/data';

const AssistanceCreateSaveButton = ({
  handleSubmitWithRedirect,
  hasSecondaryCountryCode,
  ...otherProps
}) => {
  const form = useForm();

  const handleClick = () => {
    if (form && !hasSecondaryCountryCode) {
      form.change('secondaryCountryCode', null);
    }

    handleSubmitWithRedirect('list');
  };

  return <SaveButton {...otherProps} handleSubmitWithRedirect={handleClick} />;
};

const AssistanceCreateToolbar = ({
  hasSecondaryCountryCode,
  ...otherProps
}) => (
  <Toolbar {...otherProps}>
    <AssistanceCreateSaveButton
      hasSecondaryCountryCode={hasSecondaryCountryCode}
    />
  </Toolbar>
);

const styles = {
  hidden: { display: 'none' },
  fullLine: { width: '100%' },
  flex: { display: 'flex' },
};

const AssistanceCreate = props => {
  const [switchValue, setSwitchValue] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const translation = useTranslate();
  const handleChange = event => {
    const value = event && event.target && event.target.value;
    setSwitchValue(!(value === 'true'));
  };

  const breadcrumbsData = [
    {
      text: translation('resources.assistance.name'),
      url: 'assistance',
      icon: <AssistantIcon />,
    },
    {
      text: translation('resources.assistance.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm
          redirect="list"
          toolbar={
            <AssistanceCreateToolbar hasSecondaryCountryCode={switchValue} />
          }
        >
          <ContainerFieldsList>
            <div style={styles.fullLine}>
              {/*Now we choose if using multi or single assistance*/}
              <BooleanInput
                label="Multiple Records"
                source="isMultipleRecord"
                onClick={e => setIsMulti(e.target.checked)}
              />
            </div>

            {/*Conuntry code*/}
            <TextInput source="countryCode" />

            {/*Brand*/}
            <CustomSelectInput source="brand" allowEmpty choices={brands} />

            {/*Appliance type*/}
            <CustomSelectInput source="applianceType" choices={applianceType} />

            {/*Group for single record input type*/}
            <>
              {/*Country or zone or region or language declination*/}
              <TextInput
                source="country"
                style={!isMulti ? {} : styles.hidden}
              />
              <TextInput source="phone" style={!isMulti ? {} : styles.hidden} />
              <TextInput source="link" style={!isMulti ? {} : styles.hidden} />
              <FormControlLabel
                control={
                  <Switch
                    value={switchValue}
                    color="primary"
                    onChange={e => handleChange(e)}
                  />
                }
                label={translation(
                  'resources.assistance.fields.secondaryCountryCode',
                )}
                style={!isMulti ? {} : styles.hidden}
              />
              <TextInput
                source="secondaryCountryCode"
                style={!isMulti && switchValue ? {} : styles.hidden}
              />
            </>
          </ContainerFieldsList>

          {/*Multi record enabled*/}
          <ArrayInput
            source="multipleRecords"
            style={isMulti ? {} : styles.hidden}
          >
            <SimpleFormIterator>
              {/*Country or zone or region or language declination*/}
              <TextInput source="country" />
              <TextInput source="phone" />
              <TextInput source="link" />
              <TextInput source="description" />
            </SimpleFormIterator>
          </ArrayInput>

          <div style={styles.fullLine}>
            {/*Now we choose if using multi or single assistance*/}
            <BooleanInput
              label="Washpass"
              source="washpass"
              // onClick={e => setIsMulti(e.target.checked)}
            />
          </div>
        </SimpleForm>
      </Create>
    </>
  );
};

export default AssistanceCreate;
