export const showListData = [
  {
    label: "resources.guided-washing-cluster.fields.code",
    type: "textField",
    source: "code"
  },
  {
    label: "resources.guided-washing-cluster.fields.clusterType",
    type: "textField",
    source: "clusterType"
  },
  {
    label: "resources.guided-washing-cluster.fields.active",
    type: "booleanField",
    source: "active"
  }
];
