import React from 'react';
import WidgetsIcon from '@material-ui/icons/Widgets';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import {
  Create,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';
import StatusTypes from './constants/StatusType';
import WidgetTypes from './constants/WidgetType';

const breadcrumbsData = [
  {
    text: 'Widget',
    url: '',
    icon: <WidgetsIcon />,
  },
  {
    text: 'Create',
    url: '',
  },
];

const WidgetCreate = props => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <Create {...props}>
      <SimpleForm>
        <ContainerFieldsList>
          <CustomSelectInput
            allowEmpty
            source="type"
            choices={WidgetTypes}
            validate={[required()]}
          />
          <TextInput source="name" label="Name" />
          <TextInput source="description" label="Description" />
          <CustomSelectInput
            source="active"
            choices={StatusTypes}
            validate={[required()]}
          />
        </ContainerFieldsList>
      </SimpleForm>
    </Create>
  </>
);

export default WidgetCreate;
