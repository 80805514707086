import AssistantIcon from '@material-ui/icons/Assistant';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const EligibleAppliancesList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.eligible-appliances.name'),
      url: '',
      icon: <AssistantIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        pagination={<DynamoPagination resource="eligible-appliances" />}
        // filters={<AssistanceFilter />}
        bulkActionButtons={
          permissions &&
          permissions['eligible-appliances'] &&
          permissions['eligible-appliances'].delete
        }
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="id" />
          <TextField source="countries" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};
export default EligibleAppliancesList;
