import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate
} from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import series from '../../data/series.json';
import supportGuideHelpType from '../../data/supportGuideType.json';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const SupportGuideHelpCreate = props => {
  const [tdChoices, setTdChoices] = useState(false);
  const classes = useStyles();
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.support-guide-help.name'),
      url: 'support-guide-help',
      icon: <SettingsIcon />,
    },
    {
      text: transaltion('resources.support-guide-help.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={[{ id: 'GENERIC', name: 'GENERIC' }, ...applianceType]}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
            />
            <CustomSelectInput allowEmpty source="brand" choices={brand} />
            <CustomSelectInput allowEmpty source="series" choices={series} />
            <TextInput source="seriesVersion" />
            <TextInput source="title" />
            <TextInput source="description" />
            <CustomSelectInput
              source="supportGuideHelpType"
              choices={supportGuideHelpType}
              validate={required()}
              onChange={event => {}}
            />
          </ContainerFieldsList>

          <hr className={classes.width} />
          <ArrayInput source="steps">
            <SimpleFormIterator>
              <TextInput source="title" />
              <ArrayInput source="descriptions">
                <SimpleFormIterator>
                  <TextInput source="description" />
                </SimpleFormIterator>
              </ArrayInput>
              <ImageInput
                source="imagePath"
                label="Image"
                accept="image/*"
                placeholder={
                  <p>
                    {transaltion('resources.applianceWakeUp.fields.input_file')}
                  </p>
                }
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default SupportGuideHelpCreate;
