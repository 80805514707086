import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  FieldsGenerator,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  NumberField,
  TextField,
  useTranslate,
  TextInput,
  NumberInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import CustomToolbar from '../../components/CustomToolbar';

const AppBundleUpdateEdit = ({ permissions = {}, ...props }) => {
  const translation = useTranslate();
  const { id } = props;
  const fields = id.split('#');
  const breadcrumbsData = [
    {
      text: translation('resources.app-bundle-update.name'),
      url: 'app-bundle-update',
      icon: '',
    },
    {
      text: `Country: ${fields[1]} - Version: ${fields[2]}`,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
          <ContainerFieldsList>
            <Typography variant="h6" component="h6">
              Version
            </Typography>
            <TextField source="version" label={'Version'} />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <Typography variant="h6" component="h6">
              Country
            </Typography>
            <TextField source="country" />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <NumberInput source="maxDownloads" label={'Max Downloads'} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default AppBundleUpdateEdit;
