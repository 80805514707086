export const sectionChoices = [
  // DISABLE
  { id: 'enrollment', name: 'Disable Enrollment' },
  { id: 'demo', name: 'Disable Demo' },
  { id: 'chatbot', name: 'Disable Chatbot' },
  { id: 'inventory', name: 'Disable Label Inventory' },
  { id: 'inventory_wine_scan', name: 'Disable Inventory Wine Scan' },
  { id: 'wine_food_pairings', name: 'Disable Wine Food Pairings' },
  { id: 'guided_washing', name: 'Disable Guided Washing' },
  { id: 'snap_wash', name: 'Disable Snap & Wash' },
  { id: 'snap_wash_dw', name: 'Disable Snap & Wash Dishwasher' },
  { id: 'hints', name: 'Disable Hints & Tips' },
  { id: 'extra_cycles_disabled', name: 'Disable Extra Cycles' },
  { id: 'advanced_drink_assistant', name: 'Disable Advanced Drink Assistant' },
  { id: 'food_locator', name: 'Disable Food Locator' },
  { id: 'proactive_temperature', name: 'Disable Proactive Temperature All' },
  {
    id: 'proactive_temperature_shop',
    name: 'Disable Proactive Temperature Shop',
  },
  {
    id: 'proactive_temperature_weather',
    name: 'Disable Proactive Temperature Weather',
  },
  {
    id: 'proactive_temperature_habits',
    name: 'Disable Proactive Temperature Habits',
  },
  { id: 'inspire_me', name: 'Disable Inspire Me' },
  { id: 'shopping_list', name: 'Disable Shopping List' },
  { id: 'recipes', name: 'Disable Recipes' },
  { id: 'smart_drink_assistant', name: 'Disable Smart Drink Assistant' },
  { id: 'my_profile_temperature', name: 'Disable My Profile Temperature' },
  { id: 'my_inventory', name: 'Disable My Inventory' },
  { id: 'smart_check_up', name: 'Disable Smart Check Up' },
  { id: 'check_up', name: 'Disable Check Up' },
  { id: 'inside_view', name: 'Disable Inside View' },
  { id: 'door_opening_statistics', name: 'Disable Door Opening Statistics' },
  { id: 'allergic_profile', name: 'Disable Allergic Profile' },
  {
    id: 'gps_end_of_cycle_notification',
    name: 'Disable Geolocalised end-of-cycle notification',
  },
  { id: 'assisted_recipes', name: 'Disable Assisted Recipes' },
  { id: 'dw_statistics', name: 'Disable DW Statistics' },
  {
    id: 'dw_statistics_instant_consumption',
    name: 'Disable DW Statistics - Instant Consumption',
  },
  {
    id: 'dw_statistics_auto_cycle_efficiency',
    name: 'Disable DW Statistics - Auto Cycle Efficiency',
  },
  {
    id: 'dw_statistics_water_efficiency',
    name: 'Disable DW Statistics - Water Efficiency',
  },
  {
    id: 'dw_statistics_temperature_usage',
    name: 'Disable DW Statistics - Temperature Usage',
  },
  {
    id: 'dw_statistics_temperatures_trend',
    name: 'Disable DW Statistics - Temperatures Trend',
  },
  {
    id: 'dw_statistics_programs_counter',
    name: 'Disable DW Statistics - Programs Counter',
  },
  {
    id: 'dw_statistics_most_used_programs',
    name: 'Disable DW Statistics - Most Used Programs',
  },
  { id: 'dish_placer', name: 'Disable Dish Placer' },
  { id: 'snap_cook', name: 'Disable Snap & Cook' },
  // ENABLE
  { id: 'double_pairing_hidden', name: 'Enable single pairing' },
  { id: 'epp_enabled', name: 'Enable EPP' },
  { id: 'quickPair_enabled', name: 'Enable Quick pair' },
  { id: 'enroll_under_norms', name: 'Enrollment under norms' },
  { id: 'snap_and_dry', name: 'Snap & Dry' },
  { id: 'my_zone_pro_enabled', name: 'Enable My Zone Pro' },
  { id: 'my_zone_enabled', name: 'Enable My Zone' },
  { id: 'visual_my_zone_pro_enabled', name: 'Enable Visual My Zone Pro' },
  { id: 'visual_my_zone_enabled', name: 'Enable Visual My Zone' },
  { id: 'tdCrossSell', name: 'Enable Purchase Proposal TD' },
  { id: 'visual_tricks', name: 'Visual Tricks' },
  { id: 'smart_energy', name: 'Enable Smart Energy' },
  { id: 'quick_set', name: 'Enable Quick Set' },
  { id: 'quick_set_help', name: 'Enable Quick Set Help' },
  { id: 'energy_and_sustainability', name: 'Energy & Sustainability' },
  {
    id: 'energy_and_sustainability_holiday_mode',
    name: 'Energy & Sustainability - Holiday Mode',
  },
  {
    id: 'energy_and_sustainability_door_openings',
    name: 'Energy & Sustainability - Door Openings',
  },
  {
    id: 'energy_and_sustainability_tips_hints',
    name: 'Energy & Sustainability - Tips & Hints',
  },
  // COOL-3935, COOL-3933 Fridge Blackout Alert
  { id: 'fridge_blackout_alert', name: 'Enable Fridge Blackout Alert' },
  { id: 'enabled_local_OTA', name: 'Enable Local OTA' },
  { id: 'enable_new_program_detail', name: 'New Program Detail' },
];
