import React from 'react';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {
  CustomBreadcrumbs, CustomFilter, CustomList,
  CustomSelectInput
} from 'candy-commons-components';
import {
  BooleanField, DateField,
  TextField, TextInput, useTranslate
} from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDataGrid from '../../components/organisms/customDatagrid';

const UserFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.user.fields.search" source="q" alwaysOn />
    <CustomSelectInput
      source="field"
      choices={[
        { id: 'username', name: 'Username' },
        { id: 'email', name: 'Email' },
        { id: 'cognito:user_status', name: 'User status' },
      ]}
      alwaysOn
    />
  </CustomFilter>
);

const UserList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.user.name'),
      url: '',
      icon: <SupervisorAccountIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        {...props}
        perPage={25}
        pagination={<DynamoPagination resource="user" />}
        filters={<UserFilter />}
        bulkActionButtons={permissions && permissions.user && permissions.user.delete}
      >
        <CustomDataGrid rowClick="edit" selectable>
          <TextField source="Username" />
          <TextField source="email" />
          <DateField source="UserCreateDate" />
          <DateField source="UserLastModifiedDate" />
          <BooleanField source="Enabled" />
          <TextField source="UserStatus" />
        </CustomDataGrid>
      </CustomList>
    </>
  );
};

export default UserList;
