import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import {
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const IngredientRefEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const { id } = props;
  const isEditDisabled = !permissions?.['ingredient-ref']?.update;
  const breadcrumbsData = [
    {
      text: 'Ingredient [REF]',
      url: 'ingredient-ref',
      icon: <ViewComfyIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
      <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ContainerFieldsList>
          <TextInput source="name" label="Name (translation key)" disabled={isEditDisabled} />
            <ReferenceInput source="categoryId" label="Category" reference="food-category" disabled={isEditDisabled}>
              <CustomSelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="fridgeExpiryTime" label="Fridge Expires In (days)" disabled={isEditDisabled} />
            <TextInput source="freezerExpiryTime" label="Freezer Expires In (days)" disabled={isEditDisabled} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default IngredientRefEdit;
