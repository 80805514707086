import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  ImageField,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import connectivityOptions from '../../data/connectivityOptions.json';
import platformType from '../../data/platformType.json';
import LOADING_TYPE from '../../data/loadingType.json';
import { brands } from '../maintenanceInstructions/data';
import { ServiceAppSyncValues } from './commonComponents';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const TroubleshootingCreate = props => {
  const [tdChoices, setTdChoices] = useState(false);
  const classes = useStyles();
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.troubleshooting.name'),
      url: 'troubleshooting',
      icon: <ErrorIcon />,
    },
    {
      text: transaltion('resources.troubleshooting.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <TextInput
              source="errorCode"
              validate={required()}
              label="Technical Error Code"
            />
            <CustomSelectInput source="brand" allowEmpty choices={brands} />
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
            />
            <TextInput source="applianceModel" />
            <CustomSelectInput
              allowEmpty
              source="loadingType"
              choices={LOADING_TYPE}
            />
            <TextInput source="title" validate={required()} />
            <CustomSelectInput
              source="type"
              choices={[
                { id: 'monoSolution', name: 'MONO-SOLUTION' },
                { id: 'multiSolution', name: 'MULTI-SOLUTION' },
              ]}
              validate={required()}
            />
            <CustomSelectInput
              source="category"
              choices={[
                { id: 'error', name: 'ERROR' },
                { id: 'warning', name: 'WARNING' },
                { id: 'none', name: 'NONE' },
              ]}
            />
            <CustomSelectInput
              allowEmpty
              source="platform"
              choices={platformType}
            />
            <TextInput source="userErrorCode" label="User Error Code" />
            <CustomSelectArrayInput
              style={{
                opacity: tdChoices ? 1 : 0,
                height: tdChoices ? undefined : 0,
              }}
              disabled={!tdChoices}
              source="tankType"
              allowEmpty
              choices={[
                { id: 'B', name: 'Bottom' },
                { id: 'D', name: 'Door' },
                { id: 'NA', name: 'Not available' },
              ]}
            />
            <CustomSelectArrayInput
              style={{
                opacity: tdChoices ? 1 : 0,
                height: tdChoices ? undefined : 0,
              }}
              disabled={!tdChoices}
              source="dryingTechnology"
              allowEmpty
              choices={[
                { id: 'C', name: 'Condenser' },
                { id: 'HP', name: 'Heat pump' },
                { id: 'HY', name: 'Hybrid' },
                { id: 'V', name: 'Vented' },
              ]}
            />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <CustomSelectInput
              source="connectivity"
              allowEmpty
              choices={connectivityOptions}
            />
            <BooleanInput source="serviceSync" label="Active Service Sync" />
            <BooleanInput source="serviceAppSync" label="Service App Sync" />
            <ServiceAppSyncValues />
          </ContainerFieldsList>
          <hr className={classes.width} />
          <ArrayInput source="steps">
            <SimpleFormIterator>
              <TextInput source="title" validate={required()} />
              <TextInput source="description" />
              <ImageInput
                source="imagePath"
                accept="image/*"
                placeholder={
                  <p>
                    {transaltion('resources.troubleshooting.fields.input_file')}
                  </p>
                }
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default TroubleshootingCreate;
