import React from 'react';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import {
  Create,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';

const UserCreate = ({ permissions = {}, ...props }) => {
  const translate = useTranslate();
  const breadcrumbsData = [
    {
      text: translate('resources.user.name'),
      url: 'user',
    },
    {
      text: translate('resources.user.create.name'),
      url: '',
    },
  ];
  return (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <Create {...props}>
      <SimpleForm>
        <ContainerFieldsList>
          <TextInput source="username" />
          <TextInput source="email" />
          {/* <PasswordInput source="password" /> */}

          </ContainerFieldsList>
          {(permissions.role && permissions.role.read) && (
            <ReferenceArrayInput source="roles" reference="role">
              <CustomSelectArrayInput
                optionText="name"
                validate={[required()]}
              />
            </ReferenceArrayInput>
          )}
        </SimpleForm>
      </Create>
    </>
  );
};

export default UserCreate;
