const statusName = [{
  id: 'veryGood',
  name: 'Very Good',
}, {
  id: 'good',
  name: 'Good',
}, {
  id: 'notGood',
  name: 'Not Good',
}, {
  id: 'bad',
  name: 'Bad',
}];

const categoryName = [{
  id: 'fridge',
  name: 'fridge',
}, {
  id: 'freezer',
  name: 'freezer',
}];

export {
  statusName,
  categoryName,
};
