import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useMemo } from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  required,
  useGetList,
  useTranslate
} from 'react-admin';

const GuidedWashingChoicesCreate = (props) => {
  const tranlation = useTranslate();
  const breadcrumbsData = [
    {
      text: tranlation('resources.guided-washing-choices.name_breadcrumbs'),
      url: 'guided-washing-choices',
      icon: <SwapHorizIcon />,
    },
    {
      text: tranlation('resources.guided-washing-choices.create.name'),
      url: '',
    },
  ];
  const { ids = [], data } = useGetList('guided-washing-cluster', {}, {});
  const types = useMemo(
    () =>
      ids
        .filter((x) => data[x].category === 'FABRICS')
        .map((x) => ({ id: data[x].clusterType, name: data[x].clusterType })),
    [ids, data],
  );
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectArrayInput
              source="selection"
              choices={types}
              validate={required()}
            />
            <CustomSelectInput
              allowEmpty
              source="choiceResult"
              choices={types}
              validate={required()}
            />
            <BooleanInput initialValue={false} source="recommended" />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default GuidedWashingChoicesCreate;
