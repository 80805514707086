import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import {
  ArrayField, Datagrid, ReferenceField, Show, SimpleForm, TextField, TopToolbar, useTranslate, useDataProvider
} from 'react-admin';
import TranslateIcon from '@material-ui/icons/Translate';
import { CustomBreadcrumbs } from 'candy-commons-components';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import TranslationModalButton from './translationModalButton';
import TranslationDeleteButton from './translationDeleteButton';

const TranslationShow = ({ permissions, ...props }) => {
  const { id } = props;
  const isEditDisabled = !permissions?.translation?.update;
  const classes = useStyles();
  const translation = useTranslate();

  const dataProvider = useDataProvider();
  const [stateIds, setStateIds] = useState([]);
  const [stateData, setStateData] = useState();

  useEffect(() => {
    let isMounted = true;
  
    dataProvider.getList('language', {
      filter: {},
      pagination: {},
      sort: {},
    }).then(data => {
      if (isMounted) {
        setStateIds(data?.data.map(item => item.id));
        setStateData(data?.data.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}));
      }
    });
  
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);
  
  
  const breadcrumbsData = [
    {
      text: translation('resources.translation.name'),
      url: 'translation',
      icon: <TranslateIcon />,
    }, {
      text: id,
      url: '',
    },
  ];
  const ShowActions = ({ isEditDisabled }) => (
    <TopToolbar>
      {(permissions && permissions.translation && permissions.translation.create && !isEditDisabled && (!permissions.translation.restrictLanguages || permissions.translation.en)) && (
        <Button startIcon={<AddIcon />} color="primary" href="#/translation/create">{translation('resources.translation.action.create_translation')}</Button>
      )}
    </TopToolbar>
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Show {...props} actions={<ShowActions isEditDisabled />}>
        <SimpleForm toolbar={null}>
          <TextField source="code" />
          {(permissions && permissions.translation && permissions.translation.create && !isEditDisabled) && (
            <TranslationModalButton permissions={permissions} ids={stateIds} data={stateData} id={id} />
          )}
          <ArrayField
            source="languages"
            addLabel={false}
            className={classes.arrayFieldContainer}
          >
            <Datagrid classes={{ headerRow: classes.headerRow }}>
              {(permissions && permissions.language && permissions.language.read) ? (
                <ReferenceField source="language" reference="language" className={classes.language}>
                  <TextField source="langName" />
                </ReferenceField>
              ) : (
                <div className={classes.language} />
              )}
              <TextField addLabel={false} source="translation" />
              {(permissions && permissions.translation && permissions.translation.update) && (
                <TranslationModalButton permissions={permissions} ids={stateIds} data={stateData} id={id} edit />
              )}
              {(permissions && permissions.translation && permissions.translation.delete && !isEditDisabled) && (
                <TranslationDeleteButton id={id} />
              )}
            </Datagrid>
          </ArrayField>
        </SimpleForm>
      </Show>
    </>
  );
};

const useStyles = makeStyles({
  arrayFieldContainer: {
    width: '100%',
    '& .MuiTableBody-root': {
      '& tr': {
        display: 'flex',
        borderBottom: '1px solid rgba(224, 224, 224, 1);',
        '& td': {
          display: 'inline-grid',
          borderBottom: 'none',
        },
        '& td:nth-child(2)': {
          width: '80%',
          '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },
  },
  headerRow: {
    display: 'none',
  },
  language: {
    minWidth: 90,
  },
});

export default TranslationShow;
