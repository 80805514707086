import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { BooleanField, TextField, useTranslate } from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const MultiAccountList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.multi-account.name'),
      url: '',
      icon: <SettingsIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        pagination={<DynamoPagination resource="multi-account" />}
        // filters={<AssistanceFilter />}
        bulkActionButtons={false}
      >
        <CustomDatagrid selectable rowClick="edit">
          <BooleanField label={'Group Experience'} source="groupExperience" />
          <BooleanField label={'Invitation'} source="invitation" />
          <TextField label={'Country'} source="country" />  
          <TextField label={'Restricted Users'} source="restrictedUsers" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};
export default MultiAccountList;
