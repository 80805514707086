import {
  Collapse,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DefaultIcon from "@material-ui/icons/ViewList";
import classnames from "classnames";
import inflection from "inflection";
import PropTypes from "prop-types";
import { getResources, useTranslate } from "ra-core";
import React, { useEffect, useMemo, useState } from "react";
import { DashboardMenuItem, MenuItemLink, usePermissions } from "react-admin";
import { shallowEqual, useSelector } from "react-redux";

const translatedResourceName = (resource, translate) => {
  return translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });
};

const Menu = ({
  className,
  dense,
  hasDashboard,
  onMenuClick,
  logout,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useTheme();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  useSelector((state) => state.router.location.pathname); // used to force redraw on navigation
  const [subMenuOpened, setSubMenuOpened] = useState({});
  const menuItems = useMemo(() => {
    const menuItemsTemp = [];
    resources
      .filter((r) => r.hasList)
      .forEach((resource) => {
        if (resource.nested) {
          let menuItem = menuItemsTemp.find(
            (item) => item.nested === resource.nested
          );
          if (menuItem) {
            menuItem.resources.push(resource);
          } else {
            menuItem = {
              nested: resource.nested,
              resources: [resource],
            };
            menuItemsTemp.push(menuItem);
          }
        } else {
          menuItemsTemp.push({
            nested: null,
            resource,
          });
        }
      });

    return menuItemsTemp;
  }, [resources]);

  useEffect(() => {
    if (!open && subMenuOpened) {
      let resetSubMenuOpenedObject = {};
      Object.keys(subMenuOpened).map((key) => {
        resetSubMenuOpenedObject[key] = false;
      });
      setSubMenuOpened({ ...subMenuOpened, ...resetSubMenuOpenedObject });
    }
  }, [open]);

  useEffect(() => {
    const subMenuOpenedTemp = {};
    resources
      .filter((r) => r.hasList)
      .forEach((resource) => {
        if (resource.nested) {
          subMenuOpenedTemp[resource.nested] = false;
        }
      });
    setSubMenuOpened(subMenuOpenedTemp);
  }, [resources]);

  const handleClick = (nested) => {
    const subMenuOpenedTemp = Object.assign({}, subMenuOpened);
    subMenuOpenedTemp[nested] = !subMenuOpenedTemp[nested];
    setSubMenuOpened(subMenuOpenedTemp);
  };

  const renderMenuItems = (menuItem, index) => {
    if (menuItem.nested) {
      return (
        <div key={index}>
          <ListItem
            button
            onClick={
              handleClick ? () => handleClick(menuItem.nested) : () => {}
            }
            style={{ color: "rgba(0, 0, 0, 0.54)", height: "36px" }}
          >
            <div style={{ minWidth: 40 }}>
              <DefaultIcon />
            </div>
            <ListItemText
              primary={translate(`resources.nested-menu.${menuItem.nested}.name`)}
              primaryTypographyProps={{
                style: { fontSize: "1rem" },
                className: classes.ellipsis,
              }}
            />
            {menuItem && subMenuOpened[menuItem.nested] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={menuItem && subMenuOpened[menuItem.nested]}
            timeout="auto"
            unmountOnExit
          >
            {menuItem.resources.map((resource) => (
              <div key={resource.name} style={open ? { paddingLeft: 30 } : {}}>
                <MenuItemLink
                  to={`/${resource.name}`}
                  primaryText={translatedResourceName(resource, translate)}
                  leftIcon={
                    resource.icon ? (
                      <resource.icon style={open ? {} : { paddingLeft: 10 }} />
                    ) : (
                      <DefaultIcon style={open ? {} : { paddingLeft: 10 }} />
                    )
                  }
                  onClick={onMenuClick}
                  dense={dense}
                  sidebarIsOpen={open}
                  activeStyle={{
                    fontWeight: theme.typography.fontWeightMedium,
                    backgroundColor: theme.palette.common.linkWater,
                  }}
                />
              </div>
            ))}
          </Collapse>
        </div>
      );
    }
    const { resource } = menuItem;
    return (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={translatedResourceName(resource, translate)}
        leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        onClick={onMenuClick}
        dense={dense}
        sidebarIsOpen={open}
        activeStyle={{
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: theme.palette.common.linkWater,
        }}
      />
    );
  };

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {hasDashboard && (
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      )}
      {menuItems && menuItems.map((el, index) => renderMenuItems(el, index))}
      {isXSmall && logout}
    </div>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default Menu;
