import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import CustomDatagrid from '../../components/organisms/customDatagrid';
import DynamoPagination from '../../components/DynamoPagination';

const breadcrumbsData = [
  {
    text: 'Appliance Configuration',
    url: '',
    icon: <ViewComfyIcon />,
  },
];

const ApplianceConfigurationList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList
      perPage={25}
      pagination={<DynamoPagination resource="appliance-configuration" />}
      exporter={false}
      {...props}
      bulkActionButtons={permissions && permissions['appliance-configuration'] && permissions['appliance-configuration'].delete}
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField source="applianceType" />
        <TextField source="applianceModel" />
        <TextField source="series" />
        <TextField source="brand" />
        <TextField source="connectivity" />
        <ArrayField source="sections"><SingleFieldList><ChipField source="title" /></SingleFieldList></ArrayField>
      </CustomDatagrid>
    </CustomList>
  </>
);


export default ApplianceConfigurationList;
