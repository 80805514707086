import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { CustomBreadcrumbs, CustomList, CustomFilter } from 'candy-commons-components';
import React from 'react';
import CustomDatagrid from '../../../components/organisms/customDatagrid';
import { BooleanField, TextField, TextInput } from 'react-admin';
import DynamoPagination from '../../../components/DynamoPagination';

const FAQFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.multi-account-faq.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const breadcrumbsData = [
  {
    text: 'FAQ',
    url: '',
    icon: <LiveHelpIcon />,
  }
]

const FAQList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList 
      perPage={10} 
      exporter={false}
      {...props}
      pagination={<DynamoPagination resource="multi-account-faq" />}
      filters={<FAQFilter />}
      bulkActionButtons={
        permissions &&
        permissions['multi-account-faq'] &&
        permissions['multi-account-faq'].delete
      }
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField label={'Title'}  source='id' />
        <TextField label={'Link'} source='link' />
        <BooleanField label={'Publish'} source='publish' />
      </CustomDatagrid>
    </CustomList>
  </>
);

export default FAQList;
