import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useTranslate
} from 'react-admin';
import { useForm } from 'react-final-form';
import { brands } from '../maintenanceInstructions/data';
import COUNTRIES from '../../data/countries.json';

const MaintenanceGenericCreateSaveButton = ({
  handleSubmitWithRedirect,
  hasSecondaryCountryCode,
  ...otherProps
}) => {
  const form = useForm();

  const handleClick = () => {
    if (form && !hasSecondaryCountryCode) {
      form.change('secondaryCountryCode', null);
    }

    handleSubmitWithRedirect('list');
  };

  return <SaveButton {...otherProps} handleSubmitWithRedirect={handleClick} />;
};

const MaintenanceGenericCreateToolbar = ({
  hasSecondaryCountryCode,
  ...otherProps
}) => (
  <Toolbar {...otherProps}>
    <MaintenanceGenericCreateSaveButton
      hasSecondaryCountryCode={hasSecondaryCountryCode}
    />
  </Toolbar>
);

const MaintenanceGenericCreate = props => {
  const [switchValue, setSwitchValue] = useState(false);
  const translate = useTranslate();
  const handleChange = event => {
    const value = event && event.target && event.target.value;
    setSwitchValue(!(value === 'true'));
  };
  const breadcrumbsData = [
    {
      text: translate('resources.maintenance-generic.name'),
      url: 'maintenance-generic',
      icon: <WorkOutlineIcon />,
    },
    {
      text: translate('resources.maintenance-generic.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm
          redirect="list"
          toolbar={
            <MaintenanceGenericCreateToolbar
              hasSecondaryCountryCode={switchValue}
            />
          }
        >
          <ContainerFieldsList>
            <CustomSelectInput source="countryCode" choices={COUNTRIES} validate={required()} />
            <CustomSelectInput
              source="brand"
              allowEmpty
              choices={brands}
              validate={required()}
            />
            <TextInput source="country" validate={required()} />
            <TextInput source="link" validate={required()} />
            <FormControlLabel
              control={
                <Switch
                  value={switchValue}
                  color={'primary'}
                  onChange={e => handleChange(e)}
                />
              }
              label={translate(
                'resources.maintenance-generic.fields.secondaryCountryCode',
              )}
            />
            <TextInput
              source="secondaryCountryCode"
              style={switchValue ? {} : { display: 'none' }}
            />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default MaintenanceGenericCreate;
