import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';


const MaintenanceFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.maintenance-generic.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const MaintenanceGenericList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.maintenance-generic.name'),
      url: '',
      icon: <WorkOutlineIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        filters={<MaintenanceFilter />}
        pagination={<DynamoPagination resource="maintenance-generic" />}
        bulkActionButtons={permissions && permissions['maintenance-generic'] && permissions['maintenance-generic'].delete}
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="countryCode" />
          <TextField source="brand" />
          <TextField source="link" />
          <TextField source="country" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default MaintenanceGenericList;
