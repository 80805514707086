import React from 'react';
import { ImageInput as DefaultImageInput } from 'react-admin';
import { useNotify } from 'react-admin';

/**
 * @see CORE-1961
 */

const MAX_SIZE = 2000000; // 800kb
const LABEL = 'Image (max: 2Mb, png/jpeg, application/json, image/svg+xml)';
const FILE_TYPE = 'image/png, image/jpeg, application/json, image/svg+xml';

/**
 * Image Input Wrapper
 *
 */
const ImageInput = props => {
  const notify = useNotify();

  const onChangeHandler = file => {
    // File Not Validated
    if (file === undefined)
      notify(
        'Invalid image. It must have maximum size 2 Mb and format jpg, png, svg or json',
      );

    if (props.onChange) props.onChange(file);
  };

  return (
    <>
      <DefaultImageInput
        {...props}
        label={LABEL}
        accept={FILE_TYPE}
        maxSize={MAX_SIZE}
        onChange={onChangeHandler}
      >
        {props.children}
      </DefaultImageInput>
    </>
  );
};

export default ImageInput;
