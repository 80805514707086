import React from 'react';
import WidgetsIcon from '@material-ui/icons/Widgets';
import {
  CustomBreadcrumbs, CustomList
} from 'candy-commons-components';
import {
  TextField
} from 'react-admin';

import CustomDatagrid from '../../components/organisms/customDatagrid';

const breadcrumbsData = [
  {
    text: 'Widget',
    url: '',
    icon: <WidgetsIcon />,
  },
];

const WidgetList = ({ permissions, ...props }) => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <CustomList
      perPage={25}
      exporter={false}
      {...props}
      bulkActionButtons={permissions && permissions['widget'] && permissions['widget'].delete}
    >
      <CustomDatagrid selectable rowClick="edit">
        <TextField source="type" />
        <TextField source="name" />
        <TextField source="active" />
      </CustomDatagrid>
    </CustomList>
  </>
);

export default WidgetList;
