import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  ArrayInput,
  BooleanInput,
  Edit,
  ImageField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import APPLIANCE_TYPE from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import SERIES from '../../data/series.json';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  title: {
    color: '#014599',
  },
});
const OnboardingEdit = ({ permissions = {}, ...props }) => {
  const transaltion = useTranslate();
  const classes = useStyles();
  const [applianceDisabled, setApplianceDisabled] = useState(false);
  const [eventCodeDisabled, setEventCodeDisabled] = useState(false);
  const { id } = props;
  const breadcrumbsData = [
    {
      text: transaltion('resources.onboarding.name'),
      url: 'onboarding',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
          <h2 className={[classes.width, classes.title]}>
            Appliances onboarding
          </h2>

          <ContainerFieldsList>
            <CustomSelectInput
              allowEmpty
              source="applianceType"
              choices={APPLIANCE_TYPE}
              disabled={applianceDisabled}
              onChange={ev => setEventCodeDisabled(!!ev.target.value)}
            />
            <CustomSelectInput
              allowEmpty
              source="brand"
              choices={BRANDS}
              disabled={applianceDisabled}
              onChange={ev => setEventCodeDisabled(!!ev.target.value)}
            />
            <CustomSelectInput
              allowEmpty
              source="series"
              choices={SERIES}
              disabled={applianceDisabled}
              onChange={ev => setEventCodeDisabled(!!ev.target.value)}
            />
            <TextInput
              allowEmpty
              source="seriesVersion"
              disabled={applianceDisabled}
              onChange={ev => setEventCodeDisabled(!!ev.target.value)}
            />
          </ContainerFieldsList>
          <hr className={classes.width} />
          <h2 className={[classes.width, classes.title]}>
            Widget or feature onboarding
          </h2>

          <ContainerFieldsList>
            <TextInput
              source="eventCode"
              label="Event Code"
              disabled={eventCodeDisabled}
              onChange={ev => setApplianceDisabled(!!ev.target.value)}
            />
          </ContainerFieldsList>

          <ArrayInput source="steps">
            <SimpleFormIterator>
              <TextInput
                source="stepTitleKey"
                label="Step title translation key"
              />
              <TextInput
                source="stepSubtitleKey"
                label="Step subtitle translation ley"
              />
              <BooleanInput
                initialValue={false}
                source="isLottie"
                label="Is lottie image?"
              />
              <TextInput source="assetKey" label="Asset Key" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default OnboardingEdit;
