import React from 'react';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import {
  BooleanInput,
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useTranslate
} from 'react-admin';
import countries from '../../../data/countries.json';
import { useStyles } from '../styles';

const HowToCreate = props => {
  const translation = useTranslate();
  const classes = useStyles();

  const breadcrumbsData = [
    {
      text: translation('resources.multi-account-how-to.name'),
      url: 'multi-account-faq',
      icon: <LiveHelpIcon />,
    },
    {
      text: translation('resources.multi-account-how-to.create.name'),
      url: '',
    },
  ];

  const CustomToolbar = props => {
    return (
      <Toolbar {...props}>
        <SaveButton
          redirect="list"
        />
      </Toolbar>
    );
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list" className={classes.fullWidth} toolbar={<CustomToolbar />}>
            <BooleanInput
              initialValue={false}
              source="publish"
              label="Publish"
              className={classes.fullWidth}
            />
            <ContainerFieldsList>
              <TextInput source="title" label="Title" validate={required()} />
              <TextInput source="tag" label="Tag" validate={required()} />
              <CustomSelectArrayInput
                label="Countries"
                source="countries"
                choices={countries}
                parse={x => (x.includes('all') ? ['all'] : x)}
              />
            </ContainerFieldsList>
            <ContainerFieldsList>
              <TextInput source="liferayContentId" label="Liferay Content Id" validate={required()} />
              <TextInput source="liferayStructureId" label="Liferay Structure Id" validate={required()} />
            </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default HowToCreate;
