import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate
} from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import applicationType from '../../data/applicationType.json';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const IamConfigurationCreate = props => {
  const [tdChoices, setTdChoices] = useState(false);
  const classes = useStyles();
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.iam-configuration.name'),
      url: 'esources.iam-configuration',
      icon: <SettingsIcon />,
    },
    {
      text: transaltion('resources.iam-configuration.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectInput
              label="App Type"
              source="appType"
              choices={applicationType}
              validate={required()}
            />
            <TextInput
              source="minimumAppVersion"
              label="Min AppVersion"
              validate={required()}
            />
            <TextInput
              source="maximumAppVersion"
              label="max AppVersion"
              validate={required()}
            />
          </ContainerFieldsList>

          <hr className={classes.width} />
          <ArrayInput source="variables">
            <SimpleFormIterator inline>
              <TextInput disabled source="varName" helperText={false} />
              <TextInput source="varValue" helperText={false} />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default IamConfigurationCreate;
