export const showListData = [
  {
    label: "resources.appliance-info.fields.category",
    type: "textField",
    source: "category"
  },
  {
    label: "resources.appliance-info.fields.applianceType",
    type: "textField",
    source: "applianceType"
  }
];
