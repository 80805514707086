import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  FieldsGenerator,
  CustomBreadcrumbs,
} from 'candy-commons-components';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, cloneElement, useState } from 'react';
import {
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  useEditController,
  FormDataConsumer,
  useQueryWithStore,
} from 'react-admin';
import _ from 'lodash';
import CustomToolbar from '../../components/CustomToolbar';
import { adminPortalSections } from './mapping';

const rolesSection = require('./rolesSection.json');

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  formIterator: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '8px',
    paddingBottom: '0px',
  },
});

const RoleEdit = ({ record, permissions, languages, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions?.role?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: 'Role',
      url: 'role',
      icon: <PersonAddIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'name',
          type: 'textField',
          source: 'name',
        },
      ]}
      record={record}
      {...props}
    />
  );

  const MyEdit = props => {
    const {
      basePath, // deduced from the location, useful for action buttons
      defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
      loaded, // boolean that is false until the record is available
      loading, // boolean that is true on mount, and false once the record was fetched
      record, // record fetched via dataProvider.getOne() based on the id from the location
      redirect, // the default redirection route. Defaults to 'list'
      resource, // the resource name, deduced from the location. e.g. 'posts'
      save, // the update callback, to be passed to the underlying form as submit handler
      saving, // boolean that becomes true when the dataProvider is called to update the record
      version, // integer used by the refresh feature
    } = useEditController(props);

    if (!loaded) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={100} thickness={1} />
        </div>
      );
    }

    const newSections = rolesSection.filter(
      x => !record.permissions.map(y => y.section).includes(x),
    );

    const permissions = [
      ...(record ? record.permissions : []),
      ...newSections.map(x => ({
        section: x,
        create: false,
        read: false,
        update: false,
        delete: false,
      })),
    ].map(el => ({
      ...el,
      adminPortal: adminPortalSections.indexOf(el.section) >= 0 ? true : false,
    }));

    return (
      <div>
        {cloneElement(props.children, {
          basePath,
          record: {
            'all-create': false,
            'all-read': false,
            'all-update': false,
            'all-delete': false,
            ...record,
            permissions: _.orderBy(
              permissions,
              [c => c.adminPortal, c => c.section],
              ['asc', 'asc'],
            ),
            ...props.currentRecord,
          },
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    );
  };

  const [currentRecord, setCurrentRecord] = useState({
    'all-create': false,
    'all-read': false,
    'all-update': false,
    'all-delete': false,
  });

  const updateAllRecords = ({ formData, action }) => {
    setCurrentRecord({
      permissions: formData.permissions.map(x => ({
        ...x,
        [action]: !currentRecord[`all-${action}`],
      })),
      'all-create': currentRecord['all-create'],
      'all-read': currentRecord['all-read'],
      'all-update': currentRecord['all-update'],
      'all-delete': currentRecord['all-delete'],
      [`all-${action}`]: !currentRecord[`all-${action}`],
      description: formData.description,
    });
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <MyEdit currentRecord={currentRecord} {...props}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ShowList />
          <TextInput multiline source="description" disabled={isEditDisabled} />
          <hr className={classes.width} />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <div className={classes.formIterator}>
                <span style={{ width: 250, marginLeft: 50 }}>set all</span>
                <BooleanInput
                  source="all-create"
                  label="create"
                  style={styles.boolean}
                  onClick={() =>
                    updateAllRecords({ formData, action: 'create' })
                  }
                  disabled={isEditDisabled}
                />
                <BooleanInput
                  source="all-read"
                  label="read"
                  style={styles.boolean}
                  onClick={() => updateAllRecords({ formData, action: 'read' })}
                  disabled={isEditDisabled}
                />
                <BooleanInput
                  source="all-update"
                  label="update"
                  style={styles.boolean}
                  onClick={() =>
                    updateAllRecords({ formData, action: 'update' })
                  }
                  disabled={isEditDisabled}
                />
                <BooleanInput
                  source="all-delete"
                  label="delete"
                  style={styles.boolean}
                  onClick={() =>
                    updateAllRecords({ formData, action: 'delete' })
                  }
                  disabled={isEditDisabled}
                />
              </div>
            )}
          </FormDataConsumer>
          <ArrayInput addLabel={false} label="" source="permissions">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => (
                  <div>
                    <div className={classes.formIterator}>
                      <span style={{ width: 250 }}>
                        {scopedFormData.section}
                      </span>
                      <BooleanInput
                        style={styles.boolean}
                        source={getSource('create')}
                        disabled={isEditDisabled}
                      />
                      <BooleanInput
                        style={styles.boolean}
                        source={getSource('read')}
                        disabled={isEditDisabled}
                      />
                      <BooleanInput
                        style={styles.boolean}
                        source={getSource('update')}
                        disabled={isEditDisabled}
                      />
                      <BooleanInput
                        style={styles.boolean}
                        source={getSource('delete')}
                        disabled={isEditDisabled}
                      />
                      {scopedFormData.section === 'appliance' && (
                        <BooleanInput
                          style={styles.boolean}
                          source={getSource('disenroll')}
                          disabled={isEditDisabled}
                        />
                      )}
                      {scopedFormData.section === 'translation' && (
                        <BooleanInput
                          style={styles.boolean}
                          label="restrict languages"
                          source={getSource('restrictLanguages')}
                          disabled={isEditDisabled}
                        />
                      )}
                      {scopedFormData.section === 'asset' && (
                        <BooleanInput
                          style={styles.boolean}
                          label="publish asset"
                          source={getSource('asset-publish')}
                          disabled={isEditDisabled}
                        />
                      )}
                      {scopedFormData.section === 'mock-demo' && (
                        <BooleanInput
                          style={styles.boolean}
                          label="publish mocks"
                          source={getSource('mock-publish')}
                          disabled={isEditDisabled}
                        />
                      )}
                    </div>
                    <div className={classes.formIterator}>
                      {scopedFormData.restrictLanguages &&
                        permissions &&
                        permissions.language &&
                        permissions.language.read &&
                        languages &&
                        languages.length &&
                        languages.map(lang => (
                          <BooleanInput
                            style={styles.boolean}
                            source={getSource(lang.id)}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </MyEdit>
    </>
  );
};

const mapStateToProps = state => ({
  languages: state.admin.resources.language.data,
});

const styles = {
  boolean: {
    flexDirection: 'row',
    marginRight: 20,
  },
};

export default connect(mapStateToProps)(RoleEdit);
