import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import {
  BooleanInput,
  DeleteButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import applianceType from '../../data/applianceType.json';
import series from '../../data/series.json';
import { brands } from '../maintenanceInstructions/data';
import COUNTRIES from '../../data/countries.json';

const MaintenanceEditSaveButton = ({ handleSubmitWithRedirect, isEditDisabled, ...props }) => {
  const form = useForm();
  const formState = useFormState();
  const handleClick = () => {
    if (formState.values.generic) form.change('link', null);

    handleSubmitWithRedirect('list');
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={isEditDisabled}  />;
};

const MaintenanceEditToolbar = ({ permissions = {}, resource, isEditDisabled, ...props }) => (
  <Toolbar {...props}>
    <MaintenanceEditSaveButton isEditDisabled={isEditDisabled} />
    <span style={{ flex: '1' }} />
    {permissions[resource] && permissions[resource].delete && (
      <DeleteButton undoable />
    )}
  </Toolbar>
);

const MaintenanceEdit = ({ permissions, ...props }) => {
  const classes = useStyles();
  const { id } = props;
  const translation = useTranslate();
  const isEditDisabled = !permissions?.maintenance?.update;
  const breadcrumbsData = [
    {
      text: translation('resources.maintenance.name'),
      url: 'maintenance',
      icon: <WorkIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} {...props}>
        <SimpleForm
          toolbar={<MaintenanceEditToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}
        >
          <ContainerFieldsList>
            <CustomSelectInput source="country" choices={COUNTRIES} disabled={isEditDisabled} />
            <CustomSelectInput
              allowEmpty
              source="productLine"
              choices={applianceType}
              disabled={isEditDisabled}
            />
            <CustomSelectInput source="brand" allowEmpty choices={brands} disabled={isEditDisabled} />
            <CustomSelectInput allowEmpty source="interfaceType" choices={series} disabled={isEditDisabled} />
            <BooleanInput source="generic" disabled={isEditDisabled} />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                !formData.generic && <TextInput source="link" {...rest} disabled={isEditDisabled} />
              }
            </FormDataConsumer>
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({
  reverseButtonSave: {
    '& .MuiToolbar-root': {
      flexDirection: 'row-reverse',
    },
  },
});

export default MaintenanceEdit;
