import countries from '../../../data/countries';
import applianceType from '../../../data/applianceType';
import brand from '../../../data/brand';
export const FORM_TYPE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const INPUT_VARIANT = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  MULTIPLE_CHOICES: 'MULTIPLE_CHOICES',
  SINGLE_CHOICES: 'SINGLE_CHOICES',
  POSITION: 'POSITION',
};

export const IMPRESSIONS_LOGIC = {
  NO_LIMIT: 'NO_LIMIT',
  COUNT: 'COUNT',
};

export const PROMO = {
  SPACE: 'PROMO_SPACE',
  CAMPAIGNS: 'PROMO_CAMPAIGNS',
};

export const CONNECTIVE = {
  and: {
    id: 'AND',
    name: 'Match all of the following',
  },

  or: {
    id: 'OR',
    name: 'Match any of the following',
  },
};

export const RULES = {
  isEqualTo: {
    id: 'EQ',
    name: 'Is equal to',
  },
  isNotEqualTo: {
    id: 'NEQ',
    name: 'Is not equal to',
  },
  isGratherThan: {
    id: 'GTE',
    name: 'Is greater than',
  },
  isLessThan: {
    id: 'LTE',
    name: 'Is less than',
  },
  isIn: {
    id: 'IN',
    name: 'Is in',
  },
  isNotIn: {
    id: 'NIN',
    name: 'Is not in',
  },
  isInside: {
    id: 'INSIDE',
    name: 'Is Inside',
  },
  isOutside: {
    id: 'OUTSIDE',
    name: 'Is Outside',
  },
  // isRange: {
  //   id: 'RANGE',
  //   name: 'Is range',
  // },
};

export const CRITERIA = {
  triggerEvent: {
    type: 'triggerEvent',
    label: 'Trigger event',
    inputType: INPUT_VARIANT.SINGLE_CHOICES,
    optionList: [
      {
        id: 'REGISTRATION',
        name: 'REGISTRATION',
      },
      {
        id: 'ENROLLMENT',
        name: 'ENROLLMENT',
      },
    ],
    rules: [RULES.isEqualTo, RULES.isIn],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return level === 1 && promoType === PROMO.SPACE;
    },
  },
  country: {
    type: 'country',
    label: 'Country',
    inputType: INPUT_VARIANT.MULTIPLE_CHOICES,
    optionList: countries,
    rules: [RULES.isEqualTo, RULES.isNotEqualTo, RULES.isIn, RULES.isNotIn],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return (
        level === 1 &&
        (promoType === PROMO.SPACE || promoType === PROMO.CAMPAIGNS)
      );
    },
  },
  userId: {
    type: 'userId',
    label: 'User ID',
    inputType: INPUT_VARIANT.TEXT,
    rules: [RULES.isEqualTo, RULES.isIn],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  time1: {
    type: 'time1',
    label: 'Time1',
    inputType: INPUT_VARIANT.DATE,
    rules: [RULES.isGratherThan],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  time2: {
    type: 'time2',
    label: 'Time 2',
    inputType: INPUT_VARIANT.DATE,
    rules: [RULES.isLessThan],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  appVersion1: {
    type: 'appVersion1',
    label: 'App version 1',
    inputType: INPUT_VARIANT.TEXT,
    rules: [
      RULES.isEqualTo,
      RULES.isNotEqualTo,
      RULES.isGratherThan,
      RULES.isIn,
      RULES.isNotIn,
    ],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  appVersion2: {
    type: 'appVersion2',
    label: 'App version 2',
    inputType: INPUT_VARIANT.TEXT,
    rules: [
      RULES.isEqualTo,
      RULES.isNotEqualTo,
      RULES.isLessThan,
      RULES.isIn,
      RULES.isNotIn,
    ],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  position: {
    type: 'position',
    label: 'Position',
    inputType: INPUT_VARIANT.POSITION,
    rules: [RULES.isInside, RULES.isOutside],
    showIn: [PROMO.SPACE, PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  enrolledAppliance: {
    type: 'enrolledAppliance',
    label: 'Enrolled appliance',
    inputType: INPUT_VARIANT.NUMBER,
    rules: [RULES.isEqualTo, RULES.isIn],
    showIn: [PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  applianceType: {
    type: 'applianceType',
    label: 'Appliance Type',
    inputType: INPUT_VARIANT.MULTIPLE_CHOICES,
    optionList: applianceType,
    rules: [RULES.isEqualTo, RULES.isIn],
    showIn: [PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  brand: {
    type: 'brand',
    label: 'Brand',
    inputType: INPUT_VARIANT.MULTIPLE_CHOICES,
    optionList: brand,
    rules: [RULES.isEqualTo, RULES.isIn],
    showIn: [PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  serialNumber: {
    type: 'serialNumber',
    label: 'Appliance serial number',
    inputType: INPUT_VARIANT.TEXT,
    rules: [RULES.isEqualTo, RULES.isNotEqualTo],
    showIn: [PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
  promoSpaces: {
    type: 'promoSpaces',
    label: 'Promo space',
    inputType: INPUT_VARIANT.MULTIPLE_CHOICES,
    optionList: [],
    rules: [RULES.isEqualTo, RULES.isNotEqualTo],
    showIn: [PROMO.CAMPAIGNS],
    requiredByDefault: ({ level, promoType }) => {
      return false;
    },
  },
};

export const CRITERIA_MAP = Object.values(CRITERIA);
export const getCriteriaBasedOnPromoType = level => {
  return Object.keys(CRITERIA).reduce((acc, key) => {
    if (key === CRITERIA.country.type || key === CRITERIA.triggerEvent.type) {
      return {
        ...acc,
        [key]: { isActive: level === 1, level },
      };
    } else {
      return {
        ...acc,
        [key]: { isActive: false, level },
      };
    }
  }, {});
};
