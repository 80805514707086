import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  FieldsGenerator,
  CustomBreadcrumbs,
} from 'candy-commons-components';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { Typography } from '@material-ui/core';
import CustomToolbar from '../../components/CustomToolbar';
import { validateJson, ValueInput } from './commonComponents';

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  customContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'flex-start',
    marginRight: -8,
    marginBottom: 20,
    '& >div.MuiFormControl-root': {
      width: 260,
      margin: 8,
    },
  },
});

const AppConfigurationEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const { id } = props;
  const isEditDisabled = !permissions['app-configuration']?.update;
  const breadcrumbsData = [
    {
      text: 'App Configuration',
      url: 'app-configuration',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'Type',
          type: 'textField',
          source: 'type',
        },
        {
          label: 'Config Name',
          type: 'textField',
          source: 'id',
        },
      ]}
      record={record}
      {...props}
    />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}
          validate={validateJson}
        >
          <ShowList />
          <hr className={classes.width} />

          {props.id !== 'app-update' ? (
            <ContainerFieldsList>
              <Typography variant="h6" component="h6">
                General configurations
              </Typography>
              <ContainerFieldsList>
                <TextInput source="iosMinVersion" label="iOS minimum version" disabled={isEditDisabled} />
                <TextInput
                  source="androidMinVersion"
                  label="Android minimum version"
                  disabled={isEditDisabled}
                />
              </ContainerFieldsList>

              <Typography variant="h6" component="h6">
                Key-value configurations
              </Typography>

              <ContainerFieldsList>
                <ValueInput source="value" label="Config value" disabled={isEditDisabled} />
              </ContainerFieldsList>
            </ContainerFieldsList>
          ) : (
            <ContainerFieldsList className={classes.customContainer} style={{ paddingLeft: 10 }}>
              <Typography variant="h6" component="h6">
                General configurations
              </Typography>
              <BooleanInput source="appUpdate" label="App Update" disabled={isEditDisabled} />
            </ContainerFieldsList>
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};

export default AppConfigurationEdit;
