import React from 'react';
import { CustomSelectInput } from 'candy-commons-components';
import { Loading, useGetList } from 'react-admin';

export const PromoRewardInput = ({ promoCampaignId, isEditDisabled }) => {
  const {
    data: promoRewards,
    total,
    isLoading: isLoadingPromoRewards,
    error,
  } = useGetList('promo-rewards');

  if (isLoadingPromoRewards) {
    return <Loading />;
  }

  return (
    promoRewards &&
    !isLoadingPromoRewards && (
      <CustomSelectInput
        source="content.rewardId"
        label="Reward Id"
        // validate={[required()]}
        choices={Object.values(promoRewards)
          .filter(pr => {
            if (promoCampaignId) {
              return (
                !pr.promoCampaignId || pr.promoCampaignId == promoCampaignId
              );
            } else {
              return !pr.promoCampaignId;
            }
          })
          .map(r => ({
            id: r.id,
            name: r.slug,
          }))}
        disabled={isEditDisabled}
      />
    )
  );
};
