import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
  CustomBreadcrumbs,
  CustomFilter,
  CustomList,
  CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const SmartCheckupFilter = props => {
  return (
    <CustomFilter {...props}>
      <TextInput
        label="resources.smart-checkup.fields.search"
        source="q"
        alwaysOn
      />
      <CustomSelectInput
        source="field"
        choices={[
          { id: 'errorCode', name: 'Technical Error Code' },
          { id: 'brand', name: 'Brand' },
          { id: 'applianceType', name: 'Appliance Type' },
          { id: 'applianceModel', name: 'Appliance Model' },
          { id: 'title', name: 'Title' },
          { id: 'description', name: 'Description' },
          { id: 'category', name: 'Category' },
          { id: 'cdmParameter', name: 'Common Data Model Parameter' },
          { id: 'series', name: 'Series' },
          { id: 'seriesVersion', name: 'Series Version' },
        ]}
        alwaysOn
      />
    </CustomFilter>
  );
};

const SmartCheckupList = ({ permissions, ...props }) => {
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.smart-checkup.name'),
      url: '',
      icon: <ErrorIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        pagination={<DynamoPagination resource="smart-checkup" />}
        exporter={false}
        filters={<SmartCheckupFilter />}
        {...props}
        bulkActionButtons={
          permissions &&
          permissions['smart-checkup'] &&
          permissions['smart-checkup'].delete
        }
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="errorCode" label="Technical Error Code" />
          <TextField source="applianceType" />
          <TextField source="series" />
          <TextField source="seriesVersion" />
          <TextField source="title" />
          <TextField source="description" />
          <TextField source="cdmParameter" />
          <TextField source="category" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default SmartCheckupList;
