import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import brand from '../../data/brand.json';
import series from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';

const SupportCreate = props => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.support.name'),
      url: 'support',
      icon: <ContactSupportIcon />,
    },
    {
      text: translation('resources.support.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list">
          <ContainerFieldsList>
            <TextInput source="applianceType" validate={required()} />
            <CustomSelectInput allowEmpty source="brand" choices={brand} />
            <CustomSelectInput allowEmpty source="series" choices={series} />
            <CustomSelectInput
              allowEmpty
              source="loadingType"
              choices={LOADING_TYPE}
            />
            <TextInput source="title" validate={required()} />
            <TextInput source="type" validate={required()} />
            <TextInput source="screen" validate={required()} />
            <TextInput source="iconPath" validate={required()} />
            <TextInput source="description" validate={required()} />
            <TextInput source="tutorialName" validate={required()} />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

const useStyles = makeStyles({});

export default SupportCreate;
