import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  FieldsGenerator,
  CustomBreadcrumbs,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';


const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const AppUserInfoEdit = ({ permissions = {}, ...props }) => {
  const translation = useTranslate();
  const classes = useStyles();
  const isEditDisabled = !permissions?.['app-user-info']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.app-user-info.name'),
      url: 'app-user-info',
      icon: '',
    },
    {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
      fields={[
        {
          label: 'PK',
          type: 'textField',
          source: 'PK',
        },
        {
          label: 'SK',
          type: 'textField',
          source: 'SK',
        },
        {
          label: 'email',
          type: 'textField',
          source: 'email',
        },
      ]}
      record={record}
      {...props}
    />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ShowList />
          <hr className={classes.width} />
          <ContainerFieldsList>
            <BooleanInput source="canSubmitFeedback" disabled={isEditDisabled} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default AppUserInfoEdit;
