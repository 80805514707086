import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { ContainerFieldsList, CustomBreadcrumbs, CustomSelectArrayInput, CustomSelectInput } from 'candy-commons-components';
import React, { useState } from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRAND from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import LANGUAGES from '../../data/languages.json';
import SERIES from '../../data/series.json';
import POPUP_TYPES from '../../data/serviceCommunications.popupTypes.json';

const useStyles = makeStyles({
  width: {
    width: "100% !important",
  },
});

const ServiceCommunicationsEdit = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const classes = useStyles();
  const isEditDisabled = !permissions?.['service-communications']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.service-communications.name'),
      url: 'service-communications',
      icon: <SpeakerNotesIcon />,
    },
    {
      text: id,
      url: ''
    }
  ];
  const [allApplianceFlag, setAllApplianceFlag] = useState(false);
  const handleAllAppliance = (e) => setAllApplianceFlag(e?.target?.checked);


  const areAllSelected = (props) => {
    console.log('areAllSelected', props);
  }

  return (<>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>

        <Typography style={{ paddingTop: 10 }}>
          {translation('resources.service-communications.titles.settings')}
        </Typography>
        <ContainerFieldsList >
          <TextInput source="popupId" label="Popup Id" validate={required()} disabled />
          <CustomSelectInput source="popupType" label="Popup Type" choices={POPUP_TYPES} validate={required()} disabled={isEditDisabled} />
          <BooleanInput source="active" label="active" disabled={isEditDisabled}  />
        </ContainerFieldsList>

        <hr className={classes.width} />
        <Typography style={{ paddingTop: 10 }}>
          {translation('resources.service-communications.titles.content')}
        </Typography>
        <TextInput source="title" label="Title" fullWidth validate={required()} disabled={isEditDisabled} />
        <TextInput source="body" label="Body Content" fullWidth multiline validate={required()} disabled={isEditDisabled} />

        <hr className={classes.width} />
        <Typography style={{ paddingTop: 10 }}>
          {translation('resources.service-communications.titles.criteria')}
        </Typography>
        <ContainerFieldsList>
          <FormDataConsumer>
              {() => (
                <>
                  {
                    !allApplianceFlag && (
                      <CustomSelectArrayInput
                        label="Appliance types"
                        source="applianceType"
                        choices={APPLIANCE_TYPES}
                        disabled={allApplianceFlag || isEditDisabled}
                      />
                    )
                  }
                  <BooleanInput
                    source="AllAppliance"
                    onClick={handleAllAppliance}
                    disabled={isEditDisabled}
                  />
                </>
              )}
          </FormDataConsumer>
        </ContainerFieldsList>
        <ContainerFieldsList >
          <CustomSelectInput source="series" label="Series" choices={SERIES} disabled={isEditDisabled} />
          <TextInput label="Series Version" source="seriesVersion" disabled={isEditDisabled} />
          <CustomSelectInput source="brand" label="Brand" choices={BRAND}  disabled={isEditDisabled} />
          <CustomSelectInput source="language" label="App Language" choices={LANGUAGES}  disabled={isEditDisabled} />
          <TextInput source="minAppVersion" label="Minimum App Version" disabled={isEditDisabled} />
          <TextInput source="maxAppVersion" label="Maximum App Version" disabled={isEditDisabled} />
          <DateInput source="startTime" label="Start Time" validate={required()} disabled={isEditDisabled} />
          <DateInput source="endTime" label="End Time" validate={required()} disabled={isEditDisabled} />
          <CustomSelectArrayInput
            label="countries"
            source="countries"
            choices={COUNTRIES}
            disabled={isEditDisabled}
          />
          <BooleanInput
            source="countriesToExclude"
            label="Are countries to exclude?"
            disabled={isEditDisabled}
          />
        </ContainerFieldsList>

      </SimpleForm>
    </Edit>
  </>)
};


export default ServiceCommunicationsEdit;
