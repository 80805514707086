import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React, { cloneElement } from 'react';
import {
  ArrayField, BooleanField, ChipField, SingleFieldList, TextField, TextInput, useTranslate,
} from 'react-admin';

import DynamoPagination from '../../../components/DynamoPagination';
import CustomDatagrid from '../../../components/organisms/customDatagrid';


const GWChoicesFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.guided-washing-choices.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const GuidedWashingChoicesList = ({ permissions, ...props }) => {
  const tranlation = useTranslate();
  const breadcrumbsData = [
    {
      text: tranlation('resources.guided-washing-choices.name_breadcrumbs'),
      url: '',
      icon: <SwapHorizIcon />,
    },
  ];
  const StringToLabelObject = ({ record, children, ...rest }) => cloneElement(children, {
    record: { label: record },
    ...rest,
  });
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        {...props}
        exporter={false}
        pagination={<DynamoPagination resource="gwChoices" />}
        filters={<GWChoicesFilter />}
        bulkActionButtons={permissions && permissions['guided-washing'] && permissions['guided-washing'].delete}
      >
        <CustomDatagrid selectable rowClick="edit">
          <ArrayField source="selection">
            <SingleFieldList linkType={false}>
              <StringToLabelObject>
                <ChipField source="label" />
              </StringToLabelObject>
            </SingleFieldList>
          </ArrayField>
          <TextField source="choiceResult" />
          <BooleanField source="recommended" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default GuidedWashingChoicesList;
