import React from 'react';
import { TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const additionalJsonProps = {
  style: { width: '100%' },
  multiline: true,
  rows: 10,
  placeholder: 'Please validate your JSON input',
  initialValue: '{}',
};

const ValueInput = prevProps => {
  const {
    values: { type },
  } = useFormState();
  const additionalProps = type === 'jsonMatrix' ? additionalJsonProps : {};
  const props = { ...prevProps, ...additionalProps };

  return <TextInput {...props} />;
};

const validateJson = values => {
  const errors = {};
  if (values.type === 'jsonMatrix') {
    try {
      JSON.parse(values.value);
    } catch (e) {
      errors.value = e;
    }
  }
  return errors;
};

export { ValueInput, validateJson };
