import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import axios from 'axios';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import {
  BooleanField,
  CreateButton,
  TextField,
  TextInput,
  useNotify,
  useTranslate,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconContentPublish from '@material-ui/icons/Publish';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import {
  CustomBreadcrumbs,
  CustomFilter,
  CustomList,
} from 'candy-commons-components';
import DynamoPagination from '../../components/DynamoPagination';
import config from '../../config';

const { basePath: apiPath } = config;
const MockListAction = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  permissions,
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translation = useTranslate();
  const [currentVersion, setCurrentVersion] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const username = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`,
  );
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`,
  );
  useEffect(() => {
    axios
      .get(`${apiPath}/mock-version`, {
        params: {},
        headers: { Authorization: token },
      })
      .then(response => {
        if (response && response.data && response.data.version) {
          setCurrentVersion(response.data.version);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const handleClick = () => {
    setLoading(true);
    axios
      .get(`${apiPath}/mock-publish`, {
        params: {},
        headers: { Authorization: token },
      })
      .then(response => {
        if (response && response.data && response.data.version) {
          setCurrentVersion(response.data.version);
          notify(`version ${response.data.version} published`);
          dataProvider
            .getList('mock-demo', {
              filter: {},
              pagination: {},
              sort: {},
            })
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <span>
        {translation('resources.mocks.action.current_version')} {currentVersion}{' '}
      </span>
      {permissions &&
        permissions['mock-demo'] &&
        permissions['mock-demo'].update && (
          <Button
            color="primary"
            label="resources.mocks.action.publish_in_app"
            onClick={handleClick}
          >
            {isLoading ? (
              <CircularProgress size={25} thickness={2} />
            ) : (
              <IconContentPublish />
            )}
            <span>{translation('resources.mocks.action.publish_in_app')}</span>
          </Button>
        )}
      {permissions &&
        permissions['mock-demo'] &&
        permissions['mock-demo'].create && <CreateButton basePath={basePath} />}
    </Toolbar>
  );
};

const MockDemoList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.mock-demo.name'),
      url: '',
      icon: <ChildFriendlyIcon />,
    },
  ];

  const MockFilter = props => (
    <CustomFilter {...props}>
      <TextInput label="resources.mock.fields.search" source="q" alwaysOn />
    </CustomFilter>
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        {...props}
        perPage={25}
        pagination={<DynamoPagination resource="mock" />}
        filters={<MockFilter />}
        actions={<MockListAction permissions={permissions} />}
        bulkActionButtons={
          permissions &&
          permissions['mock-demo'] &&
          permissions['mock-demo'].delete
        }
      >
        <CustomDatagrid rowClick="edit" selectable>
          <TextField source="code" />
          <TextField source="mockName" />
          <BooleanField source="published" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default MockDemoList;
