import React from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { Storage } from 'aws-amplify';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  Edit,
  ImageField,
  Labeled,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import countries from '../../data/programListRulesCountries.json';

const ShowList = ({ record, _t }) => {
  const recordsToShow = ['applianceType', 'brand', 'series', 'seriesVersion'];
  const res = recordsToShow.map(x => {
    const label = _t(`resources.program-list-rules.fields.${x}`);
    return (
      <Grid key={x} item>
        <Labeled label={label} style={{ flex: 1 }}>
          <Box>{record[x] || 'generic'}</Box>
        </Labeled>
      </Grid>
    );
  });

  return (
    <Grid container justify="space-between">
      {res}
    </Grid>
  );
};

const ProgramRulesEdit = ({ permissions = {}, ...props }) => {
  const _t = useTranslate();
  const isEditDisabled = !permissions?.['program-list-rules']?.update;
  const { id } = props;

  const splittedId = id.split('#');
  const pageTitle = ['App Type', 'Brand', 'Series', 'Ver']
    .reduce((ac, k, i) => {
      const v = splittedId[i];
      if (v === 'generic') return ac;
      return [...ac, `${k}: ${v}`];
    }, [])
    .join(' - ');

  const breadcrumbsData = [
    {
      text: 'Program list rules',
      url: 'program-list-rules',
      icon: <SettingsIcon />,
    },
    {
      text: pageTitle,
      url: '',
    },
  ];

  const handleClick = async event => {
    const file = event;
    if (!file) {
      return;
    }
    const key = `program-list-rules/${file.name}`;
    await Storage.put(key, file, {
      contentType: file.type,
    });
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <Typography style={{ paddingTop: 10 }}>
            {_t('resources.program-list-rules.keyAttributes')}
          </Typography>
          <ShowList _t={_t} />
          <Divider style={{ width: '100%' }} />
          <Typography style={{ paddingTop: 10 }}>
            {_t('resources.program-list-rules.editableAttributes')}
          </Typography>
          <ContainerFieldsList>
            <TextInput
              multiline
              source="additionalModelParams"
              disabled={isEditDisabled}
            />
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={countries}
              parse={x => (x.includes('ALL') ? ['ALL'] : x)}
              disabled={isEditDisabled}
            />
            <Divider style={{ width: '100%' }} />
            <Grid
              container
              style={{ width: '100%', padding: '0 10px 10px' }}
              direction="column"
            >
              <Typography style={{ paddingTop: 10 }}>
                {_t('Association')}
              </Typography>
              <Grid container>
                <Grid item xs={4} style={{ paddingRight: 15 }}>
                  <CustomSelectInput
                    style={{ width: '100%' }}
                    label={_t(
                      'resources.program-list-rules.fields.associationType',
                    )}
                    source="associationType"
                    choices={[
                      {
                        id: 'programName',
                        name: 'Program Name',
                      },
                      {
                        id: 'cluster',
                        name: 'Cluster',
                      },
                    ]}
                    disabled={isEditDisabled}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    style={{ width: '100%' }}
                    label={_t(
                      'resources.program-list-rules.fields.associationValue',
                    )}
                    source="associationValue"
                    disabled={isEditDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Divider style={{ width: '100%' }} />
          </ContainerFieldsList>
          {!isEditDisabled && (
              <Grid container style={{ width: '100%', padding: '0 10px' }}>
                <Grid item xs={6} style={{ paddingRight: 3 }}>
                  <ImageInput
                    source="iconPath"
                    label="Icon"
                    accept="image/*"
                    onChange={handleClick}
                    placeholder={
                      <p>{_t('resources.asset.fields.input_file')}</p>
                    }
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 3 }}>
                  <ImageInput
                    source="imagePath"
                    label="Image"
                    accept="image/*"
                    onChange={handleClick}
                    placeholder={
                      <p>{_t('resources.asset.fields.input_file')}</p>
                    }
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
              </Grid>
            )}
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ProgramRulesEdit;
