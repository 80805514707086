import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  TextField,
  useTranslate,
  ArrayField, ChipField, SingleFieldList,
} from 'react-admin';

import CustomDatagrid from '../../components/organisms/customDatagrid';

const RoleList = ({ permissions, ...props }) => {
  const transaltion = useTranslate();
  const breadcrumbsData = [
    {
      text: transaltion('resources.role.name'),
      url: '',
      icon: <PersonAddIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        exporter={false}
        {...props}
        bulkActionButtons={(permissions && permissions.roles && permissions.roles.delete) ? undefined : false}
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="name" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};


export default RoleList;
