import React from 'react';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  Create,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import applianceType from '../../data/applianceType.json';

const MockDemoCreate = props => {
  const translation = useTranslate();

  const breadcrumbsData = [
    {
      text: translation('resources.mock-demo.name'),
      url: 'mock-demo',
      icon: <ChildFriendlyIcon />,
    },
    {
      text: translation('resources.mock-demo.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list">
          <ContainerFieldsList>
            <TextInput source="code" validate={required()} />
            <TextInput source="mockName" validate={required()} />
          </ContainerFieldsList>
          <FileInput
            source="mocks"
            accept="application/json"
            validate={required()}
            placeholder={
              <p>{translation('resources.mock.fields.input_file')}</p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default MockDemoCreate;
