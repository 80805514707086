import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
  FieldsGenerator
} from 'candy-commons-components';
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import { categoryName } from './source';

const SmartDoorCounterEditRule = ({ permissions = {}, ...props }) => {
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['smart-door-counter']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.smart-door-counter.name'),
      url: 'smart-door-counter',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  const staticData = [
    {
      label: 'Score',
      type: 'textField',
      source: 'score',
    },
  ];


  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <ContainerFieldsList>
            <FieldsGenerator fields={staticData} />
            <CustomSelectInput source='category' choices={categoryName} validate={required()} disabled={isEditDisabled} />
            <NumberInput source='min' disabled={isEditDisabled} />
            <NumberInput source='max' disabled={isEditDisabled} />
            <TextInput source="description" validate={[required()]} disabled={isEditDisabled} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default SmartDoorCounterEditRule;
