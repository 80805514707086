/** Postgres db tables names for Multi Account feature */
const MultiAccountTables = {
  group: 'multi_account_group',
  groupUser: 'multi_account_group_user',
  groupUserPermission: 'multi_account_group_user_permission',
  groupUserRole: 'multi_account_group_user_role',
  invitation: 'multi_account_invitation',
  permission: 'multi_account_permission',
  role: 'multi_account_role',
  rolePermission: 'multi_account_role_permission'
};
const MultiAccountDeepLinkPrefix = 'https://hon.he.services/multiaccount-invitations';
const MultiAccountInvitationStatuses = {
  CREATED: 'CREATED',
  EXPIRED: 'EXPIRED',
  // Guest statuses
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  // Inviter statuses
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED'
};

/** Valid duration units */
const MultiAccountDurationUnits = ['month', 'week', 'day', 'hour', 'minute'];
const MultiAccountNotifications = {
  MULTIACCOUNT_NEW_ACCESS_REQUEST: 'MULTIACCOUNT_NEW_ACCESS_REQUEST',
  MULTIACCOUNT_YOU_ARE_NEW_OWNER: 'MULTIACCOUNT_YOU_ARE_NEW_OWNER',
  MULTIACCOUNT_NEW_OWNER_INFORM: 'MULTIACCOUNT_NEW_OWNER_INFORM',
  MULTIACCOUNT_USER_REMOVED: 'MULTIACCOUNT_USER_REMOVED',
  MULTIACCOUNT_USER_REMOVED_INFORM_MEMBERS: 'MULTIACCOUNT_USER_REMOVED_INFORM_MEMBERS',
  MULTIACCOUNT_FUNCTIONALITY_NOT_AVAILABLE: 'MULTIACCOUNT_FUNCTIONALITY_NOT_AVAILABLE',
  MULTIACCOUNT_REMOVED_APPLIANCE: 'MULTIACCOUNT_REMOVED_APPLIANCE',
  MULTIACCOUNT_ECODELAY_CONF_CREATE: 'MULTIACCOUNT_ECODELAY_CONF_CREATE',
  MULTIACCOUNT_ECODELAY_CONF_UPDATE: 'MULTIACCOUNT_ECODELAY_CONF_UPDATE'
};
module.exports = {
  MultiAccountInvitationStatuses,
  MultiAccountDeepLinkPrefix,
  MultiAccountTables,
  MultiAccountDurationUnits,
  MultiAccountNotifications
};