import TimelapseIcon from '@material-ui/icons/Timelapse';
import { CustomBreadcrumbs, CustomFilter, CustomList } from 'candy-commons-components';
import React from 'react';
import {
  BooleanField, NumberField, TextField, TextInput, useTranslate,
} from 'react-admin';

import DynamoPagination from '../../../components/DynamoPagination';
import CustomDatagrid from '../../../components/organisms/customDatagrid';

const GuidedWashingCycleFilter = props => (
  <CustomFilter {...props}>
    <TextInput label="resources.guided-washing.fields.search" source="q" alwaysOn />
  </CustomFilter>
);

const GuidedWashingCycleList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.guided-washing.name_breadcrumbs'),
      url: '',
      icon: <TimelapseIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        pagination={<DynamoPagination resource="guidedWashingCycle" />}
        filters={<GuidedWashingCycleFilter />}
        bulkActionButtons={permissions && permissions['guided-washing'] && permissions['guided-washing'].delete}
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField source="category" />
          <NumberField source="outputCycle" />
          <NumberField source="soil" />
          <TextField source="cycleType" />
          <NumberField source="temperature" />
          <NumberField source="color" />
          <TextField source="cluster" />
          <TextField source="spins" />
          <BooleanField source="steam" />
          <TextField source="dry" />
        </CustomDatagrid>
      </CustomList>
    </>
  );
};

export default GuidedWashingCycleList;
