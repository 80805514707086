import AssistantIcon from '@material-ui/icons/Assistant';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';

const EcoDelayStartList = ({ permissions, ...props }) => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.eco-delay-start.name'),
      url: '',
      icon: <AssistantIcon />,
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        exporter={false}
        perPage={25}
        {...props}
        pagination={<DynamoPagination resource="eco-delay-start" />}
        // filters={<AssistanceFilter />}
        bulkActionButtons={
          permissions &&
          permissions['eco-delay-start'] &&
          permissions['eco-delay-start'].delete
        }
      >
        <CustomDatagrid selectable rowClick="edit">
          <TextField label={'Name'} source="id" />
          {/* capire se aggiungere altri parametri nella lista (ex. dati del primo item della lista ) */}
        </CustomDatagrid>
      </CustomList>
    </>
  );
};
export default EcoDelayStartList;
