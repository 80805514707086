import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import React from 'react';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
} from 'candy-commons-components';
import {
  BooleanInput,
  Create,
  TextInput,
  Toolbar,
  SimpleForm,
  useTranslate,
  required,
  SaveButton
} from 'react-admin';
import { useStyles } from '../styles';

const FAQCreate = props => {
  const translation = useTranslate();
  const classes = useStyles();

  const breadcrumbsData = [
    {
      text: translation('resources.multi-account-faq.name'),
      url: 'multi-account-faq',
      icon: <LiveHelpIcon />,
    },
    {
      text: translation('resources.multi-account-faq.create.name'),
      url: '',
    },
  ];

  const CustomToolbar = props => {
    return (
      <Toolbar {...props}>
        <SaveButton
          redirect="list"
        />
      </Toolbar>
    );
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list" className={classes.fullWidth} toolbar={<CustomToolbar />}>
            <BooleanInput
              initialValue={false}
              source="publish"
              label="Publish"
              validate={[required()]}
              className={classes.fullWidth}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <TextInput source="title" label="Title" validate={required()} style={{ width: '45%' }} />
              <TextInput
                source="link"
                label="hOn Link"
                validate={required()}
                style={{ width: '45%' }}
              />
            </div>
        </SimpleForm>
      </Create>
    </>
  );
};

export default FAQCreate;
