import React from 'react';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput
} from 'react-admin';

const breadcrumbsData = [
  {
    text: 'Ingredient [REF]',
    url: 'ingredient-ref',
    icon: <ViewComfyIcon />,
  },
  {
    text: 'Create',
    url: '',
  },
];

const IngredientRefCreate = (props) => {
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <TextInput source="name" label="Name (translation key)" />
            <ReferenceInput source="categoryId" label="Category" reference="food-category">
              <CustomSelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="fridgeExpiryTime" label="Fridge Expires In (days)" />
            <TextInput source="freezerExpiryTime" label="Freezer Expires In (days)" />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default IngredientRefCreate;
