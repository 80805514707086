import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  FieldsGenerator
} from 'candy-commons-components';
import {
  Edit, SimpleForm, TextInput,
  required,
  useTranslate
} from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import CustomToolbar from '../../components/CustomToolbar';
import CustomUpload from '../../components/organisms/customUpload';
import applianceType from '../../data/applianceType.json';

const TutorialEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions?.['tutorial-landing-screen']?.update;
  const translation = useTranslate();
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator fields={[{
      label: "resources.tutorial-landing-screen.fields.code",
      type: "textField",
      source: "code"
    }]} record={record} {...props} />
  );
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.tutorial-landing-screen.name'),
      url: 'tutorial-landing-screen',
      icon: <SettingsIcon />,
    }, {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar {...props} permissions={permissions} isEditDisabled={isEditDisabled} />}
        >
          <ShowList />
          <ContainerFieldsList>
            <CustomSelectArrayInput source="applianceType" choices={applianceType} validate={[required()]} disabled={isEditDisabled}/>
            <TextInput source="landingScreenTitle" validate={required()} disabled={isEditDisabled} />
            <TextInput source="landingScreenDescription" validate={required()} disabled={isEditDisabled} />
            <TextInput source="assetName" disabled={isEditDisabled} />
          </ContainerFieldsList>
          <CustomUpload imageSource="assetPath" imageInputSource="pictures" imageFieldSource="src" {...props} isEditDisabled={isEditDisabled} />
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  reverseButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
});

export default TutorialEdit;
