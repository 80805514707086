import React from 'react';
import {
  Create, SimpleForm, TextInput, required, useTranslate, regex, useNotify, useRedirect
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import { ContainerFieldsList, CustomBreadcrumbs } from 'candy-commons-components';
import CustomToolbar from '../../components/CustomToolbar';

const TranslationCreate = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions?.translation?.update;
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const breadcrumbsData = [
    {
    text: translate('resources.translation.name'),
      url: 'translation',
      icon:<TranslateIcon/>
    }, {
      text: translate('resources.translation.create.name'),
      url: ''
    }
  ];
  const codePattern = /^[A-Z0-9\._&#-:]+(?!\n)$/;
  const codeErrorMessage = `Code only accept: Uppercase letters; Numbers; Special chars like dot _ & # - :`

  const onSuccess = ({ data }) => {
    if ('hasTextBeenSanitized' in data && data.hasTextBeenSanitized) {
      notify(`The translation key contained some invalid chars but has been sanitized and created, be careful next time!`);
    } else {
      notify(`Translation key created successfully`);
    }
    redirect('show', '/translation', data.id, data);
  };

  return (<>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
      >
        <ContainerFieldsList >
          <TextInput className={classes.containerFieldsFullWidth} source="code" validate={[required(), regex(codePattern, codeErrorMessage)]} disabled={isEditDisabled} />
          <TextInput className={classes.containerFieldsFullWidth} fullWidth multiline source="lang_en" validate={required()} disabled={isEditDisabled} />
        </ContainerFieldsList>
      </SimpleForm>
    </Create>
  </>)
};
const useStyles = makeStyles({
  containerFieldsFullWidth:{
    width:"100%  !important",
  }
});
export default TranslationCreate;
