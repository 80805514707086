import { makeStyles } from '@material-ui/core/styles';
import { ContainerFieldsList, FieldsGenerator, CustomBreadcrumbs } from 'candy-commons-components';
import React from 'react';
import {
  Edit, SimpleForm, TextInput, useTranslate, required,
} from 'react-admin';

import ImageIcon from '@material-ui/icons/Image';
import { showListData } from './assetStaticData';
import CustomToolbar from '../../components/CustomToolbar';
import CustomUpload from '../../components/organisms/customUpload';


const AssetEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const isEditDisabled = !permissions?.asset?.update;
  const translation = useTranslate();
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator fields={showListData} record={record} {...props} />
  );
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translation('resources.asset.name'),
      url: 'asset',
      icon: <ImageIcon />,
    }, {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit className={classes.reverseButtonSave} {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar {...props} permissions={permissions} isEditDisabled={isEditDisabled} />}
        >
          <ShowList />
          <ContainerFieldsList>
            <TextInput source="assetName" disabled={isEditDisabled} />
          </ContainerFieldsList>
          <CustomUpload imageSource="assetPath" imageInputSource="pictures" imageFieldSource="src" {...props} isEditDisabled={isEditDisabled} />
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
  reverseButtonSave: {
    '& .MuiToolbar-root>div': {
      flexDirection: 'row-reverse',
    },
  },
});

export default AssetEdit;
