import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
  ArrayInput,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import series from '../../data/series.json';
import supportGuideHelpType from '../../data/supportGuideType.json';

const SupportGuideHelpEdit = ({ permissions = {}, ...props }) => {
  const transaltion = useTranslate();
  const [tdChoices, setTdChoices] = useState(false);
  const isEditDisabled = !permissions?.['support-guide-help']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: transaltion('resources.support-guide-help.name'),
      url: 'support-guide-help',
      icon: <SettingsIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          toolbar={
            <CustomToolbar
              permissions={permissions}
              isEditDisabled={isEditDisabled}
            />
          }
        >
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={[{ id: 'GENERIC', name: 'GENERIC' }, ...applianceType]}
              validate={required()}
              onChange={event => {
                try {
                  if (event.target.value === 'TD') {
                    setTdChoices(true);
                  } else {
                    setTdChoices(false);
                  }
                } catch (_error) {}
              }}
              disabled={isEditDisabled}
            />
            <CustomSelectInput
              allowEmpty
              source="brand"
              choices={brand}
              disabled={isEditDisabled}
            />
            <CustomSelectInput
              allowEmpty
              source="series"
              choices={series}
              disabled={isEditDisabled}
            />
            <TextInput source="seriesVersion" disabled={isEditDisabled} />
            <TextInput source="title" disabled={isEditDisabled} />
            <TextInput source="description" disabled={isEditDisabled} />
            <CustomSelectInput
              source="supportGuideHelpType"
              choices={supportGuideHelpType}
              validate={required()}
              onChange={event => {}}
              disabled={isEditDisabled}
            />
          </ContainerFieldsList>
          <ArrayInput source="steps" disabled={isEditDisabled}>
            <SimpleFormIterator getItemLabel={index => `${index + 1}-key`}>
              <SimpleShowLayout>
                <ImageField source="imagePath" />
              </SimpleShowLayout>
              <TextInput label="title" source="title" />
              <ArrayInput source="descriptions">
                <SimpleFormIterator>
                  <TextInput source="description" />
                </SimpleFormIterator>
              </ArrayInput>
              {!isEditDisabled && (
                <ImageInput
                  source="imagePath"
                  label="Image"
                  accept="image/*"
                  placeholder={<p>Drop your file here</p>}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              )}
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default SupportGuideHelpEdit;
