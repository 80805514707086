import React from "react";
import TranslateIcon from '@material-ui/icons/Translate';
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { CustomBreadcrumbs }  from 'candy-commons-components';

const breadcrumbsData = [
  {
    text: 'Translations',
    url: 'translation',
    icon:<TranslateIcon/>
  }, {
    text: 'Edit',
    url: ''
  }
];

const TranslationEdit = props => (
  <>
    <CustomBreadcrumbs data={breadcrumbsData} />
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <TextInput source="code" validate={required()} />
        <TextInput
          fullWidth
          multiline
          label="English"
          source="lang_en"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  </>
);

export default TranslationEdit;
