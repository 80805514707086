import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ContainerFieldsList, CustomBreadcrumbs,
  CustomSelectArrayInput
} from 'candy-commons-components';
import {
  Create,
  ImageField,
  SimpleForm,
  TextInput, required,
  useTranslate
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';


const TutorialCreate = props => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.tutorial-landing-screen.name'),
      url: 'tutorial-landing-screen',
      icon: <SettingsIcon />,
    }, {
      text: translation('resources.tutorial-landing-screen.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm redirect="list">
          <ContainerFieldsList>
            <CustomSelectArrayInput source="applianceType" choices={applianceType} validate={[required()]}/>
            <TextInput source="landingScreenTitle" validate={required()} />
            <TextInput source="landingScreenDescription" validate={required()} />
            <TextInput source="code" validate={required()} />
            <TextInput source="assetName" validate={required()} />
          </ContainerFieldsList>
          <ImageInput source="pictures" accept="image/*" validate={required()} placeholder={<p>{translation('resources.asset.fields.input_file')}</p>}>
            <ImageField source="imagePath" title="title" />
          </ImageInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default TutorialCreate;
