import { makeStyles } from '@material-ui/core/styles';
import { ContainerFieldsList, CustomBreadcrumbs, FieldsGenerator } from 'candy-commons-components';
import React from 'react';
import {
  Edit, Labeled, required, SimpleForm, TextInput, useTranslate,
} from 'react-admin';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CustomToolbar from '../../components/CustomToolbar';

import { showListData } from './maintenanceGenericStaticData';


const MaintenanceGenericEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isEditDisabled = !permissions?.['maintenance-generic']?.update;
  const { id } = props;
  const breadcrumbsData = [
    {
      text: translate('resources.maintenance-generic.name'),
      url: 'maintenance-generic',
      icon: <WorkOutlineIcon />,
    }, {
      text: id,
      url: '',
    },
  ];
  const ShowList = ({ record, ...props }) => (
    <FieldsGenerator fields={showListData} record={record} {...props} />
  );

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}
          redirect="list"
        >
          <ShowList />
          <ContainerFieldsList>
            <TextInput source="country" validate={required()} disabled={isEditDisabled} />
            <TextInput source="link" disabled={isEditDisabled} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

const useStyles = makeStyles({});

export default MaintenanceGenericEdit;
