import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectArrayInput,
  CustomSelectInput
} from 'candy-commons-components';
import moment from 'moment';
import {
  Create,
  DateInput,
  ImageField,
  NumberInput,
  SimpleForm,
  TextInput,
  number,
  required,
  useTranslate
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import CODE_TYPES from '../../data/campaignCodeType.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator'; // TODO: refactor as a generic function

const useStyles = makeStyles({
  width: {
    width: '100% !important',
  },
});

const CampaignsCreate = props => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.campaigns.name'),
      url: 'campaigns',
      icon: <PageviewIcon />,
    },
    {
      text: translation('resources.campaigns.create.name'),
      url: '',
    },
  ];

  const dateFormatter = v => {
    const d = new Date(v);

    if (Number.isNaN(d)) {
      return null;
    }

    return moment(d).format('YYYY-MM-DD');
  };

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <b>General</b>
          <br />
          <ContainerFieldsList>
            <TextInput source="slug" validate={required()} />
            <TextInput source="title" validate={required()} />
            <TextInput source="description" validate={required()} />
            <NumberInput
              min={1}
              label="Order"
              source="order"
              validate={[discoverOrderValidator, required()]}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <DateInput
              source="from"
              variant="outlined"
              label="from"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={required()}
            />
            <DateInput
              source="to"
              variant="outlined"
              label="to"
              format={d => dateFormatter(d)}
              parse={d => dateFormatter(d)}
              validate={required()}
            />
            <NumberInput
              min={1}
              label="Active duration"
              source="activeDuration"
              validate={[
                number(),
                required(),
                value => {
                  if (value < 0) {
                    return 'Order must be a positive number';
                  }
                  if (!Number.isInteger(value)) {
                    return 'Order must be a decimal number';
                  }
                  return null;
                },
              ]}
            />
            <NumberInput
              min={1}
              label="Push reminder"
              source="pushReminder"
              validate={[
                number(),
                required(),
                value => {
                  if (value < 0) {
                    return 'Order must be a positive number';
                  }
                  if (!Number.isInteger(value)) {
                    return 'Order must be a decimal number';
                  }
                  return null;
                },
              ]}
            />
          </ContainerFieldsList>

          <ContainerFieldsList>
            <CustomSelectArrayInput
              label="Countries"
              source="countries"
              choices={COUNTRIES}
            />
            <TextInput source="shopWebsiteUrl" />
            <TextInput source="shopAppUrl" />
          </ContainerFieldsList>
          <ContainerFieldsList>
            <CustomSelectInput
              label="Code type"
              source="codeType"
              validate={required()}
              choices={CODE_TYPES}
            />
          </ContainerFieldsList>

          {/* colors */}
          <hr className={classes.width} />
          <div>
            <b>Colors</b>
            <div>(format: #ababab)</div>
          </div>
          <br />

          <ContainerFieldsList>
            <TextInput source="mainColor1" />
            <TextInput source="mainColor2" />
            <TextInput source="foregroundColor1" />
            <TextInput source="foregroundColor2" />
            <TextInput source="activeMainColor1" />
            <TextInput source="activeMainColor2" />
            <TextInput source="activeForegroundColor1" />
            <TextInput source="activeForegroundColor2" />
          </ContainerFieldsList>

          {/* Card dashboard */}
          <hr className={classes.width} />
          <div>
            <b>Card dashboard</b>
          </div>
          <br />

          {/* images related to card dashboard */}
          <ImageInput
            source="cardDiscountImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="cardBackgroundImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="cardProductImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="activeCardBackgroundImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="activeCardProductImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="cardBrandLogo"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ContainerFieldsList>
            <TextInput source="cardPromoTxt" />
            <TextInput source="cardPromoBtnTxt" />
          </ContainerFieldsList>

          {/* campaign details */}
          <hr className={classes.width} />
          <div>
            <b>Campaign details (header)</b>
          </div>
          <br />

          {/* images related to campaign details */}
          <ImageInput
            source="detailHeaderDiscountImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="detailHeaderProductImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ContainerFieldsList>
            <TextInput source="detailHeaderDiscountTxt" />
            <TextInput source="detailHeaderDescriptionTxt" />
            <TextInput source="detailHeaderQuestionTxt" />
            <TextInput source="detailHeaderOption1Txt" />
            <TextInput source="detailHeaderOption2Txt" />
          </ContainerFieldsList>

          {/* campaign details banner */}
          <hr className={classes.width} />
          <div>
            <b>Campaign details (banner)</b>
          </div>
          <br />

          <ImageInput
            source="detailBannerProductImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="detailBannerBackgroundImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ContainerFieldsList>
            <TextInput source="detailBannerTitleTxt" />
            <TextInput source="detailBannerTitleTxtSmall" />
            <TextInput source="detailBannerSubtitleTxt" />
            <TextInput source="detailBannerDescriptionTxt" />
            <TextInput source="detailBannerBtnTxt" />
            <TextInput source="detailBannerShowCodeBtnTxt" />
            <TextInput source="detailBannerShowCodeSubtitleTxt" />
          </ContainerFieldsList>

          {/* wine list banner */}
          <hr className={classes.width} />
          <div>
            <b>Wine list (banner)</b>
          </div>
          <br />

          <ImageInput
            source="wineListBannerBackgroundImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="wineListBannerBackgroundImgActive"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="wineListBannerActiveImg"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput
            source="wineListBannerProductImgActive"
            accept="image/*"
            placeholder={
              <p>{translation('resources.asset.fields.input_file')}</p>
            }
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <ContainerFieldsList>
            <TextInput source="wineListBannerTitle" />
            <TextInput source="wineListBannerTitleSmall" />
            <TextInput source="wineListBannerSubtitle" />
            <TextInput source="wineListBannerBtnTxt" />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};


export default CampaignsCreate;
