import * as React from 'react';
import { Form } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const CustomFilterPost = ({
  displayedFilters,
  filterValues,
  setFilters,
  hideFilter,
  open,
  ...props
}) => {
	console.log(props.context, 'context post form');
		//if (!displayedFilters.main) return null;

	const onSubmit = values => {
		if (Object.keys(values).length > 0) {
			setFilters(values);
		} else {
			hideFilter("main");
		}
	};

	const resetFilter = () => {
		setFilters({}, []);
	};

	const classes = useStyles();

	return (
			<Box className={classes.form}>
				<Form onSubmit={onSubmit} initialValues={filterValues}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Box display="flex" alignItems="center" flexWrap="wrap" flexDirection="flex-start" mb={1}>
								{props.children}
								<Box component="span">
									<Button variant="contained" type="submit" className={classes.button}>
										Search
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		);
};

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 28,
    "& .filter-field": {
      flexGrow: 0,
      width: 260,
      flexShrink: 0,
			height: 64,
      alignItems: 'center',
    },
    "& .MuiFormControl-root": {
      width: 244,
    	marginRight: 10
    },
    "& .MuiButtonBase-root.MuiIconButton-root.hide-filter": {
      padding: 4
    },
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20
    },
    "& .MuiFormHelperText-root": {
      height: 0,
    }
  },
  button: {
    flexShrink: 0,
		marginRight: 20,
		backgroundColor: theme.palette.common.cello,
		color: theme.palette.common.white
  }
}));

export default CustomFilterPost;