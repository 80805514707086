import AssistantIcon from '@material-ui/icons/Assistant';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ImageIcon from '@material-ui/icons/Image';
import InfoIcon from '@material-ui/icons/Info';
import LanguageIcon from '@material-ui/icons/Language';
import PageviewIcon from '@material-ui/icons/Pageview';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import WidgetsIcon from '@material-ui/icons/Widgets';
import RowingIcon from '@material-ui/icons/Rowing';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Subway from '@material-ui/icons/Subway';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TranslateIcon from '@material-ui/icons/Translate';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import MissingTranslateIcon from '@material-ui/icons/WarningSharp';
import MissingTranslateCounterIcon from '@material-ui/icons/FormatListNumbered';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Fastfood from '@material-ui/icons/Fastfood';
import SettingsIcon from '@material-ui/icons/Settings';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Amplify from 'aws-amplify';
import { CustomLayout, CustomTheme } from 'candy-commons-components';
import { createHashHistory } from 'history';
import React from 'react';
import { Admin } from 'react-admin';
import { Provider } from 'react-redux';

import AuthProvider from './auth/authProvider';
import CustomMenu from './components/CustomMenu';
import Resource from './components/CustomResource.js';
import Login from './components/Login';
import config from './config';
import {
  ApplianceInfoCreate,
  ApplianceInfoEdit,
  ApplianceInfoList,
} from './containers/applianceInfo';
import {
  AppBundleUpdateCreate,
  AppBundleUpdateEdit,
  AppBundleUpdateList,
} from './containers/appBundleUpdate';
import {
  ApplianceSettingsCreate,
  ApplianceSettingsEdit,
  ApplianceSettingsList,
} from './containers/applianceSettings';
import { AssetCreate, AssetEdit, AssetList } from './containers/asset';
import {
  AssistanceCreate,
  AssistanceEdit,
  AssistanceList,
} from './containers/assistance';
import {
  DiscoverCreate,
  DiscoverEdit,
  DiscoverList,
} from './containers/discover';
import {
  GuidedWashingChoicesCreate,
  GuidedWashingChoicesEdit,
  GuidedWashingChoicesList,
} from './containers/guidedWashing/guidedWashingChoices';
import {
  GuidedWashingClusterCreate,
  GuidedWashingClusterEdit,
  GuidedWashingClusterList,
} from './containers/guidedWashing/guidedWashingCluster';
import {
  GuidedWashingCycleCreate,
  GuidedWashingCycleEdit,
  GuidedWashingCycleList,
} from './containers/guidedWashing/guidedWashingCycle';
import { HintCreate, HintsEdit, HintList } from './containers/hints';
import {
  SmartDoorCounterCreateRule,
  SmartDoorCounterListRule,
  SmartDoorCounterEditRule,
} from './containers/smartDoorCounter';
import {
  HintsCategoryCreate,
  HintsCategoryEdit,
  HintCategoryList,
} from './containers/hintsCategory';
import {
  LanguageCreate,
  LanguageEdit,
  LanguageList,
} from './containers/language';
import {
  MockDemoCreate,
  MockDemoEdit,
  MockDemoList,
} from './containers/mockDemo';
import {
  MaintenanceCreate,
  MaintenanceEdit,
  MaintenanceList,
} from './containers/maintenance';
import {
  MaintenanceGenericCreate,
  MaintenanceGenericEdit,
  MaintenanceGenericList,
} from './containers/maintenanceGeneric';
import { SupportCreate, SupportEdit, SupportList } from './containers/support';
import {
  TranslationCreate,
  TranslationList,
  TranslationShow,
} from './containers/translation';
import { UserCreate, UserEdit, UserList } from './containers/user';
import { ProfileEdit } from './containers/profile';
import {
  TroubleshootingList,
  TroubleshootingCreate,
  TroubleshootingEdit,
} from './containers/troubleshooting';
import {
  ApplianceWakeUpList,
  ApplianceWakeUpCreate,
  ApplianceWakeUpEdit,
} from './containers/appliance-wake-up';
import {
  ServiceModeList,
  ServiceModeCreate,
  ServiceModeEdit,
} from './containers/serviceMode';
import {
  AppUserInfoCreate,
  AppUserInfoEdit,
  AppUserInfoList,
} from './containers/appUserInfo';
import {
  ApplianceConfigurationList,
  ApplianceConfigurationEdit,
  ApplianceConfigurationCreate,
} from './containers/applianceConfiguration';
import { RoleList, RoleEdit, RoleCreate } from './containers/roles';
import {
  IngredientRefList,
  IngredientRefEdit,
  IngredientRefCreate,
} from './containers/ingredient-ref';
import {
  ServiceCommunicationsList,
  ServiceCommunicationsCreate,
  ServiceCommunicationsEdit,
} from './containers/serviceCommunications';
import { RecipeList, RecipeEdit } from './containers/recipe';
import {
  AppConfigurationList,
  AppConfigurationEdit,
  AppConfigurationCreate,
} from './containers/appConfiguration';
import {
  VideoTutorialCreate,
  VideoTutorialList,
  VideoTutorialEdit,
  VideoTutorialShow,
} from './containers/videoTutorial';
import {
  SmartCheckupCreate,
  SmartCheckupEdit,
  SmartCheckupList,
} from './containers/smartCheckup';
import { WidgetCreate, WidgetEdit, WidgetList } from './containers/widget';
import {
  MissingTranslationList,
  MissingTranslationShow,
} from './containers/missingTranslation';
import { MissingTranslationCounterList } from './containers/missingTranslationCounter';
import createAdminStore from './createAdminStore';
import dataProvider from './providers/dataProvider';
import i18nProvider from './i18n/i18nProvider';
import {
  CampaignsCreate,
  CampaignsEdit,
  CampaignsList,
} from './containers/campaigns';
import {
  ProgramRulesList,
  ProgramRulesCreate,
  ProgramRulesEdit,
} from './containers/programListRules';
import {
  MaintenanceInstructionsCreate,
  MaintenanceInstructionsEdit,
  MaintenanceInstructionsList,
} from './containers/maintenanceInstructions';
import {
  EligibleAppliancesCreate,
  EligibleAppliancesEdit,
  EligibleAppliancesList,
} from './containers/eligible-appliances';
import {
  EcoDelayStartCreate,
  EcoDelayStartEdit,
  EcoDelayStartList,
} from './containers/eco-delay-start';
import SupportGuideHelpList from './containers/support-guide-help/SupportGuideHelpList';
import SupportGuideHelpEdit from './containers/support-guide-help/SupportGuideHelpEdit';
import SupportGuideHelpCreate from './containers/support-guide-help/SupportGuideHelpCreate';
import {
  MultiAccountCreate,
  MultiAccountEdit,
  MultiAccountList,
} from './containers/multi-account';
import {
  IamConfigurationEdit,
  IamConfigurationCreate,
  IamConfigurationList,
} from './containers/iam-configuration';
import {
  PromoSpacesList,
  PromoSpacesCreate,
  PromoSpacesEdit,
} from './containers/promoCampaigns/promoSpaces';
import {
  PromoCampaignList,
  PromoCampaignsCreate,
  PromoCampaignsEdit,
} from './containers/promoCampaigns/campaigns';
import {
  RewardsList,
  RewardsCreate,
  RewardsEdit,
} from './containers/promoCampaigns/rewards';
import {
  TutorialList,
  TutorialCreate,
  TutorialEdit,
} from './containers/tutorial-landing-screen';
import { PromoTrackingList } from './containers/promoCampaigns/tracking';
import { FAQList, FAQCreate, FAQEdit } from './containers/multi-account/FAQ';
import {
  HowToList,
  HowToCreate,
  HowToEdit,
} from './containers/multi-account/howTo';
import {
  GetFeedBackTargetCreate,
  GetFeedBackTargetEdit,
  GetFeedBackTargetList,
} from './containers/getfeedback-target';

import {
  OnboardingCreate,
  OnboardingEdit,
  OnboardingList,
} from './containers/onboarding';
import {
  TranslationAICreate,
  TranslationAIList,
} from './containers/translationAI/index.js';
const history = createHashHistory();

const { region, userPoolId, userPoolWebClientId, identityPoolId, bucket } =
  config;

Amplify.configure({
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId,
  },
  Storage: {
    AWSS3: {
      bucket,
      region,
    },
  },
  Analytics: {
    disabled: true,
  },
});

const logo = require('./assets/image/Haier-logo.png');

const customLayout = props => (
  <CustomLayout
    {...props}
    logo={logo}
    config={config}
    switcherValue="cms"
    menu={CustomMenu}
  />
);

const App = () => {
  const store = createAdminStore({
    AuthProvider,
    dataProvider,
    history,
  });

  const getSections = permissions => {
    const sections = [
      permissions.profile && permissions.profile.read && (
        <Resource
          name="profile"
          icon={Subway}
          list={ProfileEdit}
          options={{ label: 'Profile' }}
        />
      ),
      permissions.user && permissions.user.read && (
        <Resource
          name="user"
          list={UserList}
          edit={permissions.user.read ? UserEdit : null}
          create={permissions.user.create ? UserCreate : null}
          icon={SupervisorAccountIcon}
        />
      ),
      permissions.role && permissions.role.read && (
        <Resource
          name="role"
          list={RoleList}
          edit={permissions.role.read ? RoleEdit : null}
          create={permissions.role.create ? RoleCreate : null}
          icon={PersonAddIcon}
        />
      ),
      permissions.language && permissions.language.read && (
        <Resource
          name="language"
          list={LanguageList}
          edit={permissions.language.read ? LanguageEdit : null}
          create={permissions.language.create ? LanguageCreate : null}
          icon={LanguageIcon}
        />
      ),
      // translations
      permissions.translation && permissions.translation.read && (
        <Resource
          name="translation"
          list={TranslationList}
          nested="translation"
          create={
            permissions.translation.create &&
            (!permissions.translation.restrictLanguages ||
              permissions.translation.en)
              ? TranslationCreate
              : null
          }
          show={TranslationShow}
          icon={TranslateIcon}
        />
      ),
      permissions.translation && permissions.translation.read && (
        <Resource
          name="missing-translation"
          list={MissingTranslationList}
          nested="translation"
          show={MissingTranslationShow}
          icon={MissingTranslateIcon}
        />
      ),
      // permissions.translation && permissions.translation.read && (
      //   <Resource
      //     name="translation-ai"
      //     list={TranslationAIList}
      //     nested="translation"
      //     create={permissions.translation.create ? TranslationAICreate : null}
      //     icon={TranslateIcon}
      //   />
      // ),
      permissions['promo-rewards'] && permissions['promo-rewards'].read && (
        <Resource name="coupon-types" />
      ),
      permissions.translation && permissions.translation.read && (
        <Resource
          name="missing-translation-counter"
          list={MissingTranslationCounterList}
          nested="translation"
          icon={MissingTranslateCounterIcon}
        />
      ),
      // END translations
      permissions['appliance-settings'] &&
        permissions['appliance-settings'].read && (
          <Resource
            name="appliance-settings"
            options={{ label: 'Appliance Settings' }}
            list={ApplianceSettingsList}
            edit={
              permissions['appliance-settings'].read
                ? ApplianceSettingsEdit
                : null
            }
            create={
              permissions['appliance-settings'].create
                ? ApplianceSettingsCreate
                : null
            }
            icon={SettingsApplicationsIcon}
          />
        ),
      permissions['eligible-appliances'] &&
        permissions['eligible-appliances'].read && (
          <Resource
            name="eligible-appliances"
            list={EligibleAppliancesList}
            edit={
              permissions['eligible-appliances'].read
                ? EligibleAppliancesEdit
                : null
            }
            create={
              permissions['eligible-appliances'].create
                ? EligibleAppliancesCreate
                : null
            }
            icon={SettingsIcon}
          />
        ),
      permissions.asset && permissions.asset.read && (
        <Resource
          name="asset"
          list={AssetList}
          edit={permissions.asset.read ? AssetEdit : null}
          create={permissions.asset.create ? AssetCreate : null}
          icon={ImageIcon}
        />
      ),

      permissions['tutorial-landing-screen'] &&
        permissions['tutorial-landing-screen'].read && (
          <Resource
            name="tutorial-landing-screen"
            list={TutorialList}
            edit={
              permissions['tutorial-landing-screen'].read ? TutorialEdit : null
            }
            create={
              permissions['tutorial-landing-screen'].create
                ? TutorialCreate
                : null
            }
            icon={SettingsIcon}
          />
        ),

      permissions['promo-spaces'] && permissions['promo-spaces'].read && (
        <Resource
          name="promo-spaces"
          list={PromoSpacesList}
          nested="promo_campaigns"
          create={permissions['promo-spaces'].create ? PromoSpacesCreate : null}
          edit={permissions['promo-spaces'].read ? PromoSpacesEdit : null}
          // show={PromoCampaignsShow}
          icon={SettingsIcon}
        />
      ),
      permissions['promo-campaigns'] && permissions['promo-campaigns'].read && (
        <Resource
          name="promo-campaigns"
          list={PromoCampaignList}
          nested="promo_campaigns"
          create={
            permissions['promo-campaigns'].create ? PromoCampaignsCreate : null
          }
          edit={permissions['promo-campaigns'].read ? PromoCampaignsEdit : null}
          // show={PromoCampaignsShow}
          icon={SettingsIcon}
        />
      ),
      permissions['promo-rewards'] && permissions['promo-rewards'].read && (
        <Resource
          name="promo-rewards"
          list={RewardsList}
          nested="promo_campaigns"
          create={permissions['promo-rewards'].create ? RewardsCreate : null}
          edit={permissions['promo-rewards'].read ? RewardsEdit : null}
          // show={PromoCampaignsShow}
          icon={SettingsIcon}
        />
      ),
      permissions['promo-user-redemptions'] &&
        permissions['promo-user-redemptions'].read && (
          <Resource
            name="promo-user-redemptions"
            list={PromoTrackingList}
            nested="promo_campaigns"
            icon={SettingsIcon}
          />
        ),
      permissions['guided-washing'] && permissions['guided-washing'].read && (
        <Resource
          name="guided-washing"
          list={GuidedWashingCycleList}
          edit={
            permissions['guided-washing'].read ? GuidedWashingCycleEdit : null
          }
          create={
            permissions['guided-washing'].create
              ? GuidedWashingCycleCreate
              : null
          }
          nested="guided_washing"
          options={{ label: 'Cycle' }}
          icon={TimelapseIcon}
        />
      ),
      permissions['guided-washing-choices'] &&
        permissions['guided-washing-choices'].read && (
          <Resource
            name="guided-washing-choices"
            list={GuidedWashingChoicesList}
            edit={
              permissions['guided-washing-choices'].read
                ? GuidedWashingChoicesEdit
                : null
            }
            create={
              permissions['guided-washing-choices'].create
                ? GuidedWashingChoicesCreate
                : null
            }
            nested="guided_washing"
            options={{ label: 'Choices' }}
            icon={SwapHorizIcon}
          />
        ),
      permissions['guided-washing-cluster'] &&
        permissions['guided-washing-cluster'].read && (
          <Resource
            name="guided-washing-cluster"
            list={GuidedWashingClusterList}
            edit={
              permissions['guided-washing-cluster'].read
                ? GuidedWashingClusterEdit
                : null
            }
            create={
              permissions['guided-washing-cluster'].create
                ? GuidedWashingClusterCreate
                : null
            }
            nested="guided_washing"
            options={{ label: 'Cluster' }}
            icon={GroupWorkIcon}
          />
        ),
      permissions.assistance && permissions.assistance.read && (
        <Resource
          name="assistance"
          list={AssistanceList}
          // show={AssistanceShow}
          edit={permissions.assistance.read ? AssistanceEdit : null}
          create={permissions.assistance.create ? AssistanceCreate : null}
          icon={AssistantIcon}
        />
      ),
      permissions.maintenance && permissions.maintenance.read && (
        <Resource
          name="maintenance"
          list={MaintenanceList}
          edit={permissions.maintenance.read ? MaintenanceEdit : null}
          create={permissions.maintenance.create ? MaintenanceCreate : null}
          icon={WorkIcon}
        />
      ),
      permissions['maintenance-generic'] &&
        permissions['maintenance-generic'].read && (
          <Resource
            name="maintenance-generic"
            options={{ label: 'Maintenance Generic' }}
            list={MaintenanceGenericList}
            edit={
              permissions['maintenance-generic'].read
                ? MaintenanceGenericEdit
                : null
            }
            create={
              permissions['maintenance-generic'].create
                ? MaintenanceGenericCreate
                : null
            }
            icon={WorkOutlineIcon}
          />
        ),
      permissions['hints-category'] && permissions['hints-category'].read && (
        <Resource
          name="hints-category"
          list={HintCategoryList}
          edit={permissions['hints-category'].read ? HintsCategoryEdit : null}
          create={
            permissions['hints-category'].create ? HintsCategoryCreate : null
          }
          icon={RowingIcon}
        />
      ),
      permissions.hints && permissions.hints.read && (
        <Resource
          name="hints"
          list={HintList}
          edit={permissions.hints.read ? HintsEdit : null}
          create={permissions.hints.create ? HintCreate : null}
          icon={RowingIcon}
        />
      ),
      permissions.support && permissions.support.read && (
        <Resource
          name="support"
          edit={permissions.support.read ? SupportEdit : null}
          create={permissions.support.create ? SupportCreate : null}
          list={SupportList}
          icon={ContactSupportIcon}
        />
      ),
      permissions.discover && permissions.discover.read && (
        <Resource
          name="discover"
          list={DiscoverList}
          edit={permissions.discover.read ? DiscoverEdit : null}
          create={permissions.discover.create ? DiscoverCreate : null}
          icon={PageviewIcon}
        />
      ),
      permissions['getfeedback-target'] &&
        permissions['getfeedback-target'].read && (
          <Resource
            name="getfeedback-target"
            list={GetFeedBackTargetList}
            edit={
              permissions['getfeedback-target'].read
                ? GetFeedBackTargetEdit
                : null
            }
            create={
              permissions['getfeedback-target'].create
                ? GetFeedBackTargetCreate
                : null
            }
            icon={PageviewIcon}
          />
        ),
      permissions.campaigns && permissions.campaigns.read && (
        <Resource
          name="campaigns"
          list={CampaignsList}
          edit={permissions.campaigns.read ? CampaignsEdit : null}
          create={permissions.campaigns.create ? CampaignsCreate : null}
          icon={SettingsIcon}
        />
      ),
      permissions['smart-door-counter'] &&
        permissions['smart-door-counter'].read && (
          <Resource
            name="smart-door-counter"
            icon={SettingsIcon}
            list={SmartDoorCounterListRule}
            edit={
              permissions['smart-door-counter'].read
                ? SmartDoorCounterEditRule
                : null
            }
            create={
              permissions['smart-door-counter'].create
                ? SmartDoorCounterCreateRule
                : null
            }
          />
        ),
      permissions['appliance-info'] && permissions['appliance-info'].read && (
        <Resource
          name="appliance-info"
          options={{ label: 'Appliance Info' }}
          list={ApplianceInfoList}
          edit={permissions['appliance-info'].read ? ApplianceInfoEdit : null}
          create={
            permissions['appliance-info'].create ? ApplianceInfoCreate : null
          }
          icon={InfoIcon}
        />
      ),
      permissions.troubleshooting && permissions.troubleshooting.read && (
        <Resource
          name="troubleshooting"
          list={TroubleshootingList}
          edit={permissions.troubleshooting.read ? TroubleshootingEdit : null}
          create={
            permissions.troubleshooting.create ? TroubleshootingCreate : null
          }
          icon={ErrorIcon}
        />
      ),
      permissions['smart-checkup'] && permissions['smart-checkup'].read && (
        <Resource
          name="smart-checkup"
          list={SmartCheckupList}
          edit={permissions['smart-checkup'].read ? SmartCheckupEdit : null}
          create={
            permissions['smart-checkup'].create ? SmartCheckupCreate : null
          }
          icon={ErrorIcon}
        />
      ),
      permissions['app-user-info'] && permissions['app-user-info'].read && (
        <Resource
          name="app-user-info"
          list={AppUserInfoList}
          edit={permissions['app-user-info'].read ? AppUserInfoEdit : null}
          create={
            permissions['app-user-info'].create ? AppUserInfoCreate : null
          }
          icon={ErrorIcon}
        />
      ),
      permissions['appliance-configuration'] &&
        permissions['appliance-configuration'].read && (
          <Resource
            name="appliance-configuration"
            edit={
              permissions['appliance-configuration'].read
                ? ApplianceConfigurationEdit
                : null
            }
            create={
              permissions['appliance-configuration'].create
                ? ApplianceConfigurationCreate
                : null
            }
            list={ApplianceConfigurationList}
            icon={ViewComfyIcon}
          />
        ),
      permissions.recipe && permissions.recipe.read && (
        <Resource
          name="recipe"
          edit={permissions.recipe.read ? RecipeEdit : null}
          list={RecipeList}
          icon={Fastfood}
        />
      ),
      permissions['ingredient-ref'] && permissions['ingredient-ref'].read && (
        <Resource
          name="ingredient-ref"
          edit={permissions['ingredient-ref'].read ? IngredientRefEdit : null}
          create={
            permissions['ingredient-ref'].create ? IngredientRefCreate : null
          }
          list={IngredientRefList}
          icon={Fastfood}
        />
      ),
      permissions['mock-demo'] && permissions['mock-demo'].read && (
        <Resource
          name="mock-demo"
          edit={permissions['mock-demo'].read ? MockDemoEdit : null}
          create={permissions['mock-demo'].create ? MockDemoCreate : null}
          list={MockDemoList}
          icon={ChildFriendlyIcon}
        />
      ),
      permissions['food-category'] && permissions['food-category'].read && (
        <Resource name="food-category" />
      ),
      permissions['app-configuration'] &&
        permissions['app-configuration'].read && (
          <Resource
            name="app-configuration"
            edit={
              permissions['app-configuration'].read
                ? AppConfigurationEdit
                : null
            }
            create={
              permissions['app-configuration'].create
                ? AppConfigurationCreate
                : null
            }
            list={AppConfigurationList}
            icon={SettingsIcon}
          />
        ),
      permissions['app-bundle-update'] &&
        permissions['app-bundle-update'].read && (
          <Resource
            name="app-bundle-update"
            edit={
              permissions['app-bundle-update'].update
                ? AppBundleUpdateEdit
                : null
            }
            create={
              permissions['app-bundle-update'].create
                ? AppBundleUpdateCreate
                : null
            }
            list={AppBundleUpdateList}
            icon={SettingsIcon}
          />
        ),
      permissions['video-tutorial'] && permissions['video-tutorial'].read && (
        <Resource
          name="video-tutorial"
          edit={permissions['video-tutorial'].read ? VideoTutorialEdit : null}
          create={
            permissions['video-tutorial'].create ? VideoTutorialCreate : null
          }
          list={VideoTutorialList}
          show={VideoTutorialShow}
          icon={SettingsIcon}
        />
      ),
      permissions['appliance-wake-up'] &&
        permissions['appliance-wake-up'].read && (
          <Resource
            name="appliance-wake-up"
            list={ApplianceWakeUpList}
            edit={
              permissions['appliance-wake-up'].read ? ApplianceWakeUpEdit : null
            }
            create={
              permissions['appliance-wake-up'].create
                ? ApplianceWakeUpCreate
                : null
            }
            icon={SettingsIcon}
          />
        ),
      permissions['service-mode'] && permissions['service-mode'].read && (
        <Resource
          name="service-mode"
          list={ServiceModeList}
          edit={permissions['service-mode'].read ? ServiceModeEdit : null}
          create={permissions['service-mode'].create ? ServiceModeCreate : null}
          icon={SettingsIcon}
        />
      ),
      permissions['widget'] && permissions['widget'].read && (
        <Resource
          name="widget"
          list={WidgetList}
          edit={permissions['widget'].read ? WidgetEdit : null}
          create={permissions['widget'].create ? WidgetCreate : null}
          icon={WidgetsIcon}
        />
      ),
      permissions['program-list-rules'] &&
        permissions['program-list-rules'].read && (
          <Resource
            name="program-list-rules"
            create={
              permissions['program-list-rules'].create
                ? ProgramRulesCreate
                : null
            }
            edit={
              permissions['program-list-rules'].read ? ProgramRulesEdit : null
            }
            list={ProgramRulesList}
            icon={SettingsIcon}
          />
        ),
      permissions['maintenance-instructions'] &&
        permissions['maintenance-instructions'].read && (
          <Resource
            name="maintenance-instructions"
            list={MaintenanceInstructionsList}
            edit={
              permissions.maintenance.read ? MaintenanceInstructionsEdit : null
            }
            create={
              permissions.maintenance.create
                ? MaintenanceInstructionsCreate
                : null
            }
            icon={WorkIcon}
          />
        ),
      permissions['service-communications'] &&
        permissions['service-communications'].read && (
          <Resource
            name="service-communications"
            list={ServiceCommunicationsList}
            edit={
              permissions['service-communications'].read
                ? ServiceCommunicationsEdit
                : null
            }
            create={
              permissions['service-communications'].create
                ? ServiceCommunicationsCreate
                : null
            }
            icon={SpeakerNotesIcon}
          />
        ),
      permissions['eco-delay-start'] && permissions['eco-delay-start'].read && (
        <Resource
          name="eco-delay-start"
          list={EcoDelayStartList}
          edit={permissions['eco-delay-start'].read ? EcoDelayStartEdit : null}
          create={
            permissions['eco-delay-start'].create ? EcoDelayStartCreate : null
          }
          icon={SettingsIcon}
        />
      ),
      permissions['support-guide-help'] &&
        permissions['support-guide-help'].read && (
          <Resource
            name="support-guide-help"
            list={SupportGuideHelpList}
            edit={
              permissions['support-guide-help'].read
                ? SupportGuideHelpEdit
                : null
            }
            create={
              permissions['support-guide-help'].create
                ? SupportGuideHelpCreate
                : null
            }
            icon={SettingsIcon}
          />
        ),
      permissions['iam-configuration'] &&
        permissions['iam-configuration'].read && (
          <Resource
            name="iam-configuration"
            list={IamConfigurationList}
            edit={
              permissions['iam-configuration'].read
                ? IamConfigurationEdit
                : null
            }
            create={
              permissions['iam-configuration'].create
                ? IamConfigurationCreate
                : null
            }
            icon={SettingsIcon}
          />
        ),
      //Start Multi Account
      permissions['multi-account'] && permissions['multi-account'].read && (
        <Resource
          name="multi-account"
          list={MultiAccountList}
          nested="multi_account"
          edit={permissions['multi-account'].read ? MultiAccountEdit : null}
          create={
            permissions['multi-account'].create ? MultiAccountCreate : null
          }
          icon={SettingsIcon}
        />
      ),
      permissions['multi-account-faq'] &&
        permissions['multi-account-faq'].read && (
          <Resource
            name="multi-account-faq"
            list={FAQList}
            nested="multi_account"
            edit={permissions['multi-account-faq'].read ? FAQEdit : null}
            create={permissions['multi-account-faq'].create ? FAQCreate : null}
            icon={LiveHelpIcon}
          />
        ),
      permissions['multi-account-how-to'] &&
        permissions['multi-account-how-to'].read && (
          <Resource
            name="multi-account-how-to"
            list={HowToList}
            nested="multi_account"
            edit={permissions['multi-account-how-to'].read ? HowToEdit : null}
            create={
              permissions['multi-account-how-to'].create ? HowToCreate : null
            }
            icon={LiveHelpIcon}
          />
        ),

      permissions['onboarding'] && permissions['onboarding'].read && (
        <Resource
          name="onboarding"
          list={OnboardingList}
          edit={permissions.onboarding.update ? OnboardingEdit : null}
          create={permissions.onboarding.create ? OnboardingCreate : null}
          icon={WorkIcon}
        />
      ),
    ];
    return sections.sort((a, b) => (a.props?.name > b.props?.name ? 1 : -1));
  };
  return (
    <Provider store={store}>
      <Admin
        authProvider={AuthProvider}
        loginPage={Login}
        dataProvider={dataProvider}
        history={history}
        layout={customLayout}
        theme={CustomTheme}
        i18nProvider={i18nProvider}
      >
        {(permissions = {}) => {
          return getSections(permissions);
        }}
      </Admin>
    </Provider>
  );
};
export default App;
