import React, { useEffect } from 'react';
import Amplify, { Storage, getUrl } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import { TextField, useTranslate, DateField, useRefresh } from 'react-admin';

import DynamoPagination from '../../components/DynamoPagination';
import CustomDataGrid from '../../components/organisms/customDatagrid';
import config from '../../config';
const OUTPUT_FOLDER = 'outputs';

const TranslationAIList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(() => refresh(), 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const breadcrumbsData = [
    {
      text: translate('resources.translation.name'),
      url: '',
      icon: <TranslateIcon />,
    },
  ];

  const AwsGetField = props => {
    const record = props?.record;
    if (!record) return null;

    const handleClick = async ev => {
      console.log(ev);

      const awsCurrentConfig = Amplify.configure();

      Amplify.configure({
        ...awsCurrentConfig,
        Storage: {
          AWSS3: {
            bucket: config.genAiBucket,
            region: config.region,
            customPrefix: {
              public: '',
            },
          },
        },
      });

      try {
        const key = `${OUTPUT_FOLDER}/${ev.fileName}`;
        const signedUrl = await Storage.get(key, { expires: 60 });
        window.open(signedUrl, '_blank', 'noreferrer');
      } catch (err) {
        console.log(err);
      }
      Amplify.configure(awsCurrentConfig);
    };
    return record?.jobStatus === 'COMPLETED' ? (
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          handleClick(record);
        }}
      >
        {'DOWNLOAD'}
      </a>
    ) : null;
  };
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <CustomList
        perPage={25}
        {...props}
        exporter={false}
        sort={{ field: 'uploadAt', order: 'DESC' }}
        pagination={<DynamoPagination resource="translation-ai" />}
      >
        <CustomDataGrid choiceColumns selectable className={classes.grid}>
          <TextField source="fileName" label="File Name" />
          <TextField source="inputLanguages" label="Input Languages" />
          <TextField source="outputLanguages" label="Output Languages" />
          <DateField source="uploadAt" label="Upload At" />
          <TextField source="jobStatus" label="Job Status" />
          <AwsGetField label="Download" />
        </CustomDataGrid>
      </CustomList>
    </>
  );
};

const useStyles = makeStyles({
  grid: {
    wordBreak: 'break-word',
    '& .MuiButton-label': {
      display: 'contents',
    },
    '& tr td:nth-child(2)': {
      '& span': {
        maxWidth: '500px',
      },
    },
    '& span': {
      display: 'block',
      overflow: 'hidden',
      maxHeight: '40px',
      maxWidth: '310px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
});

export default TranslationAIList;
