import { reducerFromMap } from './common.reducers';

import {
  APP_INIT_COMPLETE,
} from '../actions';

const reducerMap = {};

reducerMap[APP_INIT_COMPLETE] = (state) => ({
  ...state,
  ready: true,
});

export default reducerFromMap(reducerMap);
