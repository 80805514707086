import React from 'react';
import {
  Create, SimpleForm, TextInput, SimpleFormIterator, required, ImageField, useTranslate, ArrayInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ContainerFieldsList, CustomBreadcrumbs, CustomSelectInput } from 'candy-commons-components';
import InfoIcon from '@material-ui/icons/Info';
import applianceType from '../../data/applianceType.json';
import ImageInput from '../../components/ImageInput';

const ApplianceInfoCreate = props => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.appliance-info.name'),
      url: 'appliance-info',
      icon: <InfoIcon />,
    }, {
      text: translation('resources.appliance-info.create.name'),
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <TextInput source="category" />
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
            />
            <TextInput source="description" />
            <TextInput source="title" />
          </ContainerFieldsList>
          <ArrayInput source="images">
            <SimpleFormIterator>
              <TextInput source="description" validate={required()} />
              <ImageInput source="path" label="resources.appliance-info.fields.images" accept="image/*" placeholder={<p>{translation('resources.appliance-info.fields.input_file')}</p>}>
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

const useStyles = makeStyles({});

export default ApplianceInfoCreate;
