import TimelapseIcon from '@material-ui/icons/Timelapse';
import {
  CustomBreadcrumbs,
  CustomSelectInput
} from 'candy-commons-components';
import React, { useEffect, useState } from 'react';
import {
  DeleteButton,
  Edit,
  Loading,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useTranslate
} from 'react-admin';
import { useGetOne } from '../hooks/normalizeFormDataToSend';
import { useStyles } from '../styles';

const RewardsEdit = ({ permissions = {}, ...props }) => {
  const { id } = props;
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['promo-rewards']?.update;
  const classes = useStyles();
  const { data, isLoading, error } = useGetOne('promo-rewards', id);
  const [codeType, onChangeCodeType] = useState();
  const [rewardType, onChangeRewardType] = useState();

  const breadcrumbsData = [
    {
      text: translation('resources.promo-rewards.name'),
      url: 'promo-rewards',
      icon: <TimelapseIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  useEffect(() => {
    if (data) {
      onChangeCodeType(data?.content.codeType);
      onChangeRewardType(data?.content.rewardType);
    }
  }, [data]);

  const onChangeCodeTypeHandler = event => {
    onChangeCodeType(event.target.value);
  };

  const onChangeRewardTypeHandler = event => {
    onChangeRewardType(event.target.value);
  };

  const transform = data => {
    if (data.content.rewardType === 'PHYSICAL') {
      return {
        ...data,
        content: {
          ...data.content,
          codeType: null,
          code: null,
          couponType: null,
        },
      };
    } else {
      return data;
    }
  };

  const CustomToolbar = props => {
    return (
      <Toolbar {...props}>
        <SaveButton redirect="list" disabled={isEditDisabled} />
        <DeleteButton disabled={isEditDisabled}  undoable/>
      </Toolbar>
    );
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return null;
  }

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props} undoable={false}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar />}
          className={classes.fullWidth}
        >
          <TextInput
            source="slug"
            label="Slug"
            validate={required()}
            className={classes.fullWidth}
            disabled={isEditDisabled}
          />
          <TextInput
            source="content.externalCmsStructureId"
            label="External CMS Structure Id"
            validate={[required()]}
            className={classes.fullWidth}
            disabled={isEditDisabled}
          />
          <TextInput
            source="content.externalCmsContentId"
            label="External CMS Content Id"
            validate={[required()]}
            className={classes.fullWidth}
            disabled={isEditDisabled}
          />
          <CustomSelectInput
            validate={[required()]}
            label={'Reward type'}
            source={'content.rewardType'}
            choices={[
              { id: 'PHYSICAL', name: 'Physical' },
              { id: 'DIGITAL', name: 'Digital' },
            ]}
            onChange={onChangeRewardTypeHandler}
            disabled={isEditDisabled}
          />
          {rewardType === 'DIGITAL' && (
            <>
              <CustomSelectInput
                validate={[required()]}
                label={'Code type'}
                source={'content.codeType'}
                choices={[
                  { id: 'GENERIC', name: 'generic' },
                  { id: 'UNIQUE', name: 'unique' },
                ]}
                onChange={onChangeCodeTypeHandler}
                disabled={isEditDisabled}
              />
              {codeType === 'GENERIC' && (
                <TextInput
                  source="content.code"
                  label="Code"
                  className={classes.fullWidth}
                  validate={[required()]}
                  disabled={isEditDisabled}
                />
              )}
              {codeType === 'UNIQUE' && (
                <TextInput
                  source="content.couponType"
                  label="Code List Id"
                  className={classes.fullWidth}
                  validate={[required()]}
                  disabled={isEditDisabled}
                />
              )}
            </>
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};

export default RewardsEdit;
