import moment from 'moment';

export const getDefaultDayAgo = day => {
  const dayPast = new Date(Date.now() - day * 24 * 60 * 60 * 1000);
  dayPast.setHours('00', '00', '01');
  return moment(dayPast).format('YYYY-MM-DDTHH:mm:ss');
};

export const getEndOfCurrentDay = () => {
  const today = new Date();
  today.setHours(23, 59, 59);
  return moment(today).format('YYYY-MM-DDTHH:mm:ss');
};
