import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import { Storage } from 'aws-amplify';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
  CustomSelectInput,
} from 'candy-commons-components';
import {
  ArrayInput,
  Create,
  ImageField,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  required,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPE from '../../data/applianceType.json';
import DRAWER_TYPE from '../../data/drawerType.json';
import CATEGORIES from '../../data/maintenanceInstructionsCategories.json';
import SERIES from '../../data/series.json';
import SERIES_VERSION from '../../data/seriesVersion.json';
import LOADING_TYPE from '../../data/loadingType.json';
import INSTALLATION_TYPE from '../../data/installationType.json';
import DRYING_TECHNOLOGY from '../../data/dryingTechnology.json';
import TANK_POSITION from '../../data/tankPosition.json';
import { brands as BRANDS } from './data';

const filteredSeries = SERIES.filter(x => Boolean(x.id));

const handleFileClick = async event => {
  const file = event;
  if (!file) {
    return;
  }
  const key = `maintenance/${file.name}`;
  await Storage.put(key, file, {
    contentType: file.type,
  });
};

const MaintenanceCreateSaveButton = ({
  handleSubmitWithRedirect,
  ...props
}) => {
  const form = useForm();
  const formState = useFormState();
  const handleClick = () => {
    if (formState.values.generic) form.change('link', null);

    handleSubmitWithRedirect('list');
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const MaintenanceCreateToolbar = props => (
  <Toolbar {...props}>
    <MaintenanceCreateSaveButton />
  </Toolbar>
);

const MaintenanceCreate = props => {
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.maintenance-instructions.name'),
      url: 'maintenance-instructions',
      icon: <WorkIcon />,
    },
    {
      text: translation('resources.maintenance-instructions.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm toolbar={<MaintenanceCreateToolbar />}>
          <Grid container style={{ width: '100%', paddingLeft: 10 }}>
            <Typography>Criteria</Typography>
          </Grid>
          <ContainerFieldsList>
            <CustomSelectInput
              allowEmpty
              source="applianceType"
              choices={APPLIANCE_TYPE}
              validate={required()}
            />
            <CustomSelectInput source="brand" allowEmpty choices={BRANDS} />
            <CustomSelectInput
              source="series"
              allowEmpty
              choices={filteredSeries}
            />
            <CustomSelectInput
              source="seriesVersion"
              allowEmpty
              choices={SERIES_VERSION}
            />
            <CustomSelectInput
              label="Loading Type"
              source="loadingType"
              allowEmpty
              choices={LOADING_TYPE}
            />
            <CustomSelectInput
              label="Drawer Type"
              source="drawerType"
              allowEmpty
              choices={DRAWER_TYPE}
            />

            <CustomSelectInput
              label="Installation Type"
              source="installationType"
              allowEmpty
              choices={INSTALLATION_TYPE}
            />
            <CustomSelectInput
              label="Drying Technology"
              source="dryingTechnology"
              allowEmpty
              choices={DRYING_TECHNOLOGY}
            />
            <CustomSelectInput
              label="Tank Position"
              source="tankPosition"
              allowEmpty
              choices={TANK_POSITION}
            />
          </ContainerFieldsList>
          <Divider style={{ width: '100%' }} />
          <Grid container style={{ width: '100%', paddingLeft: 10 }}>
            <Typography>Maintenance Categoy</Typography>
          </Grid>
          <ContainerFieldsList>
            <CustomSelectInput
              source="category"
              allowEmpty
              validate={required()}
              choices={CATEGORIES}
            />
          </ContainerFieldsList>
          <Divider style={{ width: '100%' }} />
          <ArrayInput source="steps">
            <SimpleFormIterator getItemLabel={index => `${index + 1}-key`}>
              <TextInput label="Title" source="title" validate={required()} />
              <TextInput label="Description" source="description" />
              <TextInput label="CTA Label" source="cta_label" />
              <ImageInput
                source="imagePath"
                label="Image"
                accept="image/*"
                onChange={handleFileClick}
                placeholder={<p>Drop your file here</p>}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <Divider style={{ width: '100%', marginBottom: 20 }} />
          <ContainerFieldsList>
            <Grid container style={{ width: '100%', paddingLeft: 10 }}>
              <Typography>Confirmation</Typography>
            </Grid>
            <TextInput source="confirmationTitle" allowEmpty />
            <TextInput source="confirmationDesc" allowEmpty />
            <TextInput source="confirmationLabel" allowEmpty />
            <TextInput source="cancelLabel" allowEmpty />
          </ContainerFieldsList>
        </SimpleForm>
      </Create>
    </>
  );
};

export default MaintenanceCreate;
