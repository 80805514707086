import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { ContainerFieldsList, CustomBreadcrumbs, CustomSelectInput } from 'candy-commons-components';
import {
  ArrayInput,
  Create, SimpleForm,
  SimpleFormIterator,
  TextInput, required,
  useTranslate,
} from 'react-admin';
import applianceType from '../../data/applianceType.json';
import series from '../../data/series.json';

const ApplianceSettingsCreate = props => {
  const classes = useStyles();
  const translation = useTranslate();
  const breadcrumbsData = [
    {
      text: translation('resources.appliance-settings.name'),
      url: 'appliance-settings',
      icon: <SettingsApplicationsIcon />,
    },
    {
      text: translation('resources.appliance-settings.create.name'),
      url: '',
    },
  ];
  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Create {...props}>
        <SimpleForm>
          <ContainerFieldsList>
            <CustomSelectInput
              source="applianceType"
              choices={applianceType}
              validate={required()}
            />
            <CustomSelectInput
              source="series"
              choices={series}
            />
          </ContainerFieldsList>
          <ArrayInput source="options">
            <SimpleFormIterator classes={{ form: classes.form }}>
              <TextInput source="name" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: -8,
    '& .MuiFormControl-root.MuiTextField-root': {
      width: 260,
      margin: 8,
    },
    '& >.ra-input': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export default ApplianceSettingsCreate;
