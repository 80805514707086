import axios from 'axios';
import { Auth } from 'aws-amplify';
import config from '../config';

const { basePath } = config;

// general fetcher wrapper function
const fetcher = async ({
  method = 'GET',
  url,
  data,
  headers,
  params,
  callbacks,
  token,
}) => {
  if (!token) {
    const username = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`,
    );
    token = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`,
    );
  }

  let response;

  try {
    response = await axios({
      method,
      url: `${basePath}${url}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      params,
    });
  } catch (ex) {
    if (ex && ex.response && ex.response.status === 401) {
      const user = await Auth.currentSession();
      response = await fetcher({
        method,
        url,
        headers,
        params,
        callbacks,
        token: user.getIdToken().jwtToken,
      });
    } else {
      throw ex;
    }
  }

  return response;
};

export default fetcher;
