import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles({
  arrayInput: {
    '& .MuiFormLabel-root': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: -8,
    '& .MuiFormControl-root.MuiTextField-root': {
      width: 260,
      margin: 8,
    },
    '& >.ra-input': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  fullWidth: {
    width: '100%',
  }
})
