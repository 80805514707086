export const showListData = [
  {
    label: "resources.language.fields.version",
    type: "textField",
    source: "version"
  },
  {
    label: "resources.language.fields.langStatus",
    type: "textField",
    source: "langStatus"
  }
];
