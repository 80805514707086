import { reducerFromMap } from './common.reducers';

import {
  SET_PAGINATION,
  SET_PAGINATION_TOKEN,
} from '../actions';

const reducerMap = {};

reducerMap[SET_PAGINATION] = (state, action) => {
  const { payload } = action;
  const prevPaginationState = state.pagination || {};
  return {
    ...state,
    pagination: {
      ...state.pagination,
      [payload.resource]: {
        ...prevPaginationState[payload.resource],
        ...payload.newToken,
      },
    },
  };
};

reducerMap[SET_PAGINATION_TOKEN] = (state, action) => {
  const { payload } = action;
  const { resource, paginationToken } = payload;
  const prevPaginationState = state.pagination || {};
  return {
    ...state,
    pagination: {
      ...state.pagination,
      [resource]: {
        ...prevPaginationState[resource],
        paginationToken,
      },
    },
  };
};

export default reducerFromMap(reducerMap);
