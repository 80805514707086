import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import {
  ContainerFieldsList,
  CustomBreadcrumbs,
} from 'candy-commons-components';
import React from 'react';
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  useTranslate,
  Edit,
} from 'react-admin';
import { useStyles } from '../styles';
import CustomToolbar from '../../../components/CustomToolbar';

const FAQEdit = ({ permissions = {}, ...props }) => {
  const classes = useStyles();
  const translation = useTranslate();
  const isEditDisabled = !permissions?.['multi-account-faq']?.update;
  const { id } = props;

  const breadcrumbsData = [
    {
      text: translation('resources.multi-account-faq.name'),
      url: 'multi-account-faq',
      icon: <LiveHelpIcon />,
    },
    {
      text: id,
      url: '',
    },
  ];

  return (
    <>
      <CustomBreadcrumbs data={breadcrumbsData} />
      <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} isEditDisabled={isEditDisabled} />}>
          <BooleanInput
            initialValue={false}
            label="Publish"
            source="publish"
            className={classes.fullWidth}
            disabled={isEditDisabled}
          />
          <ContainerFieldsList>
            <TextInput source="id" label="Title" disabled />
            <TextInput label="Link" source="link" disabled={isEditDisabled} />
          </ContainerFieldsList>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default FAQEdit;
